<template>
    <div id="org_settings_team">
        <div class="row">
            <div class="col-10">
                <h4>Team</h4>
                <p>Manage your team by adding, modifying, or removing users.</p>
            </div>
            <div class="col-2">
                <button :disabled="form.busy" class="btn btn-primary float-end" @click.prevent="addTeamUser()" data-bs-toggle="modal" data-bs-target="#user-modal">
                    <span>
                        <div v-if="form.busy" class="spinner-border spinner-border-sm float-left" role="status"> <span class="visually-hidden">Loading...</span></div>
                        <i class="fas fa-fw fa-plus" v-else></i>
                            Add Team Member
                    </span>
                </button>       
            </div>
        </div>

        <div class="row">
            <div class="col-12 position-relative">
                <input type="text" class="search-box form-control me-1" placeholder="Search by Name..." aria-label="Search by Name..." v-model="search_key" @input="searchUser()">
                <i class="fa fa-search search-placeholder-icon" v-if="search_key == ''"></i>
            </div>
        </div>

        <div class="row">
            <div class="col-12 avid-table">
                <table class="table table-responsive border rounded table-with-pagination my-3">
                    <thead>
                        <tr class="table-header-row">
                            <th v-on:click="sortByKey('name')" class="table-column-1">
                                <div class="d-flex flex-row">
                                    Name
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='name' && sort_order=='asc')? 'sort-key-active' :''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='name' && sort_order=='des')? 'sort-key-active' :''">
                                    </div>
                                </div>
                            </th>
                            <th v-if="if_show_org_column" v-on:click="sortByKey('organization')" class="table-column-1">
                                <div class="d-flex flex-row">
                                    Organization
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='organization' && sort_order=='asc')? 'sort-key-active' :''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='organization' && sort_order=='des')? 'sort-key-active' :''">
                                    </div>
                                </div>
                            </th>
                            <th v-on:click="sortByKey('email')" class="table-column-3">
                                <div class="d-flex flex-row">
                                    Email
                                    <div class="d-flex flex-column">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='email' && sort_order=='asc')? 'sort-key-active' :''">
                                        <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='email' && sort_order=='des')? 'sort-key-active' :''">                                                          
                                    </div>
                                </div>
                            </th>
                            <th class="table-column-4" style="width:96px;">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(u, index) in user_list_shown"  :class="`table-row-${index+1}`">
                            <td class="align-start table-column-1">
                                {{ u.name }}
                            </td>
                            <td v-if="if_show_org_column" class="align-start table-column-2">
                                {{ u.organization }}
                            </td>
                            <td class="align-start table-column-3">
                                {{ u.email }}
                            </td>

                            <td class="align-start table-column-4 show-on-row-hover">

                                <button class="btn btn-none px-1" v-on:click="deleteTeamUser(u, index)" title="Remove Team Member">
                                    <img class="icon" src="/img/icons/dialexa-icons/trash.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete Team Member" >
                                </button>
                                <button class="btn btn-none px-1" v-on:click="updateTeamUser(u)" title="Edit" data-bs-toggle="modal" data-bs-target="#user-modal" v-if="(user.user_type == 'admin' || user.user_type == 'agency_admin') && (u.agency_id == null && u.user_type != 'admin')">
                                    <img class="icon" src="/img/icons/dialexa-icons/pencil.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit Team Member" >
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="table-border-bottom-0">
                        <tr>
                            <td colspan="100%" class="py-0">
                                <div class="table-pagination">
                                    <div class="table-pagination--group">
                                        <span>Showing: </span>
                                        <select class="form-select" name="num_per_page" id="num_per_page" v-model="num_per_page" @change="reloadPagination()">
                                            <option value="5000" >ALL</option>
                                            <option v-for="num in num_per_page_options" :value="num">{{ num }}</option>
                                        </select>
                                        <span>Team Members</span>
                                    </div>

                                    <div class="table-pagination--group">
                                        {{ item_interval }} of {{ filtered_user_list.length }}
                                    </div>

                                    <div class="table-pagination--group">
                                        <button v-if="page_count>1" class="btn btn-none px-1" @click="moveToPrevPage()" :disabled="page_index == 0 ">
                                            <img class="icon" src="/img/icons/dialexa-icons/chevron-left.svg">
                                        </button>   

                                        <div v-if="page_index_array.length<=3">
                                            <button  v-for="page in page_index_array" class="btn btn-none px-1" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                            {{page}}
                                            </button>  
                                        </div>
                                        <div v-else>
                                            <button v-for="page in page_index_array.slice(0,2)" class="btn btn-none px-1" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                            {{page}}
                                            </button>  

                                            <button v-if="page_index > 2" class="btn btn-none px-1" disabled>
                                            ...
                                            </button>  
                                            <button v-if="page_index >= 2 && page_index< page_index_array.length -1" class="btn btn-none px-1 current-page">
                                                {{page_index + 1 }} 
                                            </button>  

                                            <button v-if="page_index < page_index_array.length -2" class="btn btn-none px-1" disabled>
                                            ...
                                            </button>  
                                            <button  class="btn btn-none px-1" :class="page_index ==page_index_array.length -1 ? 'current-page':''" @click="moveToPage(page_index_array.length)">
                                            {{ page_index_array.length }}
                                            </button>  
                                        </div>

                                        <button v-if="page_count>1" class="btn btn-none px-1" @click="moveToNextPage()" :disabled="page_index == page_count -1 ">
                                            <img class="icon" src="/img/icons/dialexa-icons/chevron-right.svg">
                                        </button>   
                                    </div>

                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div class="modal fade new-modal" id="user-modal" aria-labelledby="user-modal" aria-hidden="true" data-bs-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content mx-2">
                    <div class="modal-header">
                        <div class="w-100 d-flex flex-column gap-1">
                            <img class="modal-icon" src="/img/icons/dialexa-icons/person-add-outline.svg">
                            <p>TEAM</p>
                        </div>
                        <button type="button" class="btn btn-none close-modal"
                            data-bs-dismiss="user-modal" @click="closeModal()">
                            <i class="fa fa-close"></i>
                        </button>
                    </div>
                    <div class="modal-body pt-0">
                        <h3 class="mb-0">{{user_info.action}} Team member</h3>
                        <p>Please fill out the details for the team member you would like to {{user_info.action.toLowerCase()}}.</p>
                    
                        <div class="row">
                            <div class="col-6">
                                <label class="form-label">First Name *</label>
                                <input type="text" class="form-control" :class="((errors.first_name != '' )? 'is-invalid':'')"
                                    name="first_name" v-model="user_info.first_name">
                                <div class="text-danger invalid-feedback" v-if="errors.first_name != ''">
                                    {{errors.first_name}}
                                </div>
                            </div>

                            <div class="col-6">
                                <label class="form-label">Last Name *</label>
                                <input type="text" class="form-control" :class="((errors.last_name != '' )? 'is-invalid':'')"
                                    name="last_name" v-model="user_info.last_name">
                                <div class="text-danger invalid-feedback" v-if="errors.last_name != ''">
                                    {{errors.last_name}}
                                </div>
                            </div>
                        </div>
                    
                        <div class="row">
                            <div class="col-12">
                                <label class="form-label">Email *</label>
                                <input type="text" class="form-control" :class="((errors.email != '' )? 'is-invalid':'')"
                                    name="email" v-model="user_info.email" :disabled="user_info.action=='Update'">
                                <div class="text-danger invalid-feedback" v-if="errors.email != ''">
                                    {{errors.email}}
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="user_info.action=='Update'">
                            <div class="col-12">
                                <label class="form-label">Password</label>
                                <input type="password" class="form-control" :class="((errors.password != '' )? 'is-invalid':'')" name="password" v-model="user_info.password" >
                                <div class="text-danger invalid-feedback" v-if="errors.password != ''">
                                    {{errors.password}}
                                </div>
                            </div>
                        </div>
                        <div v-if="user_notification.length == 0 && user_info.action!='Update'">
                            <div class="d-flex align-items-center mt-3">
                                <div class="spinner-border text-primary me-2" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Loading Notification Options
                            </div>
                        </div>
                        <div v-else-if="user_info.action!='Update'">
                            <h5 class="mt-3">User Notification Options</h5>
                        </div>
                        <div class="row" v-for="n in user_notification" v-if="user_info.action!='Update'">
                            <div class="col">
                                <label class="mb-1">
                                    <input class="form-check-input mt-0 me-2" type="checkbox" role="switch" :checked="n.active" @change="n.active = !n.active">
                                        {{n.name}} <i class="fas fa-question-circle ms-1 text-primary" data-bs-toggle="tooltip" :title="n.description"></i>
                                    </label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="alert alert-danger mt-3" v-if="form.error">
                                    There was an error saving the user information.
                                </div>
                            </div>
                        </div>

                        <div class="w-100 d-flex flex-row justify-content-center gap-3 mt-3">        

                            <button class="btn btn-secondary w-50" @click.prevent="closeModal()">
                                Cancel
                            </button>


                            <button  :disabled="form.busy" class="btn btn-primary w-50" @click.prevent="update()">
                                <div v-if="form.busy" class="spinner-border spinner-border-sm float-left" role="status"> <span class="visually-hidden">Loading...</span></div>
                                {{ user_info.action }} Team Member
                            </button>   
                        </div>  
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    
    export default {
        components: {Swal},
        props: ['client', 'user'],
        data() {
            return {
                filtered_user_list: [],
                if_show_org_column: false,
                user_list_shown:[],

                search_key: '',
                sort_key: null,
                sort_order: 'asc',
                num_per_page: 20, // default to 20
                num_per_page_options: [5,10,15,20,25,30,50,100],
                page_count: null,
                page_index: 0,
                page_index_array: [],
                item_interval: '',

                user_info:{
                    action: '',
                    id: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    client_id: this.client.id,
                    password: ''
                },
                errors: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: ''
                },
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                user_notification: []
            };
        },
        beforeMount() {
            this.filtered_user_list = [...this.client.users];
            this.getUserOrgList();
            this.reloadPagination();
            this.getNotificationOptions();
        },
        updated() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        methods: {

            getNotificationOptions() {
                window.axios.get('/api/notification/'+this.client.url+'/options', [])
                  .then(response => {
                        this.user_notification = response.data.notifications;
                  });
            },
            getUserOrgList(){
                var org_list = [];
                this.filtered_user_list.forEach((user)=>{
                    if (!org_list.includes(user.organization))
                        org_list.push(user.organization);
                });
                if (org_list.length > 1){
                    this.if_show_org_column = true;
                } else{
                    this.if_show_org_column = false;
                }
            },
            addTeamUser(){
                this.user_info = {
                    action: 'Add',
                    id: -1,
                    first_name: '',
                    last_name: '',
                    email: '',
                    client_id: this.client.id,
                    password: ''
                }
            },
            updateTeamUser(user){
                this.user_info = {
                    action: 'Update',
                    id: user.id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    organization: user.organization,
                    password: ''
                }
            },
            clearErrors() { //reset errors to empty strings
                this.errors = {
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: ''
                };
            },
            hasErrors() { //validate the form               
                if(this.user_info.first_name == null || this.user_info.first_name == ""){
                    this.errors.first_name = "First Name is required.";
                } else
                    this.errors.first_name = "";

                if(this.user_info.last_name == null || this.user_info.last_name == ""){
                    this.errors.last_name = "Last Name is required.";
                } else
                    this.errors.last_name = "";
                
                if(this.user_info.email == null || this.user_info.email == ""){
                    this.errors.email = "Email is required.";
                } else
                    this.errors.email = "";

                if(this.user_info.action == 'Update' && this.user_info.password != "" &&
                    this.user_info.password.length < 8){
                    this.errors.password = "The password must be at least 8 characters long. Leave it blank if you'd like to not change it.";
                } else
                    this.errors.password = "";

                if (this.errors.first_name!="" || this.errors.last_name!="" || this.errors.email!="" || this.errors.password!=""){
                    return true
                } else{
                    return false
                }
            }, 
            update() {
                var self = this

                this.clearErrors();
                if(this.hasErrors()) {
                    return;
                }
                this.form.busy = true;

                if(this.user_info.id < 0) { // add user
                    this.user_info.notification_preference = this.user_notification;
                    window.axios.post('/api/client/add_team_user', this.user_info)
                        .then(response => {
                            self.form.busy = false;
                            self.closeModal();

                            Swal.fire({
                                html: '<h4 class="mb-0 text-white">' + self.user_info.first_name + ' was added!</h4>',
                                target: '#org_settings_team',
                                icon: 'success',
                                toast: true,
                                position: 'bottom-right',
                                showConfirmButton: false,
                                timer: 2000,
                                iconColor: 'white',
                                customClass: {
                                    popup: 'bg-primary'
                                },
                            });                            
                            
                            self.filtered_user_list.push(response.data.user);
                            self.reloadPagination();
                            self.$forceUpdate();
                            
                        }).catch(error=> {
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                        });
                }
                else {
                    //Update a user
                    window.axios.put('/api/user/' +this.user_info.id, this.user_info)
                      .then(response => {
                        self.form.busy = false;
                        self.closeModal();

                        Swal.fire({
                            html: '<h4 class="mb-0 text-white">' + self.user_info.first_name + ' was updated!</h4>',
                            target: '#org_settings_team',
                            icon: 'success',
                            toast: true,
                            position: 'bottom-right',
                            showConfirmButton: false,
                            timer: 2000,
                            iconColor: 'white',
                            customClass: {
                                popup: 'bg-primary'
                            },
                        });

                        //Find the user ID in the this.filtered_user_list and update the name and email
                        var index = self.filtered_user_list.findIndex(x => x.id === self.user_info.id);
                        self.filtered_user_list[index].first_name = self.user_info.first_name;
                        self.filtered_user_list[index].last_name = self.user_info.last_name;

                        self.filtered_user_list[index].name = self.user_info.first_name + " " + self.user_info.last_name;
                        self.filtered_user_list[index].email = self.user_info.email;
                        self.$forceUpdate();

                      }).catch(error => {
                        // Handle errors here
                        if (error.response.status === 422) {
                          self.errors.email = 'The email entered is not in the correct format.';
                        }
                        else
                            self.form.error = true;
                            setTimeout( function() {self.form.error = false} , 10000);
                      });;
                    }
            },
            deleteTeamUser(user, index){
                var self = this;

                Swal.fire({
                  title: "Are you sure you want to remove "+ user.first_name + " " + user.last_name+"?",
                  text: "They will lose access to all dashboards and any communications from Avid.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, confirm",
                }).then((result) => {
                    if (result.isConfirmed) {
                        var req_body = {
                            client_id: this.client.id,
                            user_id: user.id
                        }
                        window.axios.post('/api/client/remove_user', req_body)
                        .then(response => {
                            this.filtered_user_list.splice(index, 1);
                            this.reloadPagination();
                            Swal.fire({
                                html: '<h4 class="mb-0 text-white">'+user.first_name+' was removed!</h4>',
                                target: '#org_settings_team',
                                icon: 'success',
                                toast: true,
                                position: 'bottom-right',
                                showConfirmButton: false,
                                timer: 2000,
                                iconColor: 'white',
                                customClass: {
                                    popup: 'bg-primary'
                                },
                            });
                        }).catch(error=> {
                            Swal.fire({
                                html: '<h4 class="mb-0 text-white">Error removing this user!</h4>',
                                target: '#org_settings_team',
                                icon: 'warning',
                                toast: true,
                                position: 'bottom-right',
                                showConfirmButton: false,
                                timer: 2000,
                                iconColor: 'white',
                                customClass: {
                                    popup: 'bg-primary'
                                },
                            });
                        });
                    }
                });
            },
            closeModal() {
                var myModalEl = document.getElementById('user-modal')
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();
            },
            sortByKey(key){
                this.sort_key = key;
                if (this.sort_key != key){
                    this.sort_order = 'asc'; // reset to ascending sorting order
                } else{
                    this.sort_order = (this.sort_order == 'asc'? 'des':'asc');
                }
                this.sortDataWithLabel();
            },
            sortDataWithLabel(){
                if(this.sort_order == 'asc')
                    this.filtered_user_list.sort((a, b) => (a[this.sort_key] > b[this.sort_key]) ? 1 : -1);
                else
                    this.filtered_user_list.sort((a, b) => (a[this.sort_key] < b[this.sort_key]) ? 1 : -1);
                this.reloadPagination();
            },
            reloadPagination(){
                this.page_count = Math.ceil(this.filtered_user_list.length / this.num_per_page);
                // this.page_index = 0;
                this.page_index_array = Array.from({length: this.page_count}, (_, i) => i + 1);
                this.getItemInterval();
            }, 
            getItemInterval(){
                var from = this.page_index * this.num_per_page + 1;
                var to = (this.page_index +1) * this.num_per_page;
                if(to>this.filtered_user_list.length)
                    to = this.filtered_user_list.length;
                if (from >= to) 
                    this.item_interval = to
                else 
                    this.item_interval = from +' - ' +to;
                
                this.user_list_shown = this.filtered_user_list.slice(from-1, to);
            },
            moveToPrevPage(){
                this.page_index--;
                this.getItemInterval();
            },
            moveToPage(page){
                this.page_index = page-1;
                this.getItemInterval();
            },
            moveToNextPage(){
                this.page_index++;
                this.getItemInterval();
            },
            searchUser(){
                if(this.search_key == "") 
                    this.filtered_user_list = [...this.client.users];
                else    
                    this.filtered_user_list = this.client.users.filter(user => user.name.toLowerCase().includes(this.search_key.toLowerCase()));
                this.reloadPagination();
            },

        }
    }
</script>
