<template>
    <div class="column-chart-template">
        <canvas :id="'chart-'+this.id" :class="(if_chart_has_data ? '':'hide')" class="column-chart chart-template" ></canvas>
        <div :class="(!if_chart_has_data ? 'd-inline-block':'hide')" class='text-center'>
            <h1 class="text-secondary_v1 mb-1"><i class="fa-solid fa-chart-simple"></i></h1>
            <h5 class="text-secondary_v1 mb-2"><em>Press the <strong>Preview</strong> button to render the chart</em></h5>
        </div>
    </div>
</template>

<script>
//    import ChartDataLabels from 'chartjs-plugin-datalabels';
   import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";

   export default {
       props: ['chart_selected', 'id', 'if_refreshing', 'if_on_view', 'if_benchmark_report', 'applied_chart_filter', 'if_hundred_percent'],
       data() {
           return {
               chart: null,
               color: null,
               formatType: null,
               if_chart_has_data: false,
               if_chart_filter_applied: false,
               chart_filter_label: [],
               chart_filter_label_index: [null, null],
               num_of_datasets: null,
               font_size: 12
           };
       },
       watch:{
            chart_selected: {
                handler: function(newVal, oldVal) {
                    // Since chart_selected is a complex object, you might want to perform
                    // a more specific check to determine if renderChart should be called.
                    // For now, we'll just check if it has 'data' to decide.
                    if (newVal.data) {
                        if(this.chart_selected.metric_formats[0] != undefined){
                            this.formatType = this.chart_selected.metric_formats[0];
                            this.color = this.chart_selected.metrics[0].color;
                        } else{
                            this.formatType = 'number';
                            this.color = 'yellow';
                        }
                        this.num_of_datasets= Object.keys(this.chart_selected.data).length;   
                        this.renderChart();
                        this.if_chart_has_data = true;
                    }
                },
                deep: true, // This makes sure the watcher is looking for changes deeply within the object
            },
            if_refreshing:{
                handler: function(newVal, oldVal) {
                    this.$forceUpdate();
                }
            },
            applied_chart_filter:{
                handler: function(newVal, oldVal) {
                    this.if_chart_filter_applied = this.applied_chart_filter.if_chart_filter_applied && this.id == this.applied_chart_filter.section_chart_id;
                    this.renderChart();
                }
            }
        },
       mounted() {
            var export_report_class = document.querySelector('.export-report');
            if (export_report_class){ // if on export view, make font bigger, set to 15 for now
                this.font_size = 15;
            }
            if (this.chart_selected.data){
                // loop through metric_objects in chart to find format 
                if(this.chart_selected.metric_formats[0] != undefined){
                    this.formatType = this.chart_selected.metric_formats[0];
                    this.color = this.chart_selected.metrics[0].color;
                } else{
                    this.formatType = 'number';
                    this.color = 'yellow';
                }
                this.num_of_datasets= Object.keys(this.chart_selected.data).length;
                this.renderChart();
                this.if_chart_has_data = true;
            }
       },
       methods: {
            renderChart() {
                var dataset = this.getChartData(); 
                var options = this.getChartOptions(dataset); 

                if(this.chart != null)
                    this.chart.destroy();

                var ctx = document.getElementById('chart-'+this.id);
                if(ctx != undefined)
                    this.chart = new Chart(ctx, options);            
            },
            getColor(color) { // colors are from dark to light shades
                const colors = {
                    yellows: [
                        'rgb(220, 150, 0)',
                        'rgb(235, 181, 0)',
                        'rgb(255, 203, 32)',
                        'rgb(255, 219, 102)',
                        'rgb(255, 229, 145)',
                        'rgb(255, 236, 174)',
                    ],
                    greens: [
                        'rgb(10, 110, 62)',
                        'rgb(15, 166, 92)',
                        'rgb(25, 207, 120)',
                        'rgb(69, 223, 149)',
                        'rgb(107, 243, 178)',
                        'rgb(167, 248, 209)',
                    ],
                    blues: [
                        'rgb(9, 71, 137)',
                        'rgb(10, 89, 250)',
                        'rgb(51, 149, 255)',
                        'rgb(122, 186, 255)',
                        'rgb(168, 204, 244)',
                        'rgb(211, 228, 247)',
                    ],
                    purples: [
                        'rgb(65, 1, 121)',
                        'rgb(133, 48, 209)',
                        'rgb(124, 82, 149)',
                        'rgb(183, 123, 244)',
                        'rgb(210, 168, 253)',
                        'rgb(223, 192, 255)',
                    ],
                    grays: [
                        'rgb(18, 18, 18)',
                        'rgb(57, 74, 69)',
                        'rgb(101, 121, 115)',
                        'rgb(143, 158, 153)',
                        'rgb(173, 188, 183)',
                        'rgb(200, 215, 211)',
                        'rgb(225, 234, 230)',
                    ],
                };
                if(color == 'yellow')
                    return colors.yellows;
                if(color == 'green')
                    return colors.greens;
                if(color == 'blue')
                    return colors.blues;
                if(color == 'purple')
                    return colors.purples;
                if(color == 'gray')
                    return colors.grays;
                return colors.yellows;
            },
            getBorderRadiusByDataLength(dataLength) {
                if (dataLength < 5) {
                    return 7;
                } else if (dataLength < 10) {
                    return 5;
                } else if (dataLength < 15) {
                    return 3;
                } else {
                    return 3;
                }
            },
            getChartData() {
                var datasetArray = [];
                var chartColors = this.getColor(this.color).slice(0,this.num_of_datasets);
                const borderRadius = this.getBorderRadiusByDataLength(this.num_of_datasets * Object.values(this.chart_selected.data)[0].length);
               
                if (this.num_of_datasets >1){
                    // multiple dataset
                    this.chart_filter_label_index[0] = this.chart_selected.labels.findIndex(label => label == this.chart_filter_label);  
                    
                    Object.keys(this.chart_selected.data).forEach((key,index) => {
                        if (this.if_chart_filter_applied){
                            var backgroundColorArray = Array(this.chart_selected.data[key].length).fill(this.getColor('gray')[3]);
                            backgroundColorArray[this.chart_filter_label_index[0]] = chartColors[chartColors.length-index-1];
                            datasetArray.push({
                                label: key==""? '(not set)':key,
                                data: this.chart_selected.data[key],
                                backgroundColor: backgroundColorArray,
                                borderWidth: 0,
                                borderRadius: borderRadius,
                            });  
                        } else if(this.chart_selected.highlight_last_value){
                            datasetArray.push({
                                label: key==""? '(not set)':key,
                                data: this.chart_selected.data[key],
                                backgroundColor: (index== this.num_of_datasets-1)? chartColors[0]: this.getColor('gray')[6-index-1],
                                borderWidth: 0,
                                borderRadius: borderRadius,
                            });  
                        }else{
                            datasetArray.push({
                                label: key==""? '(not set)':key,
                                data: this.chart_selected.data[key],
                                backgroundColor: chartColors[chartColors.length-index-1],
                                borderWidth: 0,
                                borderRadius: borderRadius,
                            });  
                        }
                    });
                } else {
                    // single dataset           
                    const chartColor = this.getColor(this.color)[0];

                    Object.keys(this.chart_selected.data).forEach(key => {
                        var backgroundColorArray = [];
                        if (this.if_chart_filter_applied){
                            var index = this.chart_selected.labels.findIndex(label => label == this.chart_filter_label[0]);
                            backgroundColorArray = Array(this.chart_selected.data[key].length).fill(this.getColor('gray')[3]);
                            backgroundColorArray[index] = chartColor;
                        } else if(this.chart_selected.highlight_last_value) {
                            backgroundColorArray = Array(this.chart_selected.data[key].length-1).fill(this.getColor('gray')[3]);
                            backgroundColorArray.push(chartColor);
                        }
                        else {
                            backgroundColorArray = Array(this.chart_selected.data[key].length).fill(chartColor);
                        }
                        datasetArray.push({
                            label: key==""? '(not set)':key,
                            data: this.chart_selected.data[key],
                            backgroundColor: backgroundColorArray,
                            borderWidth: 0,
                            borderRadius: borderRadius,
                        });
                    });
                }

                var labels_temp = [];
                this.chart_selected.labels.forEach((label)=>{
                    if (label){
                        labels_temp.push(label)
                    }else{
                        labels_temp.push('(not set)')
                    }
                });

                return {
                    labels: labels_temp,
                    datasets: datasetArray
                };
           },
           getChartOptions(data) {
                var self = this;
                var config = {
                    plugins: this.if_hundred_percent ? [ChartjsPluginStacked100]: [],
                    type: 'bar',
                    data: data,

                    //All of the special aspects that make these charts unique
                    options: {
                        // onClick: (e) => {
                        //     const canvasPosition = getRelativePosition(e, this.chart);

                        //     // Substitute the appropriate scale IDs
                        //     const dataX = this.chart.scales.x.getValueForPixel(canvasPosition.x);
                        //     const dataY = this.chart.scales.y.getValueForPixel(canvasPosition.y);
                        //     console.log(dataX, dataY);
                        // },
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        responsive: true,
                        animations: {
                            x: {
                                duration: 1000,
                            }
                        },
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 30
                            }
                        },
                        indexAxis: 'x',
                        scales: {
                            x: {
                                //If the type is bar-stacked, it will make it a stacked bar chart
                                stacked: true,
                                grid: {
                                    drawBorder: false,
                                    display : false
                                },
                                border: {
                                    display: false,
                                },
                                ticks: {
                                    font: {
                                        size: this.font_size,
                                        weight: 400,
                                        family: 'MDSystem-Regular',
                                    },
                                    maxRotation:0,
                                    autoSkip:false,
                                    callback: function(value, index, ticks, context) {
                                        // Wrap around long labels
                                        var label_substrings = '';
                                        if(self.chart_selected.labels[value]){
                                            let label = self.chart_selected.labels[value] + "";
                                            label_substrings = label.split(" ");
                                        } else {
                                            label_substrings = ['(not set)'];
                                        }
                                        for(var i = 0; i <label_substrings.length-1; i++){
                                            if ((label_substrings[i].length + 1 + label_substrings[i+1].length) < 15){ 
                                                // if two substrings have total length < 15, concat them together
                                                // Note: length=15 is just a temporary value which makes the chart looks fine with dummy data
                                                label_substrings[i] = label_substrings[i]+' '+label_substrings[i+1]
                                                label_substrings[i+1] = ''
                                            }
                                        }
                                        return label_substrings;
                                    }
                                }
                            },
                            y: {
                                display: true,
                                position: 'left',
                                beginAtZero: true,
                                stacked: true,
                                //For the ticks on the axes, format the numbers to make the pretty
                                border: {
                                    display: false,
                                    width: 2,
                                    dash: [5,5],
                                    dashOffset: 0,
                                },
                                grid: {
                                    drawOnChartArea: true,
                                    drawBorder: false,
                                    
                                    color: 'rgba(206, 219, 215, 1)',
                                    lineWidth: 1, // gridline thickness
                                    borderDash: [2,2], // gridline in the chart area that is perpendicular to this axis

                                    drawTicks: true,
                                    tickColor: 'rgba(206, 219, 215, 1)',
                                    tickLength: 20,
                                    tickBorderDash: [2,2],
                                    tickBorderDashOffset: 0,
                                    z: -2,
                                },
                                ticks: {
                                    font: {
                                        size: this.font_size,
                                        weight: 400,
                                        family: 'MDSystem-Regular',
                                    },
                                    autoSkip: true,
                                    maxTicksLimit: 5,
                                    callback: function(value, index, ticks, context) {

                                        if(self.formatType == 'money' && value < 1000)
                                            return self.$options.filters.currency_with_zero(value);
                                        if(self.formatType == 'money')
                                            return self.$options.filters.currency_abbr(value);
                                        if(self.formatType == 'number' && value < 1000)
                                            return self.$options.filters.number_with_decimal(value);
                                        if(self.formatType == 'number' )
                                            return self.$options.filters.num_abbr(value);
                                        if(self.formatType == 'percent')
                                            return self.$options.filters.percentage(value);
                                    }
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                //Only show the legend if there is more than one media type
                                position: (data.datasets.length > 1) ? 'bottom' : 'none',
                                align: 'start',
                                labels: {
                                    usePointStyle: true,
                                    generateLabels: (chart) => chart.data.datasets.map((dataset, index) => {
                                        return ({
                                            text: dataset.label,
                                            pointStyle: 'rect',
                                            fillStyle:  Object.keys(this.chart_selected.data).length > 1? dataset.backgroundColor :dataset.backgroundColor.slice(-1),
                                            //strokeStyle: dataset.borderColor,
                                            lineWidth: 0, // remove box border by default
                                        })
                                    }),
                                    font: {
                                        size: this.font_size,
                                        weight: 400,
                                        family: 'MDSystem-Regular',
                                    },
                                }
                            },
                            tooltip:  this.if_on_view ?
                            {
                                enabled: false,
                                xAlign: 'center',
                                external: function(context) {
                                    // Tooltip Element
                                    let tooltipEl = document.getElementById('chart-'+self.id+'-tooltip');

                                    // Create element on first render
                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div');
                                        tooltipEl.id = 'chart-'+self.id+'-tooltip';
                                        tooltipEl.classList.add("chart-template-tooltip");
                                        tooltipEl.innerHTML = '<table></table>';
                                        document.body.appendChild(tooltipEl);
                                    }

                                    const position = context.chart.canvas.getBoundingClientRect();
                                    // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

                                    // Display, position, and set styles for font
                                    const tooltipModel = context.tooltip;
                                    tooltipEl.style.opacity = 1;
                                    tooltipEl.style.position = 'absolute';
                                    tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX - tooltipModel.width/2 + 'px';// center align
                                    tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + 'px';
                                    tooltipEl.style.backgroundColor = 'white';
                                    tooltipEl.style.padding = '10px';
                                    tooltipEl.style.boxShadow = '2px 4px 20px 0px #0000001A';
                                    tooltipEl.style.fontFamily = 'MDSystem-Regular';


                                    // Hide if no tooltip 
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = 0;
                                        return;
                                    }
                                    tooltipEl.addEventListener('mouseover',()=>{
                                        tooltipEl.style.opacity = 1;
                                    });
                                    tooltipEl.addEventListener('mouseout',()=>{
                                        tooltipEl.style.opacity = 0;
                                    });

                                    // Set caret Position
                                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                                    if (tooltipModel.yAlign) {
                                        tooltipEl.classList.add(tooltipModel.yAlign);
                                    } else {
                                        tooltipEl.classList.add('no-transform');
                                    }

                                    // Set Text
                                    if (tooltipModel.body) {
                                        const titleLines = tooltipModel.title || [];
                                        const bodyLines = tooltipModel.body.map(b => b.lines);

                                        let innerHtml = '<thead>';
                                        
                                        // title
                                        innerHtml += '<tr><th>' + titleLines[0] + '</th></tr>';
                                        innerHtml += '</thead><tbody>';

                                        // Calculate the total for each dataset
                                        var total = [];
                                        for (var i = 0; i<self.chart_selected.labels.length; i++){
                                            var temp = 0;
                                            Object.values(self.chart_selected.data).forEach((dataArray)=>{
                                                temp += dataArray[i]
                                            })
                                            total.push(temp);
                                        }
                                        var tooltipBody = ''

                                        bodyLines.forEach(function(body, i) {
                                            var label = '';
                                            var body_label = body[0].split(': ');
                                            var value = body_label[1].split(',').join('') * 1;
                                            
                                            var colors = tooltipModel.labelColors[i];
                                            var style = 'background-color:' + colors.backgroundColor + '; width:10px; height:10px; display:inline-block; margin-right:5px'
                                            var colorDiv = '<div style="' + style + '"></div>';

                                            if(self.num_of_datasets > 1){

                                                    // if(self.formatType == 'money')
                                                    //     label = body_label[0] + ': ' + self.$options.filters.currency_with_zero(context.tooltip.dataPoints[i].dataset.data[context.tooltip.dataPoints[i].dataIndex]) + ' ('+ value +'%)';
                                                    // if(self.formatType == 'number')
                                                    //     label = body_label[0] + ': ' + self.$options.filters.number_with_decimal(context.tooltip.dataPoints[i].dataset.data[context.tooltip.dataPoints[i].dataIndex]) + ' ('+ value +'%)';
                                                    // if(self.formatType == 'percent')
                                                    //     label = body_label[0] + ': ' + self.$options.filters.percentage(context.tooltip.dataPoints[i].dataset.data[context.tooltip.dataPoints[i].dataIndex]) + ' ('+ value +'%)';
                                                    
                                                    // tooltipBody =  '<tr><td>'+ colorDiv + '<span>' + label + '</span></td></tr>' + tooltipBody; // stacked tooltip loads in reverse order
                                                    if(self.formatType == 'money')
                                                        label = body_label[0] + ': ' + self.$options.filters.currency_with_zero(value) + ' ('+ self.$options.filters.percentage(value / total[context.tooltip.dataPoints[i].dataIndex]) +')';
                                                    if(self.formatType == 'number')
                                                        label = body_label[0] + ': ' + self.$options.filters.number_with_decimal(value) + ' ('+ self.$options.filters.percentage(value / total[context.tooltip.dataPoints[i].dataIndex]) +')';
                                                    if(self.formatType == 'percent')
                                                        label = body_label[0] + ': ' + self.$options.filters.percentage(value) + ' ('+ self.$options.filters.percentage(value / total[context.tooltip.dataPoints[i].dataIndex]) +')';
                                                    
                                                    tooltipBody =  '<tr><td>'+ colorDiv + '<span>' + label + '</span></td></tr>' + tooltipBody; // stacked tooltip loads in reverse order
                                          

                                            }else{
                                                if(self.formatType == 'money')
                                                    label = self.$options.filters.currency_with_zero(value);
                                                if(self.formatType == 'number')
                                                    label = self.$options.filters.number_with_decimal(value);
                                                if(self.formatType == 'percent')
                                                    label = self.$options.filters.percentage(value);
                                                
                                                tooltipBody +=  '<tr><td><span class="tooltip-single-dataset-value">' + label + '</span></td></tr>';   
                                            }
                                        });
                                        innerHtml += tooltipBody;
                                        innerHtml += '<hr class="m-1">';
                                        
                                        if (self.if_chart_filter_applied && self.chart_filter_label == titleLines[0]){
                                            innerHtml += '<button class="btn btn-none tooltip-btn-none mt-1 ps-0"><img class="icon ps-0" src="/img/icons/dialexa-icons/filter-search.svg">Remove as Filter</button>'
                                        } else {
                                            innerHtml += '<button class="btn btn-none tooltip-btn-none mt-1 ps-0"><img class="icon ps-0" src="/img/icons/dialexa-icons/filter-search.svg">Explore</button>'
                                        }
                                        innerHtml += '</tbody>';   

                                        let tableRoot = tooltipEl.querySelector('table');
                                        tableRoot.innerHTML = innerHtml;

                                        let btn = tooltipEl.querySelector('button');
                                        if (self.if_chart_filter_applied && self.chart_filter_label != titleLines[0]){
                                            btn.disabled=true;
                                        }
                                        // if chart filter applied on any other chart, disable explore for this current chart to block cross filtering for now
                                        // this is temporyary till we add cross filtering in
                                        if (self.applied_chart_filter.if_chart_filter_applied && self.if_chart_filter_applied == false){
                                            btn.disabled=true;
                                        }

                                        if (self.if_chart_filter_applied){
                                            btn.addEventListener('click', () => {
                                                self.if_chart_filter_applied = false;
                                                self.chart_filter_label = [];
                                                self.$emit('exploreChart', {
                                                    chart_selected: null,
                                                    chart_filters: []
                                                });
                                                var dataset = self.getChartData(); 
                                                self.chart.data = dataset;
                                                self.chart.update();
                                            });
                                        } else {
                                            btn.addEventListener('click', () => {
                                                self.if_chart_filter_applied = true;
                                                self.chart_filter_label = [];

                                                self.if_chart_filter_applied = true;
                                                self.chart_filter_label = titleLines[0];
                                                self.$emit('exploreChart', {
                                                    chart_selected: self.chart_selected,
                                                    chart_filters: [{
                                                        name: self.chart_selected.group_by[0].text,
                                                        column: self.chart_selected.group_by[0].value,
                                                        value: titleLines[0]
                                                    }]
                                                });

                                                // set the other columns as grayscale and change button text
                                                var dataset = self.getChartData(); 
                                                let btn = tooltipEl.querySelector('button');
                                                btn.textContent = "Remove as Filter";
                                                self.chart.data = dataset;
                                                self.chart.update();
                                            });
                                        }

                                        // tableRoot.appendChild(btn);
                                    }  
                                }
                            }:{
                                callbacks: {
                                    title: function(tooltipItems, data) {
                                        //Return value for title
                                        return tooltipItems[0].label;
                                    },
                                    label: function(context) {
                                        if(self.formatType == 'money')
                                            return context.dataset.label + ': ' + self.$options.filters.currency_with_zero(context.parsed.y);
                                        if(self.formatType == 'number')
                                            return context.dataset.label + ': ' + self.$options.filters.number_with_decimal(context.parsed.y);
                                        if(self.formatType == 'percent')
                                            return context.dataset.label + ': ' +  self.$options.filters.percentage(context.parsed.y);
                                    }
                                }
                            },
                            datalabels: {
                                anchor: 'end', // Specify the anchor position for the labels
                                align: 'end', // Specify the alignment of the labels
                                // color: this.getColor(this.color)[0],
                                display: true,
                                font: {
                                    weight: 'regular',
                                    size: this.font_size,
                                },
                                formatter: function(value, context) {
                                    return null;
                                    // if (self.if_chart_filter_applied && self.applied_chart_filter.chart_filters.length){
                                    //     if (self.chart_filter_label.length > 1){ 
                                    //         if(context.dataIndex != self.chart_filter_label_index[0] || context.datasetIndex != self.chart_filter_label_index[1])
                                    //             return null;
                                    //     } else {
                                    //         if(context.dataIndex != self.chart_filter_label_index[0])
                                    //             return null;
                                    //     }
                                    // }else {
                                    //     if(self.chart_selected.highlight_last_value && context.datasetIndex != Object.keys(self.chart_selected.data).length -1){
                                    //         return null;
                                    //     }
                                    // }

                                    // var label;
                                    // if(self.formatType == 'money' && value < 1000)
                                    //     label = self.$options.filters.currency_with_zero(value);
                                    // if(self.formatType == 'money')
                                    //     label = self.$options.filters.currency_abbr(value);
                                    // if(self.formatType == 'number' && value < 1000)
                                    //     label = self.$options.filters.number_with_decimal(value);
                                    // if(self.formatType == 'number' )
                                    //     label = self.$options.filters.num_abbr(value);
                                    // if(self.formatType == 'percent')
                                    //     label = self.$options.filters.percentage(value);
                                    // return label;
                                },
                            },
                            stacked100: { enable: true, replaceTooltipLabel: false }  
                        },
                        title: {
                            display:false,
                        },
                    }
               };
               return config;
           }
       }
   }
</script>
