<template>
    <div class="landing-page">
        <h1 class="text-center mt-5">What would you like to do today?</h1>
        <div class="row mb-2 mt-5 justify-content-center" v-if="client.plan_level == 'insights' || client.plan_level == 'trial'">
            <div class="col-12 col-sm-10 col-md-12 col-lg-10 col-xl-10">
                <div class="card box-content mx-2 mb-4 two-space-tile">
                    <div class="card-body text-center tile-body">
                        <a :href="'/insights/'+client.url">
                            <i class="fa-solid fa-chart-pie fa-5x"></i>
                            <h4 class="mt-3 mb-0">Insights</h4>
                            <p class="tile-description">Explore the latest insights from your fundraising and marketing program</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-2 justify-content-center">
            <div class="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5">
                <div class="card box-content mx-2 mb-4 tile">
                    <div class="card-body text-center tile-body">
                        <a :href="'/settings/v1/'+client.url+'/integrations'">
                            <i class="fa-solid fa-plug fa-5x"></i>
                            <h4 class="mt-3 mb-0">Setup Integrations</h4>
                            <p class="tile-description">Create or update connections into your 3rd party systems</p>
                        </a>
                    </div>
                </div>
            </div>
            
            <div class="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5" v-if="client.census_api_key && (client.plan_level == 'insights' || client.plan_level == 'trial')">
                <div class="card box-content mx-2 mb-4 tile" >
                    <div class="card-body text-center tile-body">
                        <a :href="'/pathways/'+client.url+'/push_segment/'">
                            <i class="fa-solid fa-arrow-right-arrow-left fa-5x"></i>
                            <h4 class="mt-3 mb-0">Create Pathways</h4>
                            <p class="tile-description">Link your marketing and fundraising tools together to keep your data in sync</p>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5"  v-else>
                <div class="card box-content mx-2 mb-4 tile" >
                    <div class="card-body text-center tile-body">
                        <a :href="'/benchmarks/'+client.url">
                            <i class="fa-solid fa-chart-pie fa-5x"></i>
                            <h4 class="mt-3 mb-0">Benchmarks</h4>
                            <p class="tile-description">Explore the latest trends across the non-profit industry</p>
                        </a>
                    </div>
                </div>
            </div>
            
            <div class="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5" v-if="user.agency_id != null && user.organization == 'NextAfter'">
                <div class="card box-content mx-2 mb-4 tile" >
                    <div class="card-body text-center tile-body">
                        <a v-if="published_plan" :href="'/results/'+client.url+'/projections'">
                            <i class="fa-solid fa-chart-simple fa-5x"></i>
                            <h4 class="mt-3 mb-0">View Projections vs. Actuals</h4>
                            <p class="tile-description">Track the performance of your initiatives compared to initial projections</p>
                        </a>
                        <a v-else-if="active_plan" :href="'/planning/'+active_plan.url+'/annual'">
                            <i class="fa-solid fa-chart-simple fa-5x"></i>
                            <h4 class="mt-3 mb-0">Update an Annual Plan</h4>
                            <p class="tile-description">Build program projections tied to individual initiative performance</p>
                        </a>
                        <a v-else :href="'/planning/'+client.url+'/versions'">
                            <i class="fa-solid fa-chart-simple fa-5x"></i>
                            <h4 class="mt-3 mb-0">Create an Annual Plan</h4>
                            <p class="tile-description">Create a new plan for your upcoming fiscal year</p>
                        </a>
                    </div>
                </div>                
            </div>

            <div v-if="campaign_count != 0 && user.agency_id != null" class="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5" >
                <div class="card box-content mx-2 mb-4 tile">
                    <div class="card-body text-center tile-body">
                        <a :href="'/campaigns/'+client.url">
                            <i class="fa-solid fa-envelopes-bulk fa-5x"></i>
                            <h4 class="mt-3 mb-0">View All Campaigns</h4>
                            <p class="tile-description">Create a new campaign, import an existing campaign to create additional assets, or add assets to an existing campaign</p>
                        </a>
                    </div>
                </div>                
            </div>
            <div v-if="campaign_count == 0 && user.agency_id != null" class="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5">
                <div class="card box-content mx-2 mb-4 tile">
                    <div class="card-body text-center tile-body">
                        <a :href="'/campaigns/'+client.url+'/new'">
                            <i class="fa-solid fa-envelopes-bulk fa-5x"></i>
                            <h4 class="mt-3 mb-0">Create a Campaign</h4>
                            <p class="tile-description">Build a new campaign based upon your strategic goals</p>
                        </a>
                    </div>
                </div>
            </div>            
            <div v-if="experiment_treatment_count!=0 && user.agency_id != null" :class="((tile_count % 2 == 1 )? 'col-12 col-sm-10 col-md-12 col-lg-10 col-xl-10':'col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5')">
                <div class="card box-content mx-2 mb-4" :class="((tile_count % 2 == 1 )? 'two-space-tile':'tile')">
                    <div class="card-body text-center tile-body">
                        <a :href="'/experiment_treatments/'+client.url">
                            <i class="fa-solid fa-flask fa-5x"></i>
                            <h4 class="mt-3 mb-0">View All Experiment Treatments</h4>
                            <p class="tile-description">Build a new version of an individual asset outside of an existing campaign</p>
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="experiment_treatment_count==0 && user.agency_id != null" :class="((tile_count % 2 == 1 )? 'col-sm-10 col-md-12 col-lg-10 col-xl-10':'col-sm-10 col-md-6 col-lg-5 col-xl-5')">
                <div class="card box-content mx-2 mb-4" :class="((tile_count % 2 == 1 )? 'two-space-tile':'tile')">
                    <div class="card-body text-center tile-body">
                        <a :href="'/experiment_treatments/'+client.url+'/new'">
                            <i class="fa-solid fa-flask fa-5x"></i>
                            <h4 class="mt-3 mb-0">Create an Experiment Treatment</h4>
                            <p class="tile-description">Build a new version of an individual asset outside of an existing campaign</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-----------------------------------------end of tiles  ------------------------------------------>
        <div class="row mb-3 justify-content-center" v-if="notif.length">
            <div class="col-12 col-sm-10 col-md-12 col-lg-10 col-xl-10">
                <h3>Notifications ({{ notif.length }})</h3>
                <div class="card box-content mx-2">
                    <div class="card-body">
                        <div v-for="(n, index) in notif" :key="n.id">
                            <span class="float-end lh-1">{{n.created_at | date}}</span>
                            <h5 class="mb-2">{{ n.data['client_name'] }}</h5>
                            <p>{{ n.data['error_msg'] }}</p>
                            <p class='mb-0'>
                                <button  v-if="n.data['cta'] != null " class="btn btn-sm btn-primary me-2" v-on:click="markRead(n)">{{ n.data['cta'] }}</button> 
                                <button class="btn btn-secondary btn-sm" v-on:click="dismiss(n)">Dismiss</button>
                            </p>
                            <hr v-if="index != notif.length -1"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['user', 'client', 'campaigns', 'published_plan', 'active_plan', 'notifications'],
        data() {
            return {
                notif:[],
                campaign_count: 0,
                experiment_treatment_count: 0,
                tile_count: 0
            };
        },
        watch:{

        },
        beforeMount(){
            this.campaigns.forEach((c) => {
                if(c.campaign_type_id == 0){
                    this.experiment_treatment_count++;
                }else{  
                    this.campaign_count++;
                }
            });
        },
        mounted() {
            this.notif = this.notifications.filter((n) => {
                return n.data['client_id'] == this.client.id || n.data['client_name'] == this.client.name
            })
            this.tile_count = 1; // one tile always display: integration
            if(this.client.census_api_key){
                // show pathway tile
                this.tile_count++;
            }
            if(this.user.agency_id != null){
                // show planning tile
                this.tile_count++;
            
                if(this.campaign_count== 0){
                    // show add campaign tile
                    this.tile_count++;
                }
                if(this.campaign_count != 0){
                    // show view campaigns tile
                    this.tile_count++;
                }
                if(this.experiment_treatment_count== 0){
                    // show add experiment treatment tile
                    this.tile_count++;
                }
                if(this.experiment_treatment_count != 0){
                    // show view experiment treatment tile
                    this.tile_count++;
                }
            }
        },
        methods: {
            markRead(n) {
                this.dismiss(n);
                document.location = n.data['url'];
            },
            dismiss(n) {
                window.axios.get('/api/notification/read/' + n.id);

                //Remove the notification from the list
                for(var i = 0; i < this.notif.length; i++) {
                    if(this.notif[i].id == n.id) {
                        this.notif.splice(i, 1);
                    }
                }
            },
        }
    }
</script>
