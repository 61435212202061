<template>
    <div class="chart-preview">
        <div class="row">
            <div class="mb-2">
                <label class="form-label d-block">Preview Size: </label>
                <button class="btn mb-2"
                :class="((preview_size_selected=='small') ? 'btn-primary':'btn-secondary')"
                @click="changePreviewSize('small')">Small</button>

                <button class="btn mb-2"
                :class="((preview_size_selected=='medium') ? 'btn-primary':'btn-secondary')"
                @click="changePreviewSize('medium')">Medium</button>

                <button class="btn mb-2"
                :class="((preview_size_selected=='large') ? 'btn-primary':'btn-secondary')"
                @click="changePreviewSize('large')">Large</button>

                <button class="btn mb-2"
                :class="((preview_size_selected=='xl') ? 'btn-primary':'btn-secondary')"
                @click="changePreviewSize('xl')">XL</button>
            </div>
        </div>

        <div v-if="local_chart_selected.type != 'textbox'" class="row mb-1">
            <p>Data Source Table Name: <code>{{ local_chart_selected.data_blend.table_name }}</code></p>
            
        </div>
        <div class="row">
            <div v-if="!if_loading" :class="chart_preview_classname">

                <div class= "mb-3" >
                    <div class="card box-content" v-if="local_chart_selected.type" >
                        <div class="card-body">

                            <div v-if="local_chart_selected.type!='scorecard'">
                                <h4 :class="(local_chart_selected.subtitle != null && local_chart_selected.subtitle != '') ? 'mb-1' : 'mb-3'" v-if="local_chart_selected.title != null && local_chart_selected.title != ''">{{local_chart_selected.title}}</h4>

                                <p class="mb-3" v-if="local_chart_selected.subtitle != null && local_chart_selected.subtitle != ''"><em>{{local_chart_selected.subtitle}}</em></p>
                            </div>
                            <div v-else>
                                <p>{{local_chart_selected.title}}</p>
                            </div>
                            <chart-template  :chart="local_chart_selected"></chart-template>
                        </div>
                    </div>

                    <div class="card box-content" v-else >
                        <div class="card-body pt-5 pb-4">
                            <h1 class="text-primary text-center"><i class="fa-solid fa-chart-simple"></i></h1>
                            <h3 class="text-primary text-center "><em>No chart type has been selected</em></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="card box-content">
                    <div class="card-body pt-5 pb-4">
                        <h1 class="text-primary text-center"><div class="spinner-border  spinner-border-sm float-left" role="status"></div></h1>
                        <h3 class="text-primary text-center "><em>Loading Chart Data</em></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // This will be a wrapper vue for Chart on Chart Builder
    export default {
        components: {},
        props: ['chart_selected', 'if_loading', 'chart_type', 'render_large_chart'],
        data() {
            return {
                local_chart_selected: {},
                preview_size_selected: 'large',
                chart_preview_classname: this.render_large_chart? 'col-9':'col'
                // local_charts: null,
            };
        },
        watch:{
            chart_type: {
                handler: function(newVal, oldVal) {
                    this.local_chart_selected.type = this.chart_type;
                    this.$forceUpdate();
                },
                deep: true, // This makes sure the watcher is looking for changes deeply within the object
            },
            chart_selected: {
                handler: function(newVal, oldVal) {
                    // console.log('chart_preview')
                    this.local_chart_selected = this.chart_selected;
                    this.$forceUpdate();
                },
                deep: true, // This makes sure the watcher is looking for changes deeply within the object
            },
        },
        beforeMount() {
            this.local_chart_selected = this.chart_selected;
        },
        methods: {
            getClassNameBySize(size){
                if(this.render_large_chart){
                    if(size=='small')
                        return 'col-4'
                    if(size=='medium')
                        return 'col-6' 
                    if(size=='large')
                        return 'col-9' 
                } else {
                    if(size=='small')
                        return 'col-6'
                    if(size=='medium')
                        return 'col-9' 
                    if(size=='large')
                        return 'col-12'                    
                }

                return 'col';
            },
            changePreviewSize(size){
                this.preview_size_selected = size;
                this.chart_preview_classname = this.getClassNameBySize(size);
                //emit the change
                this.$emit('changePreviewSize', size);
            }
        }
    }
</script>
