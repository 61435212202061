<template>
    <div id="org_settings_connections_files">
        <div class="d-flex">
            <div class="card card-has-table flex-grow-1" v-if="files.length > 0">
                <div class="card-header">
                    <div class="d-flex flex-row justify-content-between">
                        <div class="d-flex justify-content-start align-items-center flex-grow-1">
                            <h5>Data Files ({{local_num_files | number_with_zero}})</h5>
                        </div>
                        <div class="d-flex justify-content-start align-items-center">
                            <div class="position-relative">
                                <input type="text" class="form-control me-1" placeholder="Search by File Name..." aria-label="Search by File Name..." v-model="search_key" @input="debouncedSearchOptions">
                                <i class="fa fa-search"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body p-0">
                    <table class="table table-responsive table-with-pagination m-0">
                        <thead>
                            <tr class="table-header-row">
                                <th v-on:click="sortByKey('name')" class="table-column-1">
                                    <div class="d-flex flex-row">
                                        File Name
                                        <div class="d-flex flex-column">
                                            <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='name' && sort_order=='asc')? 'sort-key-active' :''">
                                            <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='name' && sort_order=='des')? 'sort-key-active' :''">                                                            
                                        </div>
                                    </div>
                                </th>
                                <th v-on:click="sortByKey('status')" class="table-column-2">
                                    <div class="d-flex flex-row">
                                        Status
                                        <div class="d-flex flex-column">
                                            <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='status' && sort_order=='asc')? 'sort-key-active' :''">
                                            <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='status' && sort_order=='des')? 'sort-key-active' :''">                                                        
                                        </div>
                                    </div>
                                </th>
                                <th v-on:click="sortByKey('dataset')" class="table-column-3">
                                    <div class="d-flex flex-row">
                                        Data Set
                                        <div class="d-flex flex-column">
                                            <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='dataset' && sort_order=='asc')? 'sort-key-active' :''">
                                            <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='dataset' && sort_order=='des')? 'sort-key-active' :''">                                                          
                                        </div>
                                    </div>
                                </th>
                                <th v-on:click="sortByKey('created_at')" class="table-column-4">
                                    <div class="d-flex flex-row">
                                        Upload Date
                                        <div class="d-flex flex-column">
                                            <img class="sort-key" src="/img/icons/dialexa-icons/chevron-up.svg" :class="(sort_key=='created_at' && sort_order=='asc')? 'sort-key-active' :''">
                                            <img class="sort-key" src="/img/icons/dialexa-icons/chevron-down.svg" :class="(sort_key=='created_at' && sort_order=='des')? 'sort-key-active' :''">                                                            
                                        </div>
                                    </div>
                                </th>
                                <th  class="table-column-5"><div class="d-flex flex-row">Action</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="filtered_file_list.length == 0 && search_key != ''" class="table-row-1 no-results-text">
                                <td class="align-start">
                                    There is no file match the given search.
                                </td>
                            </tr>
                            <tr v-else v-for="(file, index) in file_list_shown" :class="`table-row-${index+1}`">
                                <td class="align-start">
                                    {{ file.name }}
                                    <small class="ms-2"><em>({{ file.source }})</em></small>
                                </td>
                                <td class="align-start">
                                    <span style="cursor: pointer;" v-if="file.id >=0" :title="file.status_msg" :id="'tooltip' + file.id"
                                    :onmouseover="'$(\'#tooltip' + file.id+ '\').tooltip(\'show\');'" >
                                        <span class="badge" :class="getColorClass(file.status) === 'green' ? 'badge-success' : getColorClass(file.status) === 'yellow' ? 'badge-warning' : 'badge-danger'">{{file.status | propercase}}</span>
                                        <!-- <i class="fa-solid fa-circle me-2" :class="getColorClass(file.status)"></i> {{file.status | propercase}} -->
                                    </span>
                                    <span v-else>
                                        <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Processing...</span></div>
                                        <span class="ms-2">Processing...</span>
                                    </span>
                                </td>
                                <td class="align-start">
                                    <span v-if="file.dataset"> {{ file.dataset.name | propercase }}</span>
                                    <a class="btn btn-sm btn-secondary" v-else-if = "file.id >= 0 && file.status == 'ready' && isDataFile(file)" :href="'/integrations/'+client.url+'/mapping/file/'+file.id">
                                        <img class="icon pe-1" src="/img/icons/dialexa-icons/plus.svg">Add
                                    </a>
                                </td>
                                <td class="align-start">
                                    {{ file.created_at | date }}
                                </td>
                                <td class="text-end show-on-row-hover">
                                    <button class="btn btn-none" @click="viewFile(file)" v-if="file.dataset != null">
                                        <img class="icon" src="/img/icons/dialexa-icons/edit.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit File" > 
                                    </button> 
                                    <button v-if="isDataFile(file)" class="btn btn-none" @click="previewTable(file)" data-bs-toggle="modal" data-bs-target="#preview-modal">
                                        <img class="icon" src="/img/icons/dialexa-icons/eye.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Preview File" > 
                                    </button>                 
                                    <a v-if="withinThirtyDays(file)" class="btn btn-none" :href="'/integrations/'+ client.url +'/files/download/'+ file.id" target="_blank">
                                        <img class="icon" src="/img/icons/dialexa-icons/download.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Download File" > 
                                    </a>                            
                        
                                    <button class="btn btn-none me-1" @click="removeFile(file)">
                                        <img class="icon" src="/img/icons/dialexa-icons/trash.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete File" > 
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="table-border-bottom-0">
                            <tr>
                                <td colspan="100%" class="py-0">
                                    <div class="table-pagination">
                                        <div class="table-pagination--group">
                                            <span>Showing: </span>
                                            <select class="form-select" name="num_per_page" id="num_per_page" v-model="num_per_page" @change="reloadPagination()">
                                                <option value="5000" >ALL</option>
                                                <option v-for="num in num_per_page_options" :value="num">{{ num }}</option>
                                            </select>
                                            <span>Files</span>
                                        </div>

                                        <div class="table-pagination--group">
                                            {{ item_interval }} of {{ local_num_files | number_with_zero}}
                                        </div>

                                        <div class="table-pagination--group">
                                            <button v-if="local_num_files/num_per_page>1" class="btn btn-none px-1" @click="moveToPrevPage()" :disabled="page_index == 0 ">
                                                <img class="icon" src="/img/icons/dialexa-icons/chevron-left.svg">
                                            </button>   

                                            <div v-if="local_num_files/num_per_page<=3">
                                                <button  v-for="page in page_index_array" class="btn btn-none px-1" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                                {{page}}
                                                </button>  
                                            </div>
                                            <div v-else>
                                                <button v-for="page in page_index_array.slice(0,2)" class="btn btn-none px-1" :class="page ==page_index + 1 ? 'current-page':''" @click="moveToPage(page)">
                                                {{page}}
                                                </button>  

                                                <button v-if="page_index > 2" class="btn btn-none px-1" disabled>
                                                ...
                                                </button>  
                                                <button v-if="page_index >= 2 && page_index< Math.ceil(local_num_files/num_per_page) -1" class="btn btn-none px-1 current-page">
                                                    {{page_index + 1 }} 
                                                </button>  

                                                <button v-if="page_index < local_num_files/num_per_page -2" class="btn btn-none px-1" disabled>
                                                ...
                                                </button>  
                                                <button  class="btn btn-none px-1" :class="page_index == Math.ceil(local_num_files/num_per_page)-1 ? 'current-page':''" @click="moveToPage(Math.ceil(local_num_files/num_per_page))">
                                                {{ Math.ceil(local_num_files/num_per_page) }}
                                                </button>  
                                            </div>

                                            <button v-if="page_count>1" class="btn btn-none px-1" @click="moveToNextPage()" :disabled="page_index == Math.ceil(local_num_files/num_per_page) -1 ">
                                                <img class="icon" src="/img/icons/dialexa-icons/chevron-right.svg">
                                            </button>   
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>


            <h5 v-else>No files have been uploaded yet. 
                <a href="#" @click.prevent="openAddFileModal()">Click here to add a file.</a>
            </h5>

        </div>
        <div class="modal fade px-0" id="preview-modal">
            <div class="modal-dialog " :class="modal_size">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="btn btn-none float-end" data-bs-dismiss="preview-modal" @click="closePreviewModal()">
                                <i class="fa fa-close me-0"></i>
                            </button>
                            <button type="button" class="btn btn-none float-end" @click="toggleModalSize()">
                                <i class="fa" :class="(modal_size == 'modal-xl' ? 'fa-expand' : 'fa-compress')"></i>
                            </button>
                            <h4 class="mt-2 mb-3">Preview File Contents</h4>

                        </div>
                    </div>
                    <div class="modal-body p-0">
                        <div v-if="typeof table_data === 'string' && table_data == ''" class="m-2 mb-4 d-flex flex-column gap-2 align-items-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <h5 class="text-center">Loading File Contents</h5>
                        </div>
                        <div v-else-if='Array.isArray(table_data) && table_data.length > 0'>
                            <div class="table-responsive gy-0 m-0 mt-0">
                                <table class="table table-responsive border rounded table-with-pagination gy-0 mb-3">
                                    <thead>
                                        <tr class="table-header-row">
                                            <th v-for="(row, index) in Object.keys(table_data[0])" class='align-middle'
                                                :class="(index==0) ? 'ps-3' : ''">{{originalHeader(row)}}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="row in table_data" >
                                            <td v-for="(col, index) in row" :class="(index==0) ? 'ps-3' : ''">{{ col }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-else-if='Array.isArray(table_data) && table_data.length == 0'>
                            <div class="alert alert-danger box-content m-2"  >
                                No data was returned.
                            </div>
                        </div>
                        <div v-else>
                            <div class="alert alert-danger box-content m-2" >
                                There was an error pulling the data:<br>
                                <em>{{table_data}}</em>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import debounce from 'lodash.debounce';

    export default {
        props: ['client', 'files', 'num_files'],
        data() {
            return {
                local_connection: null,
                filtered_file_list: [],
                file_list_shown:[],
                search_key: '',
                
                sort_key: null,
                sort_order: 'asc',
                // filters: {},
                // pagination
                num_per_page: 25, // default to 25
                num_per_page_options: [5,10,15,20,25,30,50,100],
                page_count: null,
                page_index: 0,
                page_index_array: [],
                item_interval: '',
                // preview modal
                modal_size: 'modal-xl',
                table_data: null,
                current_file: null,
                local_num_files: null,
            };
        },
        filters: {
            date: function (value) {
                if(value == null) return ""
                return moment.utc(value).local().format('M/DD/YYYY h:mm a');
            }
        },
        watch: {
            files(new_value, old_value) {
                this.searchFiles();
            },
        },
        beforeMount() {
            this.local_num_files = this.num_files;
            if(this.files.length > 0){
                this.filtered_file_list = [...this.files];
                this.reloadPagination();
            }
            this.local_connection = this.connection;
            // this.setupFilter();
        },
        created() {
            this.debouncedSearchOptions = debounce(this.searchFiles, 500);
        },
        methods: {
            
            getColorClass(status) {
                if(status == 'received' || status == 'processing')
                    return 'yellow';
                if(status == 'ready')
                    return 'green';

                return 'red';
            },
            isDataFile(file) {
                // If the file type is a csv, tab, tsv, or txt
                return file.status == 'ready' && ['csv', 'tsv', 'tab', 'txt'].includes(file.type);
            },
            previewTable(file) {
                var self = this;
                this.current_file = file;
                this.table_data = "";

                window.axios.post('/api/bigquery/get_detailed_samples', {'data_source': 'file', 'data_source_id': file.id }).then(response => {
                    self.table_data = response.data.samples;

                  }).catch(function (error) {
                    self.table_data = error;
                  })
            },
            closePreviewModal() {
                var myModalEl = document.getElementById('preview-modal')
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();
            },            
            toggleModalSize() {
                if(this.modal_size == 'modal-fullscreen')
                    this.modal_size = 'modal-xl';
                else
                    this.modal_size = 'modal-fullscreen';
            },
            originalHeader(value) {
                //Do a foreach loop through this.current_file.columns with a key/val
                for (var key in this.current_file.columns) {
                    if(key == value)
                        return this.current_file.columns[key];
                }
                return value;
            },
            withinThirtyDays(file) {
                //If the file.created_at is within the last 30 days
                return moment().subtract(30, 'days').isBefore(file.created_at) && file.gcs_processed_path != null;
            },
            removeFile(file) {
                var self  = this;
                Swal.fire({
                  title: "Are you sure you want to delete <em>" + file.name + "</em>?",
                  text: "This will remove the file from the system and prevent it from being used with future data processes.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.axios.delete('/api/files/' + self.client.url + '/destroy/' + file.id)
                            .then(response => {
                                var index = self.filtered_file_list.findIndex(f => f.id == file.id);
                                self.filtered_file_list.splice(index, 1);
                                this.local_num_files--;
                                this.reloadPagination();
                            });
                    }
                });
            },
            reloadPagination(){
                this.file_list_shown = this.filtered_file_list.slice(0, Math.min(25, this.files.length));
                this.page_count = Math.ceil(this.filtered_file_list.length / this.num_per_page);
                // this.page_index = 0;
                this.page_index_array = Array.from({length: this.page_count}, (_, i) => i + 1);
                this.getItemInterval();
            }, 
            getItemInterval(){
                if (this.local_num_files > 0){
                    var from = this.page_index * this.num_per_page + 1;
                    var to = (this.page_index +1) * this.num_per_page;
                    if(this.local_num_files && to>this.local_num_files)
                        to = this.local_num_files;
                    if (from >= to) 
                        this.item_interval = to
                    else 
                        this.item_interval = from +' - ' +to;
                } else {
                    this.item_interval = '0'
                }
            },
            getFromDB() {
                window.axios.post('/api/files/' + this.client.url + '/list_by_pattern',
                    {'pattern': '%'+this.search_key+'%', 'page_size':this.num_per_page, 'offset' : this.num_per_page* this.page_index }).then(response => {
                    this.file_list_shown = response.data.files;
                    this.local_num_files = response.data.num_files;
                    this.getItemInterval();
                    this.$forceUpdate();
                });
            },
            moveToPrevPage(){
                this.page_index--;
                this.getFromDB();
            },
            moveToPage(page){
                this.page_index = page-1;
                this.getFromDB();
            },
            moveToNextPage(){
                this.page_index++;
                this.getFromDB();
            },
            searchFiles(){
                this.page_index = 0;

                if(this.search_key == "") {
                    this.filtered_file_list = [...this.files];
                    this.local_num_files = this.num_files;

                    this.reloadPagination();
                } else if (this.num_files < 100) { // for orgs with small file list
                    this.filtered_file_list = this.files.filter(option => option.name.toLowerCase().includes(this.search_key.toLowerCase()));
                    this.local_num_files = this.filtered_file_list.length;
                    this.reloadPagination();
                } else { // for orgs with big file list
                    window.axios.post('/api/files/' + this.client.url + '/list_by_pattern',
                        {'pattern': '%'+this.search_key+'%','page_size':this.num_per_page, 'offset' : this.num_per_page* this.page_index  }).then(response => {
                        this.filtered_file_list = response.data.files;
                        this.local_num_files = response.data.num_files;
                        this.reloadPagination();
                    });
                } 
            },
            sortByKey(key){
                this.sort_key = key;
                if (this.sort_key != key){
                    this.sort_order = 'asc'; // reset to ascending sorting order
                } else{
                    this.sort_order = (this.sort_order == 'asc'? 'des':'asc');
                }
                this.sortDataWithLabel();
            },
            sortDataWithLabel(){
                if (this.sort_key == 'dataset'){
                    if(this.sort_order == 'asc')
                        this.filtered_file_list.sort((a, b) => {
                            if (!a.dataset)
                                return -1;
                            if (!b.dataset)
                                return 1;
                            return (a.dataset.name > b.dataset.name) ? 1 : -1;
                        });
                    else
                        this.filtered_file_list.sort((a, b) => {
                            if (!a.dataset)
                                return 1;
                            if (!b.dataset)
                                return -1;
                            return (a.dataset.name < b.dataset.name) ? 1 : -1;
                        });   
                }else{
                    if(this.sort_order == 'asc')
                        this.filtered_file_list.sort((a, b) => (a[this.sort_key ] > b[this.sort_key ]) ? 1 : -1);
                    else
                        this.filtered_file_list.sort((a, b) => (a[this.sort_key ] < b[this.sort_key ]) ? 1 : -1);                    
                }
                this.reloadPagination();
            },
            viewFile(file){
                this.$emit('viewFile', file)
            },
            openAddFileModal(){
                this.$emit('openAddFileModal', null)
            }


        }
    }
</script>
