<template>
<div class="query-inclusion-builder">
    <div class="row pb-3">
        <div class="col-sm-9 mb-2">
            <div class="filter-type btn-group toggle-btn-group btn-group-sm d-flex d-sm-inline-flex" role="group">
                <button class="btn text-nowrap reg-btn" :class="{
                    'btn-primary' : data_filter.filter_option == 'all',
                    'btn-white' : data_filter.filter_option != 'all',
                    'is-invalid' : errors.logic_statement }"
                    v-on:click="updateFilterLogic('all');" >All Filters
                </button>
                <button class="btn text-nowrap reg-btn" :class="{
                    'btn-primary' : data_filter.filter_option == 'any',
                    'btn-white' : data_filter.filter_option != 'any',
                    'is-invalid' : errors.logic_statement }"
                    v-on:click="updateFilterLogic('any');" >Any Filters
                </button>
                <button class="btn text-nowrap reg-btn" :class="{
                    'btn-primary no-right-rounded-corner' : data_filter.filter_option == 'custom',
                    'btn-white' : data_filter.filter_option != 'custom',
                    'is-invalid' : errors.logic_statement }"
                    v-on:click="updateFilterLogic('custom');" >Custom Logic
                </button>
                <input type="text" class="form-control form-control-sm gx-0 border-start-0"
                    style="border-top-left-radius: 0; border-bottom-left-radius: 0;"
                    v-model="data_filter.filter_logic"
                    v-if="data_filter.filter_option == 'custom'"
                    v-bind:class="{ 'is-invalid' : errors.logic_statement }">
                <div class="invalid-feedback ms-2 text-danger" v-if="errors.logic_statement">
                    {{data_filter.logic_error_msg}}
                </div>
            </div>
        </div>
        <div class="col text-sm-end text-center mb-2">
            <button class="btn btn-secondary" type="button"
                v-on:click="addLogicUnit(); return false;">
                <i class="fas fa-plus"></i> Add clause
            </button>
        </div>
    </div>

    <div class="row logic-unit" v-for="(unit, index) in data_filter.logic_units" v-bind:class="{ 'is-invalid' : hasLogicUnitError(unit) && errors.logic_units[index]}">
        <div class="col-lg-1 py-1 text-center">
            <label class="form-label fs-7 m-0" >Unit #</label>
            <h4 class="m-0 mt-1">{{index+1}}</h4>
        </div>
        <div class="col-lg-3 py-1 text-center">
            <label class="form-label fs-7 m-0">Column Name</label>
            <v-select :options="db_columns" v-model="unit.db_column"
                :searchable="true" class="searchable-select"
                :selectable="(option) => option.text != ''"
                label="text" @search="fetchOptions"
                @input="changeDBColumn(unit)"
                :filterable="false">
                <template #selected-option="{ text, category }">
                    {{(category != null && category != "" && text!=null && text!="") ? category + ": " : ""}}{{text}}
                </template>

                <template #open-indicator="{ attributes }">
                    <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
                </template>

                <template #option="{ text, category }">
                    <div v-if="text == ''" :data-category="category" class="category-header"
                    v-on:click="return expandCategory(category, null)"
                    data-isexpanded="false">
                    {{ category }} <i class="fa-solid fa-caret-right"></i>
                    </div>
                    <div v-else class="suboption" :data-subcategory="category"
                    :class="category==null || category==''? 'show' : ''">
                    {{ text }}
                    </div>
                </template>
                <template #no-options="{ search, searching, loading }">
                    <div class="suboption show" v-if="is_loading">
                        <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading columns
                    </div>
                    <div class="suboption show" v-else>
                        <em>No results found</em>
                    </div>
                </template>

            </v-select>
        </div>
        <div class=" py-1 text-center" :class="{ 'col-lg-3' : unit.operator.value != 'IS NULL' && unit.operator.value != 'IS NOT NULL', 'col-lg-8' : data_filter.logic_units.length <= 1 && (unit.operator.value == 'IS NULL' || unit.operator.value == 'IS NOT NULL'), 'col-lg-7 col-9' : data_filter.logic_units.length > 1 && (unit.operator.value == 'IS NULL' || unit.operator.value == 'IS NOT NULL') }" >
            <label class="form-label fs-7 m-0">Operator</label>
            <v-select :options="operator_options"
                v-model="unit.operator"
                :selectable="(option) => option.text != ''" label="text"
                @input="updateOperator(unit);">

                <template #open-indicator="{ attributes }">
                    <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
                </template>

                <template #option="{ text, category }">
                    <div v-if="text == ''" :data-category="category" class="category-header"
                    v-on:click="return expandCategory(category, null)"
                    :data-isexpanded="true">
                    {{ category }} <i class="fa-solid fa-caret-down"></i>
                    </div>
                    <div v-else class="suboption expanded" :data-subcategory="category">
                    {{ text }}
                    </div>
                </template>
            </v-select>
        </div>
        <div  class=" py-1 text-center" :class="{ 'col-lg-4 col-9' : data_filter.logic_units.length > 1, 'col-lg-5' : data_filter.logic_units.length <= 1}"
                v-if="shouldShowValues(unit.operator)">
            <label class="form-label fs-7 m-0">Value</label>
            <div class="input-group">
                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" v-bind:disabled="unit.db_column==''">
                    {{ typeOfSelection(unit) }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="#"
                        v-on:click.prevent="unit.is_static = false; unit.show_options = false; unit.value = '';">Compare to Column</a></li>
                    <li>
                        <a class="dropdown-item" href="#"
                            v-if="unit.data_type!='date'"
                            v-on:click.prevent="
                                unit.is_static = true;
                                unit.show_options = true;
                                unit.value = '';"
                            >List of Options</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="#"
                            v-if="unit.data_type=='date'"
                            v-on:click.prevent="unit.is_static = false; unit.show_options = true; unit.value = '';"
                            >Date Comparisons</a>
                    </li>
                    <li><a class="dropdown-item" href="#"
                    v-on:click.prevent="unit.is_static = true; unit.show_options = false; unit.value = '';"
                    >Static Value</a></li>
                </ul>
                <!-- STATIC TEXT BOX -->
                <input type="text" class="form-control ps-2"  v-model="unit.value"
                    v-if="unit.is_static && unit.show_options == false && !isDate(unit.data_type)"
                    @change="updateStaticValue(data_filter.logic_units[el])">

                <!-- STATIC WITH DATE FORMATTING -->
                <datepicker v-model="unit.value" input-class="form-control date" placeholder='MM/DD/YYYY'
                    :bootstrap-styling="true" :use-utc="true" format="M/d/yyyy"
                    v-if="unit.is_static  && unit.show_options == false && isDate(unit.data_type)" />

                <!-- DATABASE COLUMN SELECTION -->
                <v-select :options="db_columns" v-model="unit.value"
                    :searchable="true" class="searchable-select  value_db_select vue_select"
                    :selectable="(option) => option.text != ''"
                    label="text" @search="fetchOptions"
                    v-on:input="changeDBColumn(unit)"
                    v-if="unit.is_static == false && !unit.show_options"
                    :filterable="false">

                    <template #open-indicator="{ attributes }">
                        <span v-bind="attributes" style="width: 12px; line-height: 8px;"><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg></span>
                    </template>

                    <template #option="{ text, category }">
                        <div v-if="text == ''" :data-category="category" class="category-header"
                        v-on:click="return expandCategory(category, null)"
                        data-isexpanded="false">
                        {{ category }} <i class="fa-solid fa-caret-right"></i>
                        </div>
                        <div v-else class="suboption" :data-subcategory="category"
                        :class="category==null || category==''? 'show' : ''">
                        {{ text }}
                        </div>
                    </template>
                    <template #no-options="{ search, searching, loading }">
                        <div class="suboption show" v-if="is_loading">
                            <div class="spinner-border  spinner-border-sm text-warning float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading columns
                        </div>
                        <div class="suboption show" v-else>
                            <em>No results found</em>
                        </div>
                    </template>
                </v-select>

                <!-- SELECT FROM AVAILABLE DB VALUES -->
                <select class="form-select" v-model="unit.value"
                    v-if="unit.is_static && unit.show_options">
                    <option v-for="col in db_column_values[unit.db_column.value]" v-bind:value="col.name"
                    >{{ col.name }}</option>
                </select>

                <!-- SELECT FROM DATE COMPARISONS -->
                <input type="number" class="form-control date_num ps-2"
                    v-bind:value="dateNumCalc(unit.value)"
                    v-bind:data-index="index" placeholder="###"
                    v-on:change="updateDateValue(index, unit);"
                    v-if="!unit.is_static && unit.show_options">

                <select class="form-select input-group-prepend date_comparison"
                    v-bind:value="dateComparisonCalc(unit.value)"
                    v-bind:data-index="index"
                    v-on:change="updateDateValue(index, unit);"
                    v-if="!unit.is_static && unit.show_options">
                    <option value="day">Days Ago</option>
                    <option value="week">Weeks Ago</option>
                    <option value="month">Months Ago</option>
                    <option value="year">Years Ago</option>
                </select>

            </div>
            <div class="form-check" v-if="unit.operator != null && (unit.operator.value == 'LIKE' || unit.operator.value == 'NOT LIKE' || unit.operator.value == 'IN' || unit.operator.value == 'NOT IN' || ((unit.operator == '=' || unit.operator.value == '=' || unit.operator.value == '<>' || unit.operator == '<>') && (unit.data_type=='string')))">
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" value="LOWER" v-model="unit.modification">
                    <small style="margin-top: 2px; display: inline-block;">Case-insensitive comparison</small>
                </label>
            </div>
            <small v-if="unit.operator != null && (unit.operator.value=='IN' || unit.operator.value=='NOT IN')">Comma separate the unique values. Quotes around strings are <em>not</em> needed.</small>
        </div>
        <div class="col-lg-1 col-3 py-1" v-if="data_filter.logic_units.length > 1" :class="{ 'mt-3' : data_filter.logic_units.length > 1 && index == 0 }">
            <button class="btn btn-secondary"
                v-on:click="removeLogicUnit(index);"><i class="fas fa-trash me-0"></i></button>
        </div>
        <div class="pb-3 m-0 col-12 invalid-feedback text-center" v-if="hasLogicUnitError(unit) && errors.logic_units[index]">
            All of the fields above must be completed.
        </div>
    </div>
</div>

</template>

<script>
    import moment from 'moment';
    import vSelect from "vue-select";
    import Datepicker from 'vuejs-datepicker';

    export default {
        props: {
            value: {
                type: Object,
            },
            client: {
                type: Object,
                required: true
            },
            source: {default: 'client_db' },
            data_source_id: {default: null },
            static_db_columns: {default: null },
            include_trans: {default: false }
            },
        components: {
            vSelect,
            Datepicker
        },
        data() {
            return {
                db_columns: [],
                unfiltered_db_columns: [],
                db_column_values: [],
                errors: {
                    logic_units: [ false ],
                    logic_statement: false
                },
                data_filter: null,
                is_loading: false,
                operator_options: [
                    { category: "Common Operators", value: '', text: '' },
                    { category: "Common Operators", value: '=', text: 'Equals' },
                    { category: "Common Operators", value: '<>', text: 'Does Not Equal' },
                    { category: "Common Operators", value: 'IS NULL', text: 'Is Blank' },
                    { category: "Common Operators", value: 'IS NOT NULL', text: 'Is Not Blank' },
                    { category: "Common Operators", value: 'IN', text: 'Is One Of' },
                    { category: "Common Operators", value: 'NOT IN', text: 'Is Not One Of' },

                    { category: "Text Operators", value: '', text: '' },
                    { category: "Text Operators", value: 'LIKE', text: 'Contains' },
                    { category: "Text Operators", value: 'NOT LIKE', text: 'Does Not Contain' },
                    { category: 'Text Operators', value:"REGEXP_CONTAINS", text: 'Contains (RegEx)' },
                    { category: 'Text Operators', value:"NOT REGEXP_CONTAINS", text: 'Does Not Contain (RegEx)' },

                    { category: "Number Operators", value: '', text: '' },
                    { category: "Number Operators", value: '>', text: 'Greater Than' },
                    { category: "Number Operators", value: '>=', text: 'Greater Than or Equal To' },
                    { category: "Number Operators", value: '<', text: 'Less Than' },
                    { category: "Number Operators", value: '<=', text: 'Less Than or Equal To' },

                    { category: "Date Operators", value: '', text: '' },
                    { category: "Date Operators", value: '>', text: 'Is After' },
                    { category: "Date Operators", value: '>=', text: 'Is On or After' },
                    { category: "Date Operators", value: '<', text: 'Is Before' },
                    { category: "Date Operators", value: '<=', text: 'Is On or Before' },
                ]
            };
        },
        beforeMount() {
            if(this.value != null) {

                this.data_filter = this.value;
                for(var i = 0; i < this.data_filter.logic_units.length; i++){
                    if(this.data_filter.logic_units[i].operator === "string")
                        this.data_filter.logic_units[i].operator = { value: this.data_filter.logic_units[i].operator , text: this.data_filter.logic_units[i].operator, category: ""}
                    else if(this.data_filter.logic_units[i].operator === null)
                        this.data_filter.logic_units[i].operator = { value: "" , text: "", category: ""}
                }
            }
            else
                this.data_filter = this.getBlankDataFilter();

            this.data_filter.filter_ui = "advanced";

        },
        mounted() {
            this.loadDropdownColumns();
        },
        watch: {
            value(old_pv, new_pv) {
                if(old_pv != null)
                    this.data_filter = old_pv;
                else
                    this.data_filter = this.getBlankDataFilter();
            },
            data_filter: {
                handler(n, o) {
                    this.$emit('input', this.data_filter);
                    for(var i = 0; i < this.data_filter.logic_units.length; i++)
                        if(this.data_filter.logic_units[i].db_column != null && this.db_column_values[this.data_filter.logic_units[i].db_column.value] == null)
                            this.loadDatabaseValues(this.data_filter.logic_units[i].db_column.value);
                },
                deep: true
            },
            source(old_pv, new_pv) {
                this.loadDropdownColumns();
            },
            data_source_id(old_pv, new_pv) {
                this.loadDropdownColumns();
            }
        },
        methods: {
            isFormValid() {
                //Reset the error messages
                for(var i = 0; i < this.data_filter.logic_units.length; i++)
                    this.errors.logic_units[i] = false;

                var form_is_valid = true;
                for(var i = 0; i < this.data_filter.logic_units.length; i++) {
                    this.errors.logic_units[i] = this.hasLogicUnitError(this.data_filter.logic_units[i]);
                    if(this.errors.logic_units[i])
                        form_is_valid = false;
                }
                this.errors.logic_statement = this.showCustomError();

                if(!form_is_valid || this.errors.logic_statement)
                    this.$forceUpdate();

                return form_is_valid && !this.errors.logic_statement;
            },
            getBlankDataFilter() {
                return {
                    filter_option: "all",
                    filter_logic: '1',
                    logic_error_msg: "",
                    filter_ui: 'advanced',
                    logic_units: [
                        {
                            db_column:{ value: "", text: "", category: ""},
                            data_type:'string',
                            operator: { value: "" , text: "", category: ""},
                            is_static: true,
                            show_options: false,
                            value:'',
                            modification:''
                        }
                    ]
                }
            },
            dateNumCalc(value) {
                if(value == undefined || moment.isDate(value) || value.indexOf("DATE_SUB") == -1)
                    return value;
                value = value.replace("DATE_SUB(CURRENT_DATE(), INTERVAL ", "");

                var parts = value.split(" ");
                return parts[0];

                //num + " " + comparison + ")
            },
            dateComparisonCalc(value) {
                if(value == undefined || moment.isDate(value) || value.indexOf("DATE_SUB") == -1)
                    return value;
                value = value.replace("DATE_SUB(CURRENT_DATE(), INTERVAL ", "");

                var parts = value.split(" ");
                parts[1] = parts[1].replace(")", "");
                return parts[1];

                //num + " " + comparison + ")
            },
            isComparison(data_type) {
                if(data_type == undefined)
                    return true;

                return data_type.indexOf('float') > -1 ||
                    data_type.indexOf('int') > -1 || data_type.indexOf('date') > -1 || data_type.indexOf('time') > -1;

            },
            isDate(data_type) {
                if(data_type == undefined)
                    return false;

                return data_type.indexOf('date') > -1 || data_type.indexOf('time') > -1;

            },
            typeOfSelection(unit) {
                if(unit.is_static && unit.show_options )
                    return 'Options';
                else if(unit.is_static)
                    return "Static";
                else if(unit.show_options)
                    return 'Date';
                else return "Column";
            },

            loadDropdownColumns() {
                if(this.static_db_columns == null)
                    this.loadDatabaseColumns();
                else {
                    //loop through the static_db_columns object
                    for(var key in this.static_db_columns) {
                        this.db_columns.push({
                            value: key,
                            text: this.static_db_columns[key],
                            category: ""
                        });
                    }
                    this.unfiltered_db_columns = JSON.parse(JSON.stringify(this.db_columns));
                }
            },
            loadDatabaseColumns() {
                this.is_loading = true;
                var _self = this;
                var data = {
                    client: this.client,
                    include_trans: this.include_trans,
                    source: this.source,
                    data_source_id: this.data_source_id
                };

                window.axios.post('/api/bigquery/get_db_columns', data)
                    .then(response => {
                    if(response.status != 200){
                        _self.db_columns = undefined;
                        _self.unfiltered_db_columns = undefined;
                    }
                    else{
                        var cols = response.data.columns;
                        var headers = "";
                        for(var i = 0; i < cols.length; i++)
                            if(headers != cols[i].category && cols[i].category != null) {
                                headers = cols[i].category;
                                cols.splice(i, 0, {
                                    value: "DIVIDER-"+ headers,
                                    category: headers,
                                    text: ""
                                });
                            }
                        _self.db_columns = cols;
                        _self.unfiltered_db_columns = JSON.parse(JSON.stringify(cols));
                    }
                    _self.is_loading = false;

                    });
            },

            shouldShowValues(operator) {
                //If the logic operator is an object
                if(operator != null && typeof operator == "object")
                    operator = operator.value;

                return operator != 'IS NULL' && operator != 'IS NOT NULL';

            },

            hasLogicUnitError(logic) {
                //If the logic operator is an object
                var operator = logic.operator;
                //If the operator hasn't been set
                if(logic.operator == null || logic.db_column == null)
                    return false;

                if(typeof logic.operator == "object")
                    operator = logic.operator.value;

                if(logic.db_column.value == "" || operator == "" ||
                    ( logic.value == "" && operator.indexOf("NULL") == -1))
                    return true;

                //If the database column is no longer available in the source table
                if(this.unfiltered_db_columns != null && this.unfiltered_db_columns.length > 0
                    && !this.unfiltered_db_columns.find(obj => obj.value === logic.db_column.value) ) {
                    logic.db_column.value = "";
                    logic.db_column.text = "";
                    return true;
                }

                //If it is a static value and it hasn't been selected
                if(logic.is_static && logic.value == null)
                    return true;

                //If the column it is comparing against has been remove...
                if(!logic.is_static && !logic.show_options && this.unfiltered_db_columns != null &&
                    this.unfiltered_db_columns.length > 0 && !this.unfiltered_db_columns.find(obj => obj.value === logic.value.value) ) {
                    logic.value.text = "";
                    logic.value.value = "";
                    return true;
                }
                return false;
            },
            changeDBColumn(unit) {
                unit.data_type = unit.db_column.type;

                if(this.db_column_values[unit.db_column.value] == undefined)
                    this.loadDatabaseValues(unit.db_column.value);

            },

            loadDatabaseValues(db_column) {

                if(db_column == undefined || db_column == null || db_column == "")
                    return;

                var data = {
                    client: this.client,
                    source: this.source,
                    column: db_column,
                    limit: 1000,
                    data_source_id: this.data_source_id
                };

                var _self = this;

                this.db_column_values[db_column] = [
                    {name: 'Loading Options...'}
                ];
                window.axios.post('/api/bigquery/get_samples', data)
                    .then(response => {
                    //Remove the null value
                    for(var i = response.data.samples.length - 1; i >= 0; i--)
                        if(response.data.samples[i].name == null)
                            response.data.samples.splice(i, 1);

                    response.data.samples.sort((a, b) => (a.name > b.name ) ? 1 : -1);

                    if(response.data.samples.length > 0)
                        _self.db_column_values[db_column] = response.data.samples;
                    else
                        _self.db_column_values[db_column] = [
                            {name: '** All values are null **'}
                        ];
                    _self.$forceUpdate();
                });
            },
            showCustomError() {
                var filter = this.data_filter;
                if(filter.filter_option != 'custom')
                    return false;
                var logic = filter.filter_logic.replace(/[^0-9\s]/g, '');
                var units = logic.split(" ");
                units = units.filter(function(entry) { return /\S/.test(entry); });

                //Convert the array of strings into numbers like they should be
                var max_unit = 0;
                for(var i = 0; i < units.length; i++) {
                    units[i] = parseInt(units[i]);
                    if(units[i] > max_unit)
                        max_unit = units[i];
                }

                //If I'm using a unit that doesn't exist
                if(max_unit > filter.logic_units.length) {
                    filter.logic_error_msg = "You are using a logical unit (#"+max_unit+") that does not exist below."
                    return true;
                }
                //If I'm not using a unit defined below
                for(var i = 0; i < filter.logic_units.length; i++) {
                    //If the logical statement doesn't include one of the logic units
                    if(!units.includes(i+1)) {
                        filter.logic_error_msg = "A logical unit (#"+(i+1)+") is defined below but not used in the logical statement."
                        return true;
                    }
                }

                if(!this.validAdvancedLogic(filter.filter_logic)){
                    filter.logic_error_msg = "The logic statement is not valid. Check the syntax and make any necessary corrections.";
                    return true;
                }

                return false;

            },
            updateFilterLogic( type) {
                //Only update the logic if it isn't a custom filter
                if(type != 'custom'){
                    this.errors.logic_statement = false;

                    var logic = "1";
                    for(var i = 1; i < this.data_filter.logic_units.length; i++){
                        logic += ((type == 'all')? " AND " : " OR ") + (i+1);
                    }

                    this.data_filter.filter_logic = logic;
                }

                this.data_filter.filter_option = type;
            },
            addLogicUnit() {
                this.data_filter.logic_units.push(
                    {
                        db_column: { value: "" , text: "", category: ""},
                        data_type:'string',
                        operator: { value: "" , text: "", category: ""},
                        value:'',
                        is_static: true,
                        show_options: false,
                        modification:'',
                    });

                this.errors.logic_units.push(false);

                //Now update the logic statement
                if(this.data_filter.filter_option == 'any')
                    this.data_filter.filter_logic += " OR ";
                else
                    this.data_filter.filter_logic += " AND ";
                    this.data_filter.filter_logic += (this.data_filter.logic_units.length);
            },
            removeLogicUnit(index) {
                this.data_filter.logic_units.splice(index,1);
                this.errors.logic_units.splice(index,1);
                //If it is simple, just remake the logic
                if(this.data_filter.filter_option == 'any' ||
                    this.data_filter.filter_option == 'all')
                    this.updateFilterLogic(this.data_filter.filter_option);

                //The showCustomError method will handle the rest

            },
            updateOperator(unit) {
                if(unit.operator!=undefined &&
                    (unit.operator.value == 'REGEXP_CONTAINS' || unit.operator.value.indexOf("IN") >= 0
                        || unit.operator.value == "LIKE"  || unit.operator.value.indexOf('NULL') >= 0)){
                    unit.is_static = true;
                    unit.show_options = false;
                }
            },

            updateStaticValue(unit) {
                var val = unit.value.toLowerCase().trim();

                if(val == 'true' || val == 'false')
                    unit.data_type = 'bool';
                else if (!isNaN(val))
                    unit.data_type = 'float';
                else
                    unit.data_type = 'string';
            },

            updateDateValue(index, unit) {
                var num = $(".date_num[data-index='" + index + "']").val();
                var comparison = $(".date_comparison[data-index='" + index + "']").val();

                unit.value = "DATE_SUB(CURRENT_DATE(), INTERVAL " + num + " " + comparison + ")" ;
            },
            expandCategory(cat, expand) {
                var headline = document.querySelector("div[data-category='"+cat+"']");
                var lines = document.querySelectorAll("div[data-subcategory='"+cat+"']");

                if(headline == undefined || lines == undefined)
                    return false;

                if((headline.dataset.isexpanded == "false" || !headline.dataset.isexpanded ) || expand === true) {
                    for(var i = 0; i < lines.length; i++)
                        lines[i].style.display="block";
                    var divs = headline.getElementsByClassName("fa-caret-right");
                    for(var i = 0; i < divs.length; i++){
                        divs[i].classList.add("fa-caret-down");
                        divs[i].classList.remove("fa-caret-right");
                    }
                    headline.dataset.isexpanded = true;
                }
                else {
                    for(var i = 0; i < lines.length; i++)
                        lines[i].style.display="none";
                    var divs = headline.getElementsByClassName("fa-caret-down");
                    for(var i = 0; i < divs.length; i++){
                        divs[i].classList.add("fa-caret-right");
                        divs[i].classList.remove("fa-caret-down");
                    }
                    headline.dataset.isexpanded = false;
                }

                return false;
            },
            fetchOptions (search, loading) {
                //Reset the array
                this.db_columns = JSON.parse(JSON.stringify(this.unfiltered_db_columns));

                if(search == "") return;

                //Look at each column
                for(var i = this.db_columns.length-1; i >= 0 ; i--) {
                    //If the search string isn't in the text or the category
                    if(this.db_columns[i].text.toLowerCase().indexOf(search.toLowerCase()) == -1
                        && (this.db_columns[i].category == null
                        || this.db_columns[i].category.toLowerCase().indexOf(search.toLowerCase()) == -1)
                        && this.db_columns[i].text != "" ) //And not a category divider

                        this.db_columns.splice(i, 1);
                }

                //Get the remaining categories
                var cats = [];
                for(var i = 0; i < this.db_columns.length; i++)
                    if(this.db_columns[i].category != null && !cats.includes(this.db_columns[i].category) && this.db_columns[i].text != "")
                        cats.push(this.db_columns[i].category);

                //Expand the categories
                for(var i = 0; i < cats.length; i++)
                    this.expandCategory(cats[i], true);

                //Remove a category if it isn't in the array of categories
                for(var i = this.db_columns.length-1; i >= 0 ; i--)
                    if(this.db_columns[i].text == "" && this.db_columns[i].category != null && !cats.includes(this.db_columns[i].category))
                        this.db_columns.splice(i, 1);

                return true;
            },
            validAdvancedLogic(statement) {

                //Get each token which are numbers or parentheses
                statement = statement.replaceAll("(", " ( ").replaceAll(")", " ) ");
                const tokens = statement.match(/[\w()]+|[^\w\s()+-]+/g);
                let expectDigit = true;
                let parensCount = 0;

                //Loop through the parenthesis
                for (let i = 0; i < tokens.length; i++) {
                    const token = tokens[i];
                    //For open parenthesis, make sure this isn't the second one in a row and that we were expected a number
                    if (token === "(") {
                        if (!expectDigit)
                        return false;

                        expectDigit = true;
                        parensCount++;
                        //For closing paretheses, be looking for a number and not have more than two in a row
                    } else if (token === ")") {
                        if (expectDigit || parensCount === 0)
                        return false;

                        expectDigit = false;
                        parensCount--;
                    //Looking for a number...
                    } else if (/^\d+$/.test(token)) {
                        if (!expectDigit)
                        return false;

                        expectDigit = false;
                    } else if (token.toUpperCase() === "AND" || token.toUpperCase() === "OR") {
                        if (expectDigit)
                        return false;

                        expectDigit = true;
                    }
                    else
                        return false;

                }

                return !expectDigit && parensCount === 0;
            },
        }

    }
</script>