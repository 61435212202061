<template>
    <div class="pathways-column-mapping">
        <div class="d-flex flex-column gap-1">
            <h5>Columns to Export</h5>
            <p class="mb-1">Customize the data being exported to your file. This configuration will be saved for this segment moving forward.</p>
        </div>
        <div class="d-flex flex-column gap-3 mb-4">
            <section v-for="(column, i) in mapping.slice(0,2)" :id="i == 0 ? 'primary-key-column-mapping' : 'column-mapping'">
                <div v-if="i>=1" class="w-100 d-flex flex-column gap-2">
                    <div class="d-flex flex-row gap-3">
                        <div class="col-xl-8 col-12 card">
                            <table class="mapping-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="flex-column">
                                                <h5>Source Column</h5>
                                                <p>What column do you want to export?</p>
                                            </div>
                                        </th>
                                        <th>
                                            <!-- <div>
                                                <small>Maps to</small>
                                            </div> -->
                                        </th>
                                        <th>
                                            <div class="flex-column">
                                                <h5>Output Name</h5>
                                                <p>What header do you want to use for the column?</p>
                                            </div>
                                        </th>
                                        <th style="width:80px;"></th>
                                    </tr>
                                </thead>
                                <draggable v-model="mapping" tag="tbody" handle=".handle">
                                    <tr v-for="(column, j) in mapping" v-if="j > 0"  :key="column.id" >
                                        <td class="mapping-grid__source w-100">
                                            <div class="w-100 d-flex">
                                                <div class="input-group" :class="{'is-invalid' : errors[j].source != ''}">
                                                    <input type="text" class="form-control" v-model="column.source_column.value" :class="{'is-invalid' : errors[j].source != ''}" v-if="isConstant(column)" @change="updateConstant(column)"
                                                        :key="column.id">
                                                    <accordion-select v-model="column.source_column.value" :key="column.source_column.id" :class="{'is-invalid' : errors[j].source != ''}" v-else :options="source_columns" @change="updateDestination(column)"></accordion-select>
                                                    <button class="btn btn-secondary p-0" data-bs-toggle="tooltip" title="Use a value from a column" @click="toggleConstant(column)" v-if="isConstant(column)">
                                                        <img class="icon px-1" src="/img/icons/dialexa-icons/map.svg">
                                                    </button>
                                                    <button class="btn btn-secondary p-0" data-bs-toggle="tooltip" title="Use a constant value" @click="toggleConstant(column)" v-else>
                                                        <img class="icon px-1" src="/img/icons/dialexa-icons/edit.svg">
                                                    </button>
                                                </div>
                                                <div class="close-btn d-sm-none" v-if="!column.is_primary_identifier">
                                                    <button class="btn btn-none px-0" v-on:click="removeColumn(j-1);"><i class="fa fa-close"></i></button>
                                                </div>
                                            </div>
                                            <div class="text-danger invalid-feedback" v-if="errors[j].source != ''">
                                                {{ errors[j].source }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="w-100 d-flex align-items-center">
                                                <small>Maps to</small>
                                            </div>
                                        </td>
                                        <td class="mapping-grid__destination w-100">
                                            <div class="w-100 d-flex">
                                                <input type="text" class="form-control" v-model="column.destination_column" :class="{'is-invalid' : errors[j].destination != ''}" :key="'dest'+column.id" @change="errors[j].destination = ''">
                                            </div>
                                            <div class="text-danger invalid-feedback" v-if="errors[j].destination != ''">
                                                {{ errors[j].destination }}
                                            </div>
                                        </td>
                                        <td>
                                            <button class="btn btn-none px-0 ms-2 handle border-0">
                                                <img src="/img/icons/dialexa-icons/vertical-dots.svg" alt="drag" class="icon">
                                            </button>
                                            <button v-if="j > 1" class="mapping-grid__button btn btn-none px-0" v-on:click="removeColumn(j);">
                                                <img class="icon p-1" src="/img/icons/dialexa-icons/trash.svg">
                                            </button>
                                        </td>
                                    </tr>
                                </draggable>
                            </table>
                        </div>
                        <div class="col-xl-4 d-none d-xl-block drill-down pe-3">
                            <preview-table :client="client" :source="source"
                                :source_columns="source_columns"></preview-table>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Buttons -->
            <div class="my-2 d-flex flex-row gap-2">
                <button class="btn btn-secondary" v-on:click="addColumnMapping()" ><i class="fas fa-plus"></i> Add Column</button>
            </div>
        </div>
        <div v-if="Object.keys(questions).length > 0" class="mb-3">
            <div class="d-flex flex-column gap-1">
                <h5>Questions About Your Export</h5>
                <p class="mb-3">Depending upon how the export will be used, you may want more or less information. Answer the questions below to ensure you get the data you're looking for.</p>
            </div>
            <div v-for="key in Object.keys(questions)" :key="key" class="ms-2">
                <div class="d-flex flex-column gap-1 form-check">
                    <label class="form-check-label"><input type="checkbox" v-model="keys[key]" class="form-check-input" @change="setPrioritization()"><span v-html="questions[key]"></span></label>
                </div>
            </div>
        </div>
        <div class="form-check form-switch d-inline-flex align-items-center gap-2 my-0 mb-3">
            <input class="form-check-input mt-0" type="checkbox" role="switch" :checked="advanced_setting" @change="toggleAdvanced()">
            <span>Advanced Settings</span>
        </div>

        <div v-if="advanced_setting" class="w-100 d-flex flex-column gap-2 mb-3 mt-4">
            <h5>Advanced Settings <i class="fas fa-info-circle ms-2" data-bs-toggle="tooltip" title="The primary key is what determines a unique row of data and how that aligns to the destination." ></i></h5>
            <div class="mapping" id="primary-key-column" :class="{ 'is-invalid' : errors[0].source != '' }">
                <div class='w-100'>
                    <label>{{ source.data_source.name }} Source Column(s)</label>
                    <accordion-multi-select v-model="mapping[0].source_column" @input="selectKeyColumn($event, 0)" :class="{'is-invalid' : errors[0].source != ''}"
                        :options="source_columns" :key="mapping[0].id" :field_name="'primary_key_column'"></accordion-multi-select>
                    <div class="text-danger invalid-feedback" v-if="errors[0].source != ''">
                        {{ errors[0].source }}
                    </div>
                </div>
            </div>
            <div class="mapping mb-4" id="prioritization-columns">
                <div class="w-100">
                    <label>Prioritization Column(s)</label><br>
                    <small class="mb-2 d-block text-muted">If there are multiple records with the same primary key, how should Avid AI determine which one to sync?</small>
                    <div class="w-75 mb-2" :class="prioritization_column.length > 1 ? 'd-grid' : 'd-flex'">
                        <template v-for="(priority, i) in prioritization_column">
                            <b v-if="prioritization_column.length > 1" class="fs-5">{{ i+1 }}.</b>
                            <div class="input-group position-relative">
                                <accordion-select v-model="priority.column"
                                    :options="source_columns"></accordion-select>
                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{ priority.sort == 'DESC'? 'Descending' : 'Ascending' }}</button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                    <li><a class="dropdown-item" href="#" @click.prevent="priority.sort = 'ASC'">Ascending</a></li>
                                    <li><a class="dropdown-item" href="#" @click.prevent="priority.sort = 'DESC'">Descending</a></li>
                                </ul>
                            </div>
                            <button v-if="prioritization_column.length > 1" class="mapping-grid__button btn btn-none px-0 " v-on:click="prioritization_column.splice(i, 1);"><img class="icon p-1" src="/img/icons/dialexa-icons/trash.svg"></button>
                        </template>
                    </div>
                    <button class="btn btn-secondary" @click="addPriorityColumn()"><i class="fas fa-plus"></i> Add Prioritization</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import draggable from 'vuedraggable';
    export default {
        components: { draggable },
        props: {
            client: {
              type: Object,
            },
            destination: {
              type: Object,
            },
            value: {
              type: Array,
            },
            source: {type: Object },
            source_columns: {
                default: () => []
            }
        },
        data() {
            return {
                advanced_setting: false,
                questions: [],
                mapping: [],
                errors: [],
                prioritization_column:[{
                    column: '',
                    sort: 'DESC'
                }],
                keys: [],
                formatted_columns: []
            };
        },
        beforeMount() {
            this.setupUI();

            //For the error message, set the default value to false.
            this.errors = [];
            for(var i = 0; i < this.mapping.length; i++) {
                this.errors.push({
                    source: "",
                    destination: ""
                });
            }
            this.formatted_columns = this.getFormattedColumns(this.source_columns);
            if(this.source.prioritization_column != null)
                this.prioritization_column = this.source.prioritization_column;

        },
        updated() {
            //Ensure the tooltips show up
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }

        },
          watch: {
            value(val) {
                if(val != null)
                    this.mapping = val;
                else{
                    this.mapping = this.getDefaultMapping();
                    //Add the questions at the end
                    this.addQuestions(arr[arr.length-1]);
                }

            },
            mapping: {
                handler(n, o) {
                    this.$emit('input', this.mapping);
                    this.$emit('changeMappingDetails', this.mapping)

                    if (this.mapping.length > this.errors.length){
                        for(var i = 0; i < this.mapping.length - this.errors.length; i++) {
                            this.errors.push({
                                source: "",
                                destination: ""
                            });
                        }
                    }
                },
                deep: true
            },
            prioritization_column: {
                handler(n, o) {
                    this.$emit('prioritize', this.prioritization_column);
                },
                deep: true
            },
        },
        methods: {
            getFormattedColumns(db_columns) {
                var columns = [];
                db_columns.forEach(column => {
                    column.main_category = "Columns";
                    columns.push(column);
                });
                return columns;
            },
            toggleAdvanced() {
                this.advanced_setting = !this.advanced_setting;
                this.setPrioritization();
                this.$forceUpdate();
            },
            addPriorityColumn() {
                this.prioritization_column.push({
                    column: '',
                    sort: 'DESC'
                });
            },
            setPrioritization() {
                if(this.source.source == "data_blend" && this.source.data_source.type == 'constituent'){
                    //Update prioritization when this updates
                    //Remove the existing primary keys
                    this.mapping[0].source_column = [this.getGeneratedSourceColumn('id')];
                    this.prioritization_column = [];
                    for(var key in this.keys){
                        if(key == 'contacts' && this.keys[key] == true)
                            this.mapping[0].source_column.push(this.getGeneratedSourceColumn('contacts.contact_id'));
                        else if(key == 'email' && this.keys[key] == true)
                            this.mapping[0].source_column.push(this.getGeneratedSourceColumn('contacts.email.email'));
                        else if(key == 'phone' && this.keys[key] == true)
                            this.mapping[0].source_column.push(this.getGeneratedSourceColumn('contacts.phone.phone'));
                        else if(key == 'address' && this.keys[key] == true)
                            this.mapping[0].source_column.push(this.getGeneratedSourceColumn('contacts.address.city'));
                    }
                    //Loop through the questions and add sorting accordingly
                    for(var key in this.questions){
                        if(key == 'email')
                            this.prioritization_column.push({column: 'contacts.email.email_is_primary', sort: 'DESC'});
                        else if(key == 'phone')
                            this.prioritization_column.push({column: 'contacts.phone.phone_is_primary', sort: 'DESC'});
                        else if(key == 'address')
                            this.prioritization_column.push({column: 'contacts.address.is_primary', sort: 'DESC'});
                    }
                    this.prioritization_column.push({column: 'giving_history.last_gift.date', sort: 'DESC'});

                    //Loop through the rest of the mappings to see if it has transactions
                    for(var i = 1; i < this.mapping.length; i++){
                        if(this.mapping[i].source_column.value == null) continue;

                        var parts = this.mapping[i].source_column.value.split('.');
                        if(parts.includes('transactions')){
                            //This needs to be added to the group by
                            this.mapping[0].source_column.push(this.getGeneratedSourceColumn('transactions.id'));
                            break;
                        }
                    }

                }
                else {
                    //
                }
                this.$emit('prioritize', this.prioritization_column);
            },
            addColumnMapping(){
                this.mapping.push(this.getMapping());
            },
            updateDestination(column) {

                //If column.source_column is an arry, just stop and return
                if(Array.isArray(column.source_column)) return;

                //Split the col by . and then call the filter to transform it
                if(column.source_column.value == null){
                    return;
                }
                var parts = column.source_column.value.split('.');
                //Call the propercase vue filter on the last element of the array
                var text = this.$options.filters.propercase(parts[parts.length - 1]);

                //if(column.destination_column == null || column.destination_column == '')
                column.destination_column = text;

                this.addQuestions(column);
            },
            addQuestions(col) {

                let source = col.source_column;
                //If it isn't an array, make it one
                if(!Array.isArray(source))
                    source = [source];

                for(let j = 0; j < source.length; j++){
                    if(source[j].value == null) continue;

                    var parts = source[j].value.split('.');
                    if(parts.length == 1)
                        return;

                    if(this.source.source == "data_blend" && this.source.data_source.type == 'constituent'){
                        // if the parts contains "contacts"
                        if(parts.includes("contacts"))
                            this.questions['contacts'] = 'Export every individual associated with an account? <em>(If unchecked, it will select only a single individual from each account)</em>';
                        if(parts.includes("email"))
                            this.questions['email'] = 'Export every email address? <em>(This can create multiple rows for each contact.)</em>';
                        if(parts.includes("phone"))
                            this.questions['phone'] = 'Export every phone number? <em>(This can create multiple rows for each contact.)</em>';
                        if(parts.includes("address"))
                            this.questions['address'] = 'Export every address for each person? <em>(This can create multiple rows for each contact.)</em>';
                    }
                    else {

                    }

                    //Loop through the questions as key / value
                    for(var key in this.questions){
                        //See if the key exists in any of the existing mappings
                        var found = false;
                        for(var i = 0; i < this.mapping.length; i++){
                            if(this.mapping[i].source_column.value == null) continue;

                            if(Array.isArray(this.mapping[i].source_column) && this.mapping[i].source_column.some(obj => obj.value === key)){
                                found = true;
                                break;
                            }
                            else{
                                var parts = this.mapping[i].source_column.value.split('.');
                                if(parts.includes(key)){
                                    found = true;
                                    break;
                                }
                            }
                        }
                        //If it wasn't found, remove the question
                        if(!found)
                            delete this.questions[key];
                    }
                }
                this.$forceUpdate();

            },
            removeQuestions(col) {
                var col_parts = col.source_column.value.split('.');
                if(col_parts.length == 1)
                    return;

                let arr = [];
                //Look through the mappings and identify what parts they have
                for(let i = 0; i < this.mapping.length; i++){
                    if(this.mapping[i].source_column.value == null) continue;
                    let tmp = this.mapping[i].source_column;

                    if(!Array.isArray(tmp)) tmp = [tmp];

                    for(let j = 0; j < tmp.length; j++){
                        var parts = tmp[j].value.split('.');
                        if(parts.includes("contacts"))
                            arr.push("contacts");
                        if(parts.includes("email"))
                            arr.push("email");
                        if(parts.includes("phone"))
                            arr.push("phone");
                        if(parts.includes("address"))
                            arr.push("address");
                    }
                }

                if(!arr.includes("contacts") && col_parts.includes("contacts"))
                    delete this.questions['contacts'];
                if(!arr.includes("email") && col_parts.includes("email"))
                    delete this.questions['email'];
                if(!arr.includes("phone") && col_parts.includes("phone"))
                    delete this.questions['phone'];
                if(!arr.includes("address") && col_parts.includes("address"))
                    delete this.questions['address'];

            },
            resetMapping() {
                this.$emit('input', null);
            },
            setupUI() {

                //Set the default columns or whatever was sent to me
                if(this.value != null && this.value.length > 0) {
                    this.mapping = this.value;

                    //Remove any columns that are no longer available
                    for(var i = 0; i < this.mapping.length; i++){
                        //If it is an array...
                        if(Array.isArray(this.mapping[i].source_column)) {
                            for(var j = 0; j < this.mapping[i].source_column.length; j++){
                                //If the value is in the list of source_columns
                                if(this.mapping[i].source_column[j].main_category=="Columns" && !this.source_columns.some(obj => obj.value === this.mapping[i].source_column[j].value)){
                                    this.mapping[i].source_column[j] = this.getBlankSourceColumn();
                                    break;
                                }
                                else{
                                    this.addQuestions(this.mapping[i]);
                                }
                            }
                        }
                        //Just look at the object
                        else if(this.mapping[i].source_column.main_category=="Columns" && !this.source_columns.some(obj => obj.value === this.mapping[i].source_column.value)){

                            //If it is the primary column, it should be an array
                            if(this.mapping[i].is_primary_identifier == 1)
                                this.mapping[i].source_column = [this.getBlankSourceColumn()];
                            else
                                this.mapping[i].source_column = this.getBlankSourceColumn();
                        }
                        else {
                            this.addQuestions(this.mapping[i]);
                        }


                    }

                    //Look through the primary key and set the checkboxes
                    for(var j = 0; j < this.mapping[0].source_column.length; j++){
                        for(var key in this.questions){
                            if(this.mapping[0].source_column[j].value == 'contacts.contact_id')
                                this.keys['contacts'] = true;
                            else if(this.mapping[0].source_column[j].value == 'contacts.email.email')
                                this.keys['email'] = true;
                            else if(this.mapping[0].source_column[j].value == 'contacts.phone.phone')
                                this.keys['phone'] = true;
                            else if(this.mapping[0].source_column[j].value == 'contacts.address.city')
                                this.keys['address'] = true;

                        }
                    }

                    this.setPrioritization();

                }
                else{

                    this.mapping = this.getDefaultMapping();
                    //Add the questions at the end
                    if(this.mapping && this.mapping.length > 0){
                        for(let i = 0; i < this.mapping.length; i++)
                            this.addQuestions(this.mapping[i]);
                    }
                }

            },
            selectKeyColumn(event, index){
                if(event.value != null)
                    this.mapping[index].source_column = event.value;
                else
                    this.mapping[index].source_column = event;
            },
            getColumnText(column) {
                const foundInstance = this.flat_dest_columns.find(item => item.value === column);

                if(foundInstance == null)
                    return column;

                return foundInstance.text;
            },
            getDefaultMapping() {

                let arr = [];
                //If it is a constituent blend, it will be the account ID
                if(this.source.source == "data_blend" && this.source.data_source.type == 'constituent'){
                    arr.push(this.getMapping('id', true));
                    arr.push(this.getMapping('id'));
                    arr.push(this.getMapping('contacts.contact_id'));
                    arr.push(this.getMapping('contacts.first_name'));
                    arr.push(this.getMapping('contacts.last_name'));
                    arr.push(this.getMapping('contacts.address.address_1'));
                    arr[arr.length-1].destination_column = 'Address Line 1';
                    arr.push(this.getMapping('contacts.address.city'));
                    arr.push(this.getMapping('contacts.address.region'));
                    arr.push(this.getMapping('contacts.address.postal_code'));
                    arr.push(this.getMapping('contacts.email.email'));
                    arr.push(this.getMapping('contacts.phone.phone'));
                    arr.push(this.getMapping('giving_summary.giving_level'));
                    arr.push(this.getMapping('giving_history.first_gift.amount'));
                    arr[arr.length-1].destination_column = 'First Gift Amount';
                    arr.push(this.getMapping('giving_history.first_gift.date'));
                    arr[arr.length-1].destination_column = 'First Gift Date';
                    arr.push(this.getMapping('giving_history.last_gift.amount'));
                    arr[arr.length-1].destination_column = 'Last Gift Amount';
                    arr.push(this.getMapping('giving_history.last_gift.date'));
                    arr[arr.length-1].destination_column = 'Last Gift Date';
                }
                else if(this.source.source == "data_blend" && this.source.data_source.type == 'subscriber'){
                    arr.push(this.getMapping('email', true));
                    arr.push(this.getMapping('contact.first_name'));
                    arr.push(this.getMapping('contact.last_name'));
                    arr.push(this.getMapping('email'));
                    arr.push(this.getMapping('source.email_source'));
                    arr.push(this.getMapping('source.email_source_type'));
                    arr.push(this.getMapping('status.name'));
                    arr[arr.length-1].destination_column = 'Email Status';
                    arr.push(this.getMapping('engagement_summary.overall_engagement_recency.label'));
                    arr[arr.length-1].destination_column = 'Time Since Last Engement';
                    arr.push(this.getMapping('date_created'));
                }
                else {
                    arr.push(this.getMapping(null, true));
                    arr.push(this.getMapping(null));
                }
                return arr;
            },
            getMapping(col, is_primary = false) {
                //Split the col by . and then call the filter to transform it
                var text = '';
                if(col != null) {
                    var parts = col.split('.');
                    //Call the propercase vue filter on the last element of the array
                     text = this.$options.filters.propercase(parts[parts.length - 1]);
                }
                return {
                    id: -1 * Math.random(),
                    client_id: this.client.id,
                    census_connection_id: this.source.id,
                    source_column: (col == null) ? this.getBlankSourceColumn() : this.getGeneratedSourceColumn(col),
                    destination_column: text,
                    is_primary_identifier: is_primary,
                }
            },
            getBlankSourceColumn() {
                return {
                    main_category: 'Columns',
                    category: null,
                    type: 'string',
                    value: null,
                    text: ""
                };
            },
            getGeneratedSourceColumn(col) {
                //Split the col by . and then call the filter to transform it
                var parts = col.split('.');
                //Call the propercase vue filter on the last element of the array
                var text = this.$options.filters.propercase(parts[parts.length - 1]);

                //TODO: Fill this out so it popuplates based upon the column
                return {
                    main_category: 'Columns',
                    category: (parts.length == 1 ? null : parts[0]),
                    type: 'string',
                    value: col,
                    text: text
                };
            },
            updateConstant(column) {
                column.source_column.main_category = 'Static';
                column.destination_column = column.source_column.value;
            },
            toggleConstant(column) {

                //If it is a constant, switch back to the column
                if(this.isConstant(column))
                    column.source_column.main_category = 'Columns';
                else
                    column.source_column.main_category = 'Static';

                column.source_column.value = "";

            },
            isConstant(column) {
                if(Array.isArray(column.source_column)) return false;
                return column.source_column != null && column.source_column.main_category == 'Static';
            },
            isValid() {
                //Loop through the mapping and make sure that each column has a source and destination
                var valid = true;

                for(var i = 1; i < this.mapping.length; i++) {
                    //There isn't a column selected
                    if((this.mapping[i].source_column.value == null || this.mapping[i].source_column.value == "" ) && this.mapping[i].source_column.main_category == 'Columns') {
                        this.errors[i].source = "Please select a source column";
                        valid = false;
                    }
                    //There is a blank value
                    else if(!Array.isArray(this.mapping[i].source_column) && (this.mapping[i].source_column.value == null || this.mapping[i].source_column.value == "")) {
                        this.errors[i].source = "Please enter a static value above";
                        valid = false;
                    }
                    else
                        this.errors[i].source = "";

                    if(this.mapping[i].destination_column == null || this.mapping[i].destination_column == "") {
                        this.errors[i].destination = "Please enter the column name for your export";
                        valid = false;
                    }
                    else
                        this.errors[i].destination = "";
                }

                if(!this.advanced_setting)
                    this.setPrioritization();

                this.$forceUpdate();

                return valid;

            },
            removeColumn(i) {
                let col = this.mapping[i];

                //Remove the column
                this.mapping.splice(i, 1);
                this.errors.splice(i, 1);

                this.removeQuestions(col);
                this.$forceUpdate();
            }
        }
    }
</script>
