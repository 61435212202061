<template>
    <div class="modal fade" :id="id" aria-labelledby="system-modal" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-lg">
           <div class="modal-content mx-2">
                <div class="modal-header border-bottom-0">
                    <div class="w-100 d-flex flex-column gap-1">
                        <img class="modal-icon" src="/img/icons/dialexa-icons/plug-connected.svg">
                    </div>
                    <button type="button" class="btn btn-none close-modal"
                        :data-bs-dismiss="'#'+id" @click="closeModal()">
                        <i class="fa fa-close me-0"></i>
                    </button>
                </div>
                <div class="modal-body pt-0" v-if="!selected_integration || !selected_integration.id">
                    <h3>Add a System</h3>
                    <p class="mb-2">Connect a system to unleash more insights!</p>
                    <div class="row">
                        <div class="col-12 position-relative mb-3">
                            <input type="text" class="search-box form-control me-1" placeholder="Search by Name..." aria-label="Search by Name..." v-model="search_key" @input="searchIntegration()">
                            <i class="fa fa-search search-placeholder-icon" v-if="search_key == ''"></i>
                        </div>
                    </div>
                    <div class="system-connections-grid row">

                        <div v-for="(integration, index) in local_integrations" class="col-6 d-flex">
                            <div class="card system-connection-card mb-2 w-100" :class="integration.id===selected_integration.id? 'system-connection-card-selected':''" @click="changeIntegrationSelection(integration)">
                                <div class="card-body system-connection-card__header d-flex">
                                    <div class="system-connection-card__header__icon">
                                        <img :src="integration.logo_url" :alt="integration.display_name" class="avatar rounded-circle avatar-sm me-2 bg-white">
                                    </div>
                                    <h5 class="mt-2">{{ integration.display_name }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-body pt-0" v-else>
                    <div v-if="form.loading">
                        <div class="d-flex flex-column gap-2 align-items-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <h5 class="text-center">Loading System Information</h5>
                        </div>
                    </div>
                    <div v-else>
                        <button v-if="!local_connection_selected" class="btn btn-text back-button mb-2" @click.prevent="backToSelection()">
                            <img class="icon pe-1" src="/img/icons/dialexa-icons/chevrons-left.svg">
                            Back
                        </button>
                        <h3> <img :src="selected_integration.logo_url" :alt="selected_integration.display_name" class="avatar rounded-circle avatar-sm me-2 bg-white">{{ selected_integration.display_name }}</h3>
                        <div v-if="selected_integration_info.type=='credentials'">
                            <div v-html="selected_integration_info.instructions"></div>

                            <div class="system-connection-fields row mt-2">
                                <div v-for="(field, field_index) in selected_integration_info.field" class="mb-1">
                                    <label v-if="field.type != 'checkbox' && field.type != 'hidden'" class="form-label"><span v-html="field.label"></span>
                                        <i v-if="field.tooltip && field.tooltip.length > 0" class="fas fa-info-circle" data-bs-toggle="tooltip" :title="field.tooltip"></i>
                                    </label>

                                    <input v-if="field.type == 'text'" type="text" class="form-control" v-on:change="field.error = false;"
                                        :name="field.id" v-model="field.value"
                                        :class="((field.error)? 'is-invalid':'')">


                                    <input v-if="field.type == 'label'" type="text"
                                        class="form-control" disabled="disabled" :name="field.id" v-model="field.value">

                                    <textarea v-if="field.type == 'textarea'"  :name="field.id" class="form-control full-height" v-model="field.value"  v-on:change="field.error = false;"></textarea>

                                    <div v-if="field.type == 'password' || field.type == 'password-text'" class="input-group" >
                                        <input aria-label="Password" aria-describedby="password-addon" ref="password" autocomplete="off"  :name="field.id" :type="field.type == 'password'? 'password' : 'text'"  :class="((field.error)? 'is-invalid':'')"
                                        class="form-control password-input input_v1" name="password"  v-model="field.value"  v-on:change="field.error = false;">

                                        <span :title="!field.visible ? 'Show password' : 'Hide password'" @click="toggleVisibility(field)"
                                            class="input-group-text" id="password-addon"
                                            style="border-left: none; background-color: white">
                                            <i class="fa" :class="[!field.visible ? 'fa-eye' : 'fa-eye-slash']"></i>
                                        </span>
                                    </div>

                                    <div v-if="field.type == 'select'">
                                        <select class="form-select" :name="field.id" v-model="field.value" :class="((field.error)? 'is-invalid':'')"  v-on:change="field.error = false;">
                                            <option v-for="option in Object.keys(field.options)" :value="option">{{field.options[option]}}</option>
                                        </select>
                                    </div>

                                    <div v-if="field.type == 'warning'">
                                        <p style="margin-top:-8px;"><small class="text-muted">{{ field.text }}</small></p>
                                        <div class="alert alert-warning " role="alert">
                                            <code v-html="field.value"></code>
                                        </div>
                                    </div>

                                    <div v-if="field.type == 'vue-select'">
                                        <accordion-select :options="field.options" :name="field.id" v-model="field.value" :class="((field.error)? 'is-invalid':'')"  v-on:change="field.error = false;"></accordion-select>
                                    </div>

                                    <div v-if="field.type == 'checkbox'" class="form-check"  :class="((field.error)? 'is-invalid':'')">
                                        <input class="form-check-input" type="checkbox" value="true" :id="field.id" :name="field.id" v-model="field.value">
                                        <label class="form-check-label" :for="field.id">
                                            {{ field.label }}
                                            <i v-if="field.tooltip && field.tooltip.length > 0" class="fas fa-info-circle" data-bs-toggle="tooltip" :title="field.tooltip"></i>
                                        </label>
                                    </div>

                                    <div v-if="field.type == 'tables'" :id="field.id">
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-sm-7">
                                                <label class="form-label">Dataset and Table Name
                                                    <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="The value below should be in the format of [DATASET].[TABLE NAME]"></i>
                                                </label></div>
                                            <div class="col">
                                                <label class="form-label">Table Type</label>
                                            </div>
                                            <div class="col-1"></div>
                                        </div>

                                        <div class="row mb-3" v-for="(row, row_index) in field.value" >
                                            <div class="col-1">
                                                <h4 class="text-end mb-0 mt-1 nums">{{ row_index + 1 }}.</h4>
                                            </div>
                                            <div class="col-sm-7">
                                            <input name="table_name[]" type="text" v-model="field.value[row_index]['table_name']" class="form-control"
                                                :class="((field.error && field.value[row_index]['table_name'] == '')? 'is-invalid':'')" />
                                            </div>
                                            <div class="col">
                                                <select name="table_type[]" v-model="field.value[row_index]['table_type']"
                                                    class="form-select" v-html="bigquery_table_type_html" :class="((field.error && field.value[row_index]['table_type'] == '')? 'is-invalid':'')"
                                                    v-on:change="field.error = false;" >

                                                </select>
                                            </div>
                                            <div class="col-1 delete_table" v-if="field.value.length > 1">
                                                <button class="btn btn-none" @click="removeTable(field_index, row_index)"><i class="fa fa-trash"></i></button>
                                            </div>
                                        </div>
                                        <div class="text-danger invalid-feedback" v-if="field.error">
                                            {{field.error_msg}}
                                        </div>

                                        <button class="btn btn-secondary mt-3 mb-4"  @click="addTable(field_index)"><i class="fas fa-fw fa-plus"></i> Add Table</button>
                                    </div>

                                    <div class="text-danger invalid-feedback" v-if="field.error && field.type != 'tables'">
                                         {{field.error_msg}}
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-danger mt-3" v-if="form.error ">
                                <span v-html="form.msg"></span>
                            </div>

                            <div class="w-100 d-flex flex-row justify-content-center gap-3 mt-2">
                                <button class="btn btn-primary w-50" @click="addIntegration()" :disabled="form.saving">
                                    Save
                                </button>

                                <button class="btn btn-secondary w-50" @click="closeModal()" :disabled="form.saving">
                                    Cancel
                                </button>
                            </div>
                        </div>

                        <div v-else-if="selected_integration_info.type=='oauth'" id="oauth-section">
                            <div v-if="selected_integration_info.instructions != null" v-html="selected_integration_info.instructions"></div>
                            <p v-else><strong>Avid AI connects to {{ selected_integration.display_name }} through a one-click authentication.</strong> To connect your instance, click on the button below&mdash;you will be redirecting to {{ selected_integration.display_name }} to complete the process. If additional details are needed after connecting, you'll be prompted when you get back.</p>
                            <div class="w-100 d-flex flex-row justify-content-center gap-3 mt-3">
                                <button class="btn btn-primary w-100" @click="redirectOauth(selected_integration_info.url)">
                                    <img class="icon p-0" src="/img/icons/dialexa-icons/profile-tick.svg"> Authenticate
                                </button>
                            </div>
                        </div>
                        <div v-else-if="form.error">
                            <div class="alert alert-danger mt-3" v-if="form.error ">
                                <span v-html="form.msg"></span>
                            </div>
                            <div class="w-100 d-flex flex-row justify-content-center gap-3 mt-2">
                                <button class="btn btn-secondary w-100" @click="closeModal()" :disabled="form.saving">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
    import Swal from 'sweetalert2';
    export default {
        components: {
            Swal
        },
        props: {
            client: [],
            integrations: [],
            connections: [],
            connection_selected: {
                default: null,
            },
            id: {
                default: 'system-modal',
            },
        },
        data() {
            return {
                integration_tab_list: ['all'],
                local_integrations:[],
                selected_integration: {},
                selected_integration_info: {},
                bigquery_table_type_html: '',
                local_connection_selected: {},
                search_key: '',
                form: {
                    saving: false,
                    error: false,
                    msg: "",
                    loading: false
                },
                errors :{
                    name: "",
                    value: ""
                },

            };
        },
        beforeMount() { 
            // remove file and the two sftps from the integration list
            this.local_integrations = this.integrations.filter(integration => integration.id != '71b0c3b0-8390-49ec-9984-1c04b7384d38' && integration.id != '7dbce608-4906-4606-977c-32ab1259b2e6' && integration.id != '397b5b86-e954-495f-8fdb-513885f53251');
            this.local_connection_selected = this.connection_selected;
        },
        watch:{
            connection_selected(old_value, new_value){
                this.local_connection_selected = this.connection_selected;
                this.selected_integration = {};

                if (this.connection_selected){
                    this.selected_integration = this.connection_selected.integration;
                    this.fetchIntegrationDetailsWithValues(this.connection_selected);
                }
            },
            integrations(old_value, new_value){
                this.local_connection_selected = this.connection_selected;
                this.local_integrations = this.integrations.filter(integration => integration.id != '71b0c3b0-8390-49ec-9984-1c04b7384d38' && integration.id != '7dbce608-4906-4606-977c-32ab1259b2e6' && integration.id != '397b5b86-e954-495f-8fdb-513885f53251');
            },
        },
        updated() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
        },
        methods: {
            redirectOauth(url) {
                //Look at #oauth-section and look for any input checkboxes. If any are selected, append them to the url with the id as the key and true as the value.
                let oauth_url = url;
                //Check if the url has a ? in it
                if(url.includes('?'))
                    oauth_url += '&';
                else
                    oauth_url += '?';

                let checkboxes = document.querySelectorAll('#oauth-section input[type="checkbox"]');
                checkboxes.forEach(checkbox => {
                    if(checkbox.checked)
                        oauth_url += checkbox.id + '=true&';
                });
                //Remove the last & if it exists
                if(oauth_url.charAt(oauth_url.length - 1) == '&')
                    oauth_url = oauth_url.slice(0, -1);

                window.location.href = oauth_url;

            },
            searchIntegration(){
                if(this.search_key == "") 
                    this.local_integrations = [...this.integrations];
                else    
                    this.local_integrations = this.integrations.filter(integration => integration.display_name.toLowerCase().includes(this.search_key.toLowerCase()));
            },
            backToSelection(){
                this.selected_integration = {};
                this.selected_integration_info = {};
            },
            changeIntegrationSelection(integration){
                this.selected_integration = integration;
                this.form.loading = true;
                //Determine if there are existing connections with the same integration
                if (integration.id == '66841ef7-ea17-4628-9b91-b6331523cd7e'){ // Bigquery
                    // send request to get table select options
                    window.axios.get('/api/integration/data_source/data_set')
                    .then(response => {
                        if(response.status == 200) {
                            // console.log(response.data);
                            this.bigquery_table_type_html = response.data.html;
                        }
                    })
                }

                window.axios.get('/api/integration/' + integration.id)
                    .then(response => {
                        this.selected_integration_info = response.data;
                        if (response.data.type == 'credential') {
                            var fields = response.data.field;
                            for (var i = 0; i< fields.length; i++){
                                if (fields[i].type == 'password')
                                    fields[i].visible = false;

                                if (fields[i].type == 'select')
                                    fields[i].value = Object.keys(fields[i].options)[0];

                                fields[i].error = false;
                            }
                        }
                        this.form.loading = false;
                    }).catch(error => {
                        this.form.loading = false;
                        this.form.error = true;
                        this.form.msg = error.response.data.message;
                    });
            },
            fetchIntegrationDetailsWithValues(connection){
                this.form.loading = true;
                if (connection.integration.id == '66841ef7-ea17-4628-9b91-b6331523cd7e'){ // Bigquery
                    // send request to get table select options
                    window.axios.get('/api/integration/data_source/data_set')
                    .then(response => {
                        this.bigquery_table_type_html = response.data.html;
                    })
                }
                window.axios.get('/api/integration/' + connection.integration.id + '/' + connection.id)
                    .then(response => {
                        this.selected_integration_info = response.data;
                        if (response.data.type == 'credential') {
                            var fields = response.data.field;
                            for (var i = 0; i< fields.length; i++){
                                if (fields[i].type == 'password')
                                    fields[i].visible = false;
                                fields[i].error = false;
                                // if (fields[i].type == 'select')
                                //     fields[i].value = Object.keys(fields[i].options)[0];
                            }
                        }
                        this.form.loading = false;
                    }).catch(error => {
                        this.form.loading = false;
                        this.form.error = true;
                        this.form.msg = error.response.data.message;
                    });;
            },

            toggleVisibility(field){
                // const input = this.$refs.password;
                if (field.type === 'password') {
                    field.type = 'password-text';
                    field.visible = true;
                } else if (field.type === 'password-text') {
                    field.type = 'password';
                    field.visible = false;
                }
            },
            addTable(field_index) {
                this.selected_integration_info.field[field_index].value.push({
                    table_name:"",
                    table_type:""
                });
            },
            removeTable(field_index, table_row_index) {
                this.selected_integration_info.field[field_index].value.splice(table_row_index, 1);
            },
            isFormValid() {
                if(this.selected_integration_info == null || this.selected_integration_info.field == null)
                    return true;

                let is_valid = true;
                for (var i = 0; i < this.selected_integration_info.field.length; i++) {
                    if(this.selected_integration_info.field[i].type == 'tables'){
                        for (var j = 0; j < this.selected_integration_info.field[i].value.length; j++) {
                            if(this.selected_integration_info.field[i].value[j].table_name == '' || this.selected_integration_info.field[i].value[j].table_type == ''){
                                this.selected_integration_info.field[i].error = true;
                                this.selected_integration_info.field[i].error_msg = 'This field is required.';
                                if(this.selected_integration_info.field[i].value.length > 1)
                                    this.selected_integration_info.field[i].error_msg += ' Be sure to remove any empty rows.';
                                is_valid = false;
                            }
                            else
                                this.selected_integration_info.field[i].error = false;
                        }
                    }
                    else if(this.selected_integration_info.field[i].required && (this.selected_integration_info.field[i].value == null ||
                        this.selected_integration_info.field[i].value == '')){
                        this.selected_integration_info.field[i].error = true;
                        this.selected_integration_info.field[i].error_msg = 'This field is required';

                        is_valid = false;
                    }
                    else
                        this.selected_integration_info.field[i].error = false;
                }

                return is_valid;
            },
            addIntegration(){

                if(!this.isFormValid()){
                    this.form.msg = "There was an error saving your information.";
                    this.form.error = true;
                    return;
                }

                this.form.msg = "";
                this.form.error = false;
                this.form.saving = true;
                //Copy the value of the field data
                let data = {
                    client_id: this.client.id
                }
                //if updating an existing connection
                if(this.local_connection_selected != null)
                    data.conn_id = this.local_connection_selected.id;

                //Loop through the field data and get the key as the data id
                this.selected_integration_info.field.forEach(field => {
                    data[field.id] = field.value;
                });

                window.axios.post('/api' + this.selected_integration_info.url, data)
                    .then(response => {
                        this.closeModal();
                        this.$emit('connectionAdded', response.data.connection);

                        this.form.saving = false;
                    }).catch(error => {
                        if(error.response.data.message != null && error.response.data.message != ""){
                            this.form.msg = error.response.data.message;
                        }
                        else{
                            this.form.msg = "There was an error saving your information. Please try again shortly";
                        }

                        this.form.saving = false;
                        this.form.error = true;
                    });
            },
            closeModal() {
                var myModalEl = document.getElementById(this.id)
                var modal = bootstrap.Modal.getInstance(myModalEl)
                modal.hide();

                // Ensure backdrop removal
                myModalEl.addEventListener('hidden.bs.modal', () => {
                    const backdrop = document.querySelector('.modal-backdrop');
                    if (backdrop) {
                      backdrop.parentNode.removeChild(backdrop);
                    }
                }, { once: true });

                this.form.saving = false;
                this.form.loading = false;
                this.form.error = false;
                this.selected_integration = {};
                this.selected_integration_info = {};
            },
        }
    }
</script>
