<template>
    <div class="scorecard-projection">
        <div class="row mb-3">
            <div class="col">
                <h2>{{ data.fytd_chart.title }} </h2>
                <a v-if="data.link != null" :href="data.link" class="btn btn-secondary float-end ms-3"> <img src="/img/icons/dialexa-icons/chart.svg" class="icon me-2">View Insights Details</a>
                <p>{{ data.description }}</p>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-4 border-end border-1">
                <h5>{{ data.fytd_chart.subtitle }}</h5>
                <div >
                    <chart-template :chart="data.fytd_chart.chart" :id="'fytd_chart' + data.id" :key="'fytd_chart' + data.id" :if_benchmark_report="true" :if_on_view="true" ></chart-template>
                </div>
            </div>
            <div class="col-8">
                <div class="ms-3">

                <h5>Projected Impact on Future Fiscal Years</h5>
                <chart-template :chart="data.fy_chart.chart" :id="'fy_chart' + data.id" :key="'fy_chart' + data.id" :if_benchmark_report="true" :if_on_view="true" ></chart-template>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="d-flex pink-gradient-left summary pt-3">
                    <div class="primary-chart-insight-icon mt-3 mx-3">
                        <img class="icon" src="/img/icons/dialexa-icons/flag.svg">
                    </div>
                    <div>
                        <h5>What If...</h5>
                        <p class="mb-3">The projections above are based upon the most current time period's trends. However, there is still time to make changes to improve results. <strong>Use the slider below to see how changes made today to the {{ data.fytd_chart.title }} will impact both this year and the 3 years' value to your organization.</strong></p>
                        <div class="d-flex">
                            <div  class="d-flex justify-content-center align-items-center "><label class="form-label mb-0">{{ Math.round(min*100) +'%' }}</label></div>
                            <div class="flex-grow-1 mx-2" :class="(local_value < 0 ? 'negative' : '')">
                                <vue-slider v-model="local_value" :tooltip-formatter="val => Math.round(val*100) +'%'" :min="min" :max="max" :interval="0.01" @drag-start="dragging=true;" @drag-end="dragEnd" @change="change" :tooltip="'always'"/>
                            </div>
                            <div class="d-flex justify-content-center align-items-center " ><label class="form-label mb-0">{{ Math.round(max*100) +'%' }}</label></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/antd.css'
    export default {
        components: {
            VueSlider,
        },
        props: ['data', 'value'],
        data() {
            return {
                local_value: null,
                min: -1,
                max:1,
                dragging: false,
            };
        },
        watch: {
            local_value(old_value, new_value){
                this.$emit('input', this.local_value);
            }
        },
        computed: {
            formattedValue() {
              return Math.round(this.local_value * 100) + '%';
            }
        },

        beforeMount() {
            this.local_value=this.value;
            //Get the second year value
            var rate = 0;
            Object.keys(this.data.fy_chart.chart.data).forEach(key => {
                let starting_pos = 1;
                //If it is a percentage
                if(this.data.fy_chart.chart.data[key][starting_pos] <= 1)
                    rate = this.data.fy_chart.chart.data[key][starting_pos];
            });

            //Make sure it isn't able to grow above 100%
            if(rate > 0) {
                let max_rate = Math.pow(1/rate, 0.333) - 1;
                if(max_rate < this.max){
                    this.max = Math.floor(max_rate*100)/100;
                    if(this.local_value > this.max)
                        this.local_value = this.max;
                }
            }
            else if(this.local_value > 1){
                this.max = Math.ceil(this.local_value);
            }

            if(this.max < this.local_value)
                this.local_value = 0;
        },
        methods: {
            change() {
                if(!this.dragging){
                    this.$emit('input', this.local_value);
                    this.$emit('change', this.local_value);
                }
            },
            dragEnd() {
                this.$emit('change', this.local_value);
                this.dragging=false;
            }
        }   
    }
</script>
