<template>
    <div class="onboarding-org-info">       

        <h2 class="mt-2 mb-3">Step 2: Organization Information</h2>
        <div class="row mb-3">
            <div class="col-12">

                <label>Non-Profit Name</label>
                <v-select :options="local_org_list"
                    placeholder="Type to search..." v-model="local_client.name"
                    :searchable="true"  name="name" id="org_search"
                    @search="debouncedSearchOptions"
                    @input="selectOrg" :class="((errors.name != '' )? 'is-invalid':'')"
                    :filterable="false">

                    <template #list-header="{ search }">
                        <li class="border-bottom border-primary border-1" @click.prevent="addNewOrg(search)" v-if="search != ''">
                            <a class="btn btn-link"><i class="fa-solid fa-circle-plus text-primary"></i> Add Organization as Written</a>
                        </li>
                    </template>
                    <template #option="{value, label}">
                        <li class="suboption show">
                        {{label}}
                        </li>
                    </template>
                    <template #no-options="{ search}">
                        <div class="suboption show mt-1" v-if="search == ''">
                            <em>Type to search for the nonprofit...</em>
                        </div>
                        <div class="suboption show mt-1" v-else-if="searching == true">
                            <div class="spinner-border  spinner-border-sm text-primary float-left" role="status"> <span class="visually-hidden">Loading...</span></div>  Loading options
                        </div>
                         <div class="suboption show mt-1" v-else-if="search_error == true">
                            <em>We seem to be having trouble searching for your organization; please enter the name and click "Add Organization as Written" to continue</em>
                        </div>

                        <div class="suboption show mt-1" v-else>
                            <em>No organizations match your search</em>
                        </div>
                    </template>
                </v-select>
                <div class="text-danger invalid-feedback" v-if="errors.name != ''" v-html="errors.name"></div>

                <div class="my-2">
                    <label>
                        <input class="form-check-input" type="checkbox" id="agency_for_org" v-model="local_client.if_agency">
                        <span class="ms-2">I am part of an agency or consultancy serving this non-profit.</span>
                    </label>
                </div>
                <div class="mt-2" v-if="local_client.if_agency == true">
                    <label>Agency Name</label>
                    <input class="form-control me-2" type="textbox" id="agency_name"
                        v-model="local_client.agency_name" @change="checkAgency()"
                        :class="((errors.agency_name != '' )? 'is-invalid':'')">
                    <div class="text-danger invalid-feedback" v-if="errors.agency_name != ''">
                        {{errors.agency_name}}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="searched_org == false">
            <div class="row">
                <div class="col">
                    <h5>Please fill out the details for {{ local_client.name }}. </h5>
                    <p>Your responses will help us tailor our benchmark dashboard to provide you with the most valuable insights for your organization.</p>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 col-sm-6">
                    <label class="form-label">Vertical</label>
                    <select class="form-select" id="vertical" v-model="local_client.vertical" :class="((errors.vertical != '' )? 'is-invalid':'')" @change="errors.vertical = ''" >
                        <option :value="item" v-for="item in vertical_types" :key="item">{{item}}</option>
                    </select>
                    <div class="text-danger invalid-feedback" v-if="errors.vertical != ''">
                        {{errors.vertical}}
                    </div>
                </div>
                <div class="mb-3 col-sm-6">
                    <label class="form-label">Annual Revenue</label>
                    <select class="form-select" id="size" v-model="local_client.size" :class="((errors.size != '' )? 'is-invalid':'')" @change="errors.size = ''" >
                        <option :value="item" v-for="item in revenue_levels" :key="item">{{item}}</option>
                    </select>
                    <div class="text-danger invalid-feedback" v-if="errors.size != ''">
                        {{errors.size}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 mb-3">
                    <div class="form-group">
                        <label class="form-label">Fiscal Year Start Month</label>
                        <select class="form-select" name="local_client.fiscal_year_offset" v-model="local_client.fiscal_year_offset"  >
                            <option value="0">January</option>
                            <option value="1">February</option>
                            <option value="2">March</option>
                            <option value="3">April</option>
                            <option value="4">May</option>
                            <option value="5">June</option>
                            <option value="6">July</option>
                            <option value="7">August</option>
                            <option value="8">September</option>
                            <option value="9">October</option>
                            <option value="10">November</option>
                            <option value="11">December</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-6 mb-3">
                    <label class="form-label">Website Domain</label>
                    <input type="text" class="form-control" :class="((errors.domain != '' )? 'is-invalid':'')"
                        name="domain" v-model="local_client.domain" @change="errors.domain = ''">
                    <div class="text-danger invalid-feedback" v-if="errors.domain != ''">
                        {{errors.domain}}
                    </div>
                </div>
            </div>
        </div> 
        <div class="row mb-3">
            <div class="col-12">
                <label :class="errors.terms_and_conditions != '' ? 'text-danger' : ''"><input type="checkbox" name="terms_and_conditions" id="terms_and_conditions" v-model="terms_and_conditions" class="form-check-input" ><span class="ms-2">By activating your Avid AI account, you agree to Avid's <a href="/privacy_policy" target="_blank">privacy policy</a> and the <a href="/terms_and_conditions" target="_blank">terms of service</a></span></label>
                <div class="text-danger invalid-feedback" v-if="errors.terms_and_conditions != ''">
                    {{errors.terms_and_conditions}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button type="submit" class="btn btn-primary w-100 mt-2" @click="nextStep()" :disabled="local_client.name=='' || processing || errors.name != ''|| errors.agency_name != ''">
                    <span v-if="processing">
                        <div class="spinner-border  spinner-border-sm text-white float-left" role="status"></div>Processing
                    </span>
                    <span v-else>
                        Continue <i class="fa fa-arrow-right"></i>
                    </span>
                </button>                
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash.debounce';
    import vSelect from "vue-select";   

    export default {
        props: ['user'],
        components: { vSelect },
        data() {
            return {
                searched_org : true,
                debouncedSearchOptions: null,
                local_org_list: [],
                local_client: {
                    name: '',
                    if_agency: false,
                    agency_name: null,
                    ein: null,
                    size: null,
                    vertical: null,
                    domain: null,
                    fiscal_year_offset: 0,
                    mission: null
                },
                errors: {
                    agency_name: '',
                    name: '',
                    size: '',
                    vertical: '',
                    domain: '',
                    fiscal_year_offset: '',
                    terms_and_conditions: ''
                },
                revenue_levels : ["Less than $1M", "$1M-$5M", "$5M-$10M", "$10M-$25M", "$25M-$50M", "$50M+"],
                vertical_types: [  'Arts, Culture, and Humanities', "Animal-Related", "Education", "Environment", "General Nonprofit", "Health & Human Services", 'Public Policy/Advocacy','Religion'],
                searching: false,
                search_error: false,
                processing: false,
                terms_and_conditions: false
            };
        },
        created() {
            this.debouncedSearchOptions = debounce(this.searchOptions, 1000);
        },
        beforeMount() {
            if(this.user == null)
                this.$emit('reset');
        },
        methods: {
            checkAgency(){
                this.errors.agency_name = '';

                if(this.local_client.agency_name != null && this.local_client.agency_name != '')
                    window.axios.post('/api/register/is_agency', {agency_name: this.local_client.agency_name}).then(response => {

                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors.agency_name = error.response.data.message;
                    }
                });
            },
            checkOrganization(){
                this.errors.name = '';

                if(this.local_client.name != null && this.local_client.name != '')
                    window.axios.post('/api/register/organization_exists', {
                        name: this.local_client.name,
                        ein: this.local_client.ein}).then(response => {

                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors.name = error.response.data.message;
                    }
                });
            },
            resetOrg(){
                this.local_client = {
                    name: '',
                    if_agency: false,
                    agency_name: null,
                    ein: null,
                    size: null,
                    vertical: null,
                    domain: null,
                    fiscal_year_offset: 0,
                    mission: null
                };
                this.errors = {
                    agency_name: '',
                    name: '',
                    size: '',
                    vertical: '',
                    domain: '',
                    fiscal_year_offset: ''
                }
            },
            searchOptions(search_name){
                if(search_name != '') {
                    //Only look if they have searched for something
                    this.searching = true;
                    this.search_error = false;
                    window.axios.post('/api/client/api_lookup', {name: search_name})
                      .then(response => {
                        this.local_org_list = []
                        if(response.data.results != null){
                            for(let i = 0; i < response.data.results.length; i++){

                                let label = response.data.results[i].name;
                                if(response.data.results[i].address != null && response.data.results[i].address.city != null)
                                    label += " - " + response.data.results[i].address.city + ", " + response.data.results[i].address.state;

                                this.local_org_list.push( {
                                    ein: response.data.results[i].ein,
                                    revenue: response.data.results[i].revenues_total.value,
                                    ntee: response.data.results[i].ntee.value,
                                    label: label,
                                    name: response.data.results[i].name
                                });
                            }
                        }
                        this.searching = false;
                        this.$forceUpdate();
                    }).catch(error => {
                        this.searching = false;
                        this.local_org_list = [];
                        this.search_error = true;

                        this.$forceUpdate();
                    });
                }
            },
            selectOrg(org_selected){
                this.searched_org = true;
                if(org_selected == null){
                    this.resetOrg();
                    return;
                }
                this.local_client.name = org_selected.name;
                this.errors.name = '';
                this.checkOrganization();
                this.local_client.ein = org_selected.ein;

                if(org_selected.revenue != null){
                    if(org_selected.revenue < 1000000)
                        this.local_client.size = "Less than $1M";
                    else if(org_selected.revenue < 5000000)
                        this.local_client.size = "$1M-$5M";
                    else if(org_selected.revenue < 10000000)
                        this.local_client.size = "$5M-$10M";
                    else if(org_selected.revenue < 25000000)
                        this.local_client.size = "$10M-$25M";
                    else if(org_selected.revenue < 50000000)
                        this.local_client.size = "$25M-$50M";
                    else "$50M+"
                }

                if(org_selected.ntee){
                    //If it starts with A
                    if(org_selected.ntee[0] == 'A')
                        this.local_client.vertical = 'Arts, Culture, and Humanities';
                    else if(org_selected.ntee[0] == 'B')
                        this.local_client.vertical = 'Education';
                    else if(org_selected.ntee[0] == 'C')
                        this.local_client.vertical = 'Environment';
                    else if(org_selected.ntee[0] == 'D')
                        this.local_client.vertical = 'Animal-Related';
                    else if(org_selected.ntee[0] == 'E' || org_selected.ntee[0] == 'F' || org_selected.ntee[0] == 'G'||org_selected.ntee[0] == 'H' || org_selected.ntee[0] == 'M' || org_selected.ntee[0] == 'P')
                        this.local_client.vertical = "Health & Human Services";
                    else if(org_selected.ntee[0] == 'Q' || org_selected.ntee[0] == 'R'|| org_selected.ntee[0] == 'V')
                        this.local_client.vertical = "Public Policy/Advocacy";
                    else if(org_selected.ntee[0] == 'X')
                        this.local_client.vertical = 'Religion';
                    else
                        this.local_client.vertical = 'General Nonprofit';
                }

                this.processing = true;

                window.axios.post('/api/client/api_lookup', {ein: this.local_client.ein})
                    .then(response => {
                        if(response.data) {
                            if(response.data.website_url != null && response.data.website_url != '')
                                this.local_client.domain = response.data.website_url;
                            else this.searched_org = false;
                            this.local_client.mission = response.data.description;
                            this.local_client.systems = response.data.widget_types;
                            if(response.data.fiscal_year_end != null && response.data.fiscal_year_end != '')
                                this.local_client.fiscal_year_offset = this.getFiscalYearStart(response.data.fiscal_year_end);
                            else this.searched_org = false;

                            this.processing = false;
                            this.$forceUpdate();
                        }
                    }).catch(error => {
                        this.searched_org = false;
                        this.processing = false;
                        this.local_org_list = []
                        this.$forceUpdate();
                    });;
            },
            getFiscalYearStart(fiscalYearEnd) {
                const months = {
                    "January": 1,
                    "February": 2,
                    "March": 3,
                    "April": 4,
                    "May": 5,
                    "June": 6,
                    "July": 7,
                    "August": 8,
                    "September": 9,
                    "October": 10,
                    "November": 11,
                    "December": 0
                };

                // Find the numerical value for the fiscal year start
                return months[fiscalYearEnd];
            },
            addNewOrg(search){

                this.searched_org = false;
                this.local_client.ein = null;
                this.local_client.name = search;
                this.local_client.size = null;
                this.local_client.fiscal_year_offset = 0;
                this.local_client.vertical = null;

                this.checkOrganization();

                //remove the vs--open and vs--searching class from #org_search element
                document.getElementById("org_search").classList.remove("vs--open");
                document.getElementById("org_search").classList.remove("vs--searching");
                //Now remove the focus off the .vs__search element
                document.querySelector(".vs__search").blur();
                this.errors.name = '';

                this.$forceUpdate();
            },
            hasErrors() {//validate the form
                if(!this.local_client.name == null || this.local_client.name == ""){
                    this.errors.org_name = "Organization name is required.";
                } else
                    this.errors.org_name = "";
                
                if((this.local_client.size == null || this.local_client.size == "") && (this.searched_org == false)){
                    this.errors.size = "Annual revenue is required.";
                } else
                    this.errors.size = "";

                if(this.local_client.vertical == null || this.local_client.vertical == ""){
                    this.errors.vertical = "Vertical is required.";
                } else
                    this.errors.vertical = "";

                if(this.local_client.domain == null || this.local_client.domain == ""){
                    this.errors.domain = "Website domain is required.";
                } else{
                    if(!this.isValidDomain(this.getDomainFromUrl(this.local_client.domain)))
                        this.errors.domain = "Enter a valid website domain";
                    else
                        this.errors.domain = "";
                }

                if(this.local_client.if_agency == true && (this.local_client.agency_name == null || this.local_client.agency_name == '' || this.errors.agency_name != '')){
                    if(this.errors.agency_name != '')
                        this.errors.agency_name = "An agency name is required.";
                }
                else
                    this.errors.agency_name = "";

                if(this.terms_and_conditions == false){
                    this.errors.terms_and_conditions = "You must agree to the terms of service.";
                } else
                    this.errors.terms_and_conditions = "";

                for (const error_name in this.errors) {
                    if (this.errors[error_name] != "") {
                        return true;
                    }
                }
                return false; 
            },
            getDomainFromUrl(url) {
                try {
                    // Create a new URL object, which will automatically parse the domain
                    const parsedUrl = new URL(url);
                    return parsedUrl.hostname;
                } catch (error) {
                    // If the input is not a full URL, assume it's already a domain
                    return url;
                }
            },
            isValidDomain(domain) {
                // Regular expression to match a valid domain
                const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,}$/;

                // Test the domain against the pattern
                return domainPattern.test(domain);
            },
            nextStep(){
                if(this.hasErrors()) {
                    return;
                }
                this.processing = true;
                window.axios.post('/api/register/organization', this.local_client)
                  .then(response => {
                    this.processing = false;
                    this.$emit('nextStep', response.data.client);

                  }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors.agency_name = error.response.message;
                    }
                });

            }
        }
    }
</script>
