<template>
    <div class="experiment_treatment_overview asset-details">
        <div class="asset-variant-detail mb-2">
            <div class="card box-content">
                <div class="card-body">
                    <h4 class="mt-2 mb-3">Experiment Treatment Details</h4>
                    <p>This will allow you to create a new version of an existing asset to be used as an experiment treatment. You'll be able to apply behavioral psychology concepts, donor personas, or simply describe how the asset should be changed. To get started, provide the details below.</p>
                    <div class="row mb-3">
                        <div class="col-12">
                            <label class="form-label">Name <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="The name of the asset that a treatment will be built off of."></i></label>
                            <input type="text" id="campaign-name-input" class="form-control" v-model="local_asset.name" :disabled="form.busy"
                                :class="((errors.name != '' )? 'is-invalid':'')" @change="inputFieldValueChange()" >
                            <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                                {{errors.name }}
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-12">
                            <label class="form-label">Asset Description <i class="fas fa-info-circle" data-bs-toggle="tooltip" title="Describe the audience that is engaging with this asset, what their motivations are, and what action you are trying to get them to take."></i>
                            </label>
                            <textarea class="form-control" v-model="local_asset.description" :disabled="form.busy" @change="inputFieldValueChange()" ref="textarea"></textarea>
                        </div>
                    </div>

                </div>
            </div>                    
            <div class="mt-3 mx-2">
                <label class="form-label">Asset Type <em>(Select one)</em></label>
                <div class="row tile-container" >
                    <div class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-1-5 asset-type-tile" v-for="asset_type in asset_types"> 
                        <div class="card box-content mx-2 mb-4" :class=getTileClass(asset_type) @click="selectAssetType(asset_type)">
                            <div class="card-body text-center tile-body py-2">
                                <div class="tile-content">
                                <i class="fa-4x" :class="asset_type.icon_class"></i>
                                <p class="mt-2 mb-0 tile-title input-field-description">{{ asset_type.name.substring(0, asset_type.name.length-1) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-danger invalid-feedback" v-if="errors.asset_type != ''">
                    {{errors.asset_type }}
                </div>
            </div>
        </div>

        <div class="asset-variant-input-detail pb-3" v-if="local_asset.asset_type" ref="assetSection">
            <div class="card box-content">
                <div class="card-body">
                    <h4 class="mt-2 mb-3">Original Version</h4>
                    <div v-for="(input_field, index) in local_asset_inputs" :key="local_asset.asset_type.id + '_' + index + '_' + input_field.placeholder">
                        <div class="row mb-3">
                            <div class="col-12">
                                <label class="form-label mb-0">
                                    {{input_field.name}} <em v-if="input_field.optional == 1">(Optional)</em>

                                    <a v-if="input_field.type=='image'" class="ms-2" href="#" @click.prevent="addEmptyImageSlot(index)">
                                        <span data-bs-toggle="tooltip" title="Add Image">
                                            <i class="fas fa-plus"></i>
                                        </span>
                                    </a>
                                </label>

                                <p class="input-field-description">{{input_field.description}}</p>

                                <div :class="getErrorClasses(input_field)" :maxlength="input_field.max_length" :currentlength="(input_field.value != null) ? input_field.value.length: 0" 
                                v-if="(input_field.type=='text' || input_field.type=='link')">
                                    <input type="text" v-model="input_field.value" :maxlength="input_field.max_length" :disabled="form.busy"
                                    class="form-control" @change="inputFieldValueChange()">
                                </div>
                                <textarea :id="input_field.placeholder+'-'+local_asset.asset_type_id" v-if="(input_field.type=='textarea' || input_field.type=='template')" v-model="input_field.value" :disabled="form.busy"
                                :maxlength="input_field.max_length" class="form-control" :class="((errors[input_field.placeholder] != '' )? 'is-invalid':'')" @change="inputFieldValueChange()" ref="textarea"></textarea>

                                <div v-if="input_field.type=='image'" class="image-array-section" >
                                    <div class="me-3" v-for="(image_url, image_index) in input_field.value">
                                        <vue-dropzone ref="image-dropzone" class="input-dropzone" :class="(image_url == null)?'':'has-background'" :id="'image-dropzone-' + local_asset.id +'-'+image_index"
                                            @vdropzone-s3-upload-success="imageUpload" :style="(image_url != null) ? 'background-image: url(\'' +image_url+'\')' : ''"
                                            @vdropzone-sending="imageUploadStarted(input_field, image_index)"
                                            :options="imageDropzoneOptions" :awss3="awss3" ></vue-dropzone>
                                        <div class="image-field-buttons">
                                            <span>
                                                <label class="form-label mb-2" v-if="image_url != null">
                                                    <a class="ms-2" href="#" @click.prevent="deleteImage(index, image_index)"><i class="fas fa-trash"></i>Delete</a> 
                                                </label>                                                
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                
                                <vue-dropzone v-if="input_field.type=='file'" ref="file-dropzone"
                                    class="input-dropzone" :id="'file-dropzone-' + local_asset.asset_type_id"
                                    :options="fileDropzoneOptions" :disabled="form.busy"
                                    v-on:vdropzone-processing="onProcessing"
                                    v-on:vdropzone-success="onSuccess"
                                    v-on:vdropzone-sending="onSending"></vue-dropzone>
                                <!-- <vue-editor v-if="input_field.type=='wysiwyg'"  v-model="input_field.value"
                                    :editorToolbar="customToolbar" @focus="inputFieldValueChange()" :class="((errors[input_field.placeholder] != '' )? 'is-invalid':'')"></vue-editor>
                                    -->
                                <wysiwyg-editor-advanced v-if="input_field.type=='wysiwyg'" :if_show_button_input="if_show_button_input" :input_value="input_field.value" :input_index="index" :asset_id=null
                                    @wysiwygValueChange="wysiwygValueChange" :if_disabled="form.busy"></wysiwyg-editor-advanced>

                                <select v-if="input_field.type=='dropdown'" v-model="input_field.value" :disabled="form.busy" class="form-select w-auto form-control"
                                :class="((errors[input_field.placeholder] != '' )? 'is-invalid':'')" @change="inputFieldValueChange()">
                                    <option value="" disabled selected>Select the value...</option>
                                    <option v-for="option in input_field.options" :value=option>{{ option }}</option>
                                </select>                       
                            </div>
                            <div class="text-danger invalid-feedback" v-if="(errors[input_field.placeholder] != null && errors[input_field.placeholder] != '' )" v-html="errors[input_field.placeholder]"></div>
                        </div>
                    </div>

                    <div class="row mb-2" >
                        <div class="col">
                            <div class="alert alert-danger mb-3" v-if="if_has_errors">
                                Fill out all required fields above including name before creating a treatment.
                            </div>
                            <span class="dropup">
                                <button type="button" class="btn me-2 dropdown-toggle btn-primary"
                                    :disabled="form.busy" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa fa-btn fa-copy"></i> Create a Treatment
                                </button>

                                <ul class="dropdown-menu pt-0 prompt-dropdown" ref="variant_dropdown">
                                    <li class='px-3 py-2 text-white form-label bg-dark'>Generate a variant based upon ...</li>
                                    <li><button class="dropdown-item btn btn-link" href="#" @click.stop="toggleDonorPersonaOptions()">Constituent Personas
                                        <i v-if="!is_donor_personas_expanded" class="fa fa-caret-right mx-1"></i>
                                        <i v-else class="fa fa-caret-down mx-1"></i>
                                    </button></li>
                                    <ul v-if="client.personas != null && is_donor_personas_expanded" class="my-1">
                                        <li v-for="persona in client.personas" ><button class="btn btn-link p-0 dropdown-item" @click="generateVariantPrompt('donor_persona', persona.description)">{{ persona.name }}</button></li>
                                    </ul>
                                    <ul v-if="client.personas == null && is_donor_personas_expanded" class="my-1">
                                        <li><a :href="'/settings/'+ client.url +'/#personas'" target="_blank" class=" dropdown-item">Define your first persona <span class="ms-2"><i class="fa-solid fa-arrow-up-right-from-square "></i></span></a></li>
                                    </ul>

                                    <li><button class="dropdown-item btn btn-link" href="#" @click.stop="toggleBehavioralConceptOptions()">Behavioral Psychology Concept
                                        <i v-if="!is_concept_expanded" class="fa fa-caret-right mx-1"></i>
                                        <i v-else class="fa fa-caret-down mx-1"></i>
                                    </button></li>
                                    <ul v-if="is_concept_expanded" class="my-1">
                                        <li v-if="concepts.length == 0">No behavioral concepts defined yet</li>
                                        <li v-for="concept in concepts"><button class="btn btn-link p-0 dropdown-item" @click="generateVariantPrompt('concept', concept.id)"
                                            data-bs-toggle="tooltip" data-bs-placement="right" :title="concept.definition">
                                            {{ concept.name }} </button></li>
                                    </ul>
                                    <li>
                                        <a class="dropdown-item btn btn-link" href="#" data-bs-toggle="modal" :data-bs-target="'#refine-prompt-modal-'+local_asset.id" @click="is_variant=true;">
                                            Describe What You Are Testing
                                        </a>
                                    </li>
                                </ul>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal example-modal fade" :id="'refine-prompt-modal-'+local_asset.id" aria-labelledby="refine-prompt-modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0 pb-0">
                        <div class="text-center w-100">
                            <button type="button" class="close btn" :data-bs-dismiss="'refine-prompt-modal-'+local_asset.id" @click="closeModal('refine-prompt-modal-'+local_asset.id)">
                                <i class="fa fa-close"></i>
                            </button>
                            <h4 class="mt-0">Describe What to Change in the Treatment</h4>
                            <p>Use the field below to describe what you are testing&mdash;how the treatment should be different. The AI will then generate new content based upon your directions.</p>
                        </div>
                    </div>
                    <div class="modal-body pt-0 pb-0">

                        <textarea class="form-control mb-3" name="example_content"
                            v-model="provided_example"></textarea>

                        <div class="mb-4">
                            <button type="submit" class="btn btn-primary" @click="generateVariantPrompt('refine_prompt', provided_example)"
                                :data-bs-dismiss="'refine-prompt-modal-'+local_asset.id">
                                <i class="fa fa-btn fa-edit"></i> Generate New Asset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        components: {vueDropzone: vue2Dropzone},
        props: ['client','campaign','asset_types', 'concepts'],
        data() {
            return {
                local_asset:{},
                local_asset_inputs: [],
                is_changes_made: false,
                if_show_button_input: false, 
                if_has_errors: false,
                is_donor_personas_expanded: false,
                is_concept_expanded: false, 
                provided_example: null,            
                form: {
                    successful: false,
                    busy: false,
                    error: false
                },
                errors: {
                    name:'',
                    asset_type:'',
                },
                imageDropzoneOptions: {
                    url: "#",
                    acceptedFiles: "image/*",
                    renameFile: function (file) {
                        let new_name = "client/" + Date.now() + "_" + file.name;
                        return new_name;
                    },
                    dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> DROP IMAGE TO UPLOAD",
                    clickable: true,
                    createImageThumbnails: true,
                    thumbnailWidth: 280,
                    thumbnailHeight: 280
                },
                awss3: {
                      signingURL: '/api/client/get_signed_url', //Where you will get signed url
                      headers: {
                        'X-Requested-With':'XMLHttpRequest',
                        'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
                      },
                      params : {},
                      sendFileToServer : false //If you want to upload file to your server along with s3
                },
                fileDropzoneOptions: {
                    url: "#",
                    method: 'put',
                    timeout: 0,
                    parallelUploads: 2,
                    dictDefaultMessage: "Click or Drag/Drop a File Here",
                    headers: {
                        'charset': "utf-8",
                        "Cache-Control": "",
                    },
                    withCredentials: false,
                    accept: function(file, done) {

                        var url_parts = window.location.pathname.split('/');
                        var client_url = url_parts[2];
                        this.options.headers['Content-Type'] = file.type;
                        window.axios.post( "/api/file_upload/get_signed_url", { url: client_url, file_name: Date.now() + "_" +file.name }).then(function(data) {

                           //I can't access the variable in the "data" property so I'll just save it to the html
                          if($("#dropzone").data("file_urls") == undefined)
                            $("#dropzone").data("file_urls", []);

                            //Save the URL for when it is actually sent in a second
                            var arr = $("#dropzone").data("file_urls");
                            arr[file.upload.uuid] = data.data.url;

                            $("#dropzone").data("file_urls", arr);
                            done();
                        });

                    },
                    init: function() {
                        const dz = this;

                        dz.on("processing", (file) => {
                            dz.options.headers["Content-Type"] = file.type;
                        });
                    },
                    maxFilesize: null,
                },
            };
        },
        beforeMount() {
            if(!this.campaign.id){ // Add a new campaign then add a new asset for this campaign
                this.local_campaign = {
                    client_id: this.campaign.client_id,
                    name: '',
                    campaign_type_id: this.campaign.campaign_type_id,
                    campaign_goal: '',
                    audience: '',
                    take_action: '',
                    asset_types: [],
                    assets: []
                };

                this.local_asset = {
                    client_id: this.campaign.client_id,
                    name: '',
                    campaign_id: -1, 
                    asset_types: null,
                    asset_type_id: -1, 
                    initiative_id: null, 
                    description: '',
                    inputs: null,
                    status: 'draft', // default to draft 
                    version: 'original'
                };
            }
        },
        updated() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            for (var i = 0; i < tooltipTriggerList.length; i++) {
              (function(index) {
                tooltipTriggerList[index].addEventListener('click', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
                tooltipTriggerList[index].addEventListener('mouseleave', function() {
                  // Hide the tooltip using the index passed to the closure
                  tooltipList[index].hide();
                });
              })(i);
            }
        },
        methods: {
            selectAssetType(asset_type) {
                if (this.form.busy){
                    return;
                }
                this.local_asset.asset_type = asset_type;
                this.local_asset.asset_type_id = asset_type.id
                this.checkIfShowButtonInput();
                this.loadInputs();
                // this.$forceUpdate();
            },
            getTileClass(asset_type){
                var classes = "";
                if (this.local_asset.asset_type_id == asset_type.id){
                    classes = 'tile-selected'
                }
                if (this.form.busy){
                    classes += ' tile-disabled';
                }
                return classes;
            },
            inputFieldValueChange(){
                this.is_changes_made = true;
            },
            addEmptyImageSlot(index){
                var tooltip = document.querySelector('.tooltip')
                if (tooltip){
                    tooltip.classList.remove('show'); // hide tooltip                    
                }
                this.local_asset_inputs[index].value.push(null);
            },
            toggleDonorPersonaOptions(){
                this.is_donor_personas_expanded = !this.is_donor_personas_expanded;
            },
            toggleBehavioralConceptOptions(){
                this.is_concept_expanded = !this.is_concept_expanded;
            },
            wysiwygValueChange(newValueWithIndex){
                this.local_asset_inputs.forEach((input, index) => {
                    if (input.type=='wysiwyg' && index == newValueWithIndex.index){
                        if (this.local_asset_inputs[index].value != newValueWithIndex.value){
                            this.is_changes_made = true;
                        }
                        this.local_asset_inputs[index].value = newValueWithIndex.value;
                    }
                });
            },
            checkIfShowButtonInput(){
                if(this.local_asset.asset_type.name == "Donor Emails" || this.local_asset.asset_type.name == "Prospecting Emails"
                || this.local_asset.asset_type.name == "Housefile Emails"){
                    this.if_show_button_input = true;
                } else {
                    this.if_show_button_input = false;
                }
            },
            generateVariantPrompt(type, value){
                this.if_has_errors = this.hasErrors();
                if(this.if_has_errors){
                    return;
                } else {
                    this.saveAsset(type, value);

                    if(type == 'refine_prompt')
                        this.closeModal('refine-prompt-modal-' + this.local_asset.id);  
                }
            },
            saveAsset(type, value) {
                var self = this;

                self.form.busy= true;
                this.imageDropzoneOptions.clickable = false; // disable image fields

                var asset_input = []
                this.local_asset_inputs.forEach(input_field =>{
                    if(input_field.type == "wysiwyg"){
                        var formatted_value = input_field.value.replace('data-bs-toggle="modal" ', '')
                        .replace('data-bs-target="#insert-image-modal-'+this.local_asset.id +'\" ', '')
                        .replace('data-bs-target="#insert-button-modal-'+this.local_asset.id +'\" ', '');
                        asset_input.push({
                            placeholder: input_field.placeholder,
                            value: formatted_value
                        });
                    }  else if(input_field.type == "image" && Array.isArray(input_field.value) && input_field.value.length == 1 && input_field.value[0] == null){
                        // If image array is empty, empty image array would be an array has only one element and the only element is null
                        asset_input.push({
                            placeholder: input_field.placeholder,
                            value: null
                        });
                    } else {
                        asset_input.push({
                            placeholder: input_field.placeholder,
                            value: input_field.value
                        });                        
                    }
                });
                this.local_asset.inputs = asset_input;
                this.local_asset.status = 'approved';

                // create a new campaign
                this.local_campaign.name = this.local_asset.name;
                this.local_campaign.assets = [this.local_asset];
                this.local_campaign.asset_types = [this.local_asset.asset_type];
                this.local_campaign.variant = {
                    option_selected: type,
                    content: value
                };

                //Remove the "show" class
                this.$refs.variant_dropdown.classList.remove("show");

                // Swal.fire({
                //     title: "Your Treatment is Generating.",
                //     text: "Avid AI is processing your treatment; once it is finished, you'll be redirected to the Asset Details page and able to make adjustments or regenerate based upon additional feedback." ,
                //     icon: "warning",
                //     iconColor:  "#46705F",
                //     confirmButtonColor: "#2A324B",
                //     confirmButtonText: "Close",
                //     timer: 5000,
                // });
                window.axios.post('/api/campaigns/'+this.client.url + '/experiment_variant', this.local_campaign)
                    .then(response => {
                        if(response.status == 200) {
                            self.form.busy = false;
                            window.location = '/campaigns/'+ self.client.url +'/assets/' + response.data.campaign.url + '/'+self.local_campaign.assets[0].asset_type_id + '/true';
                        }
                });
            },
            closeModal(id) {
                var myModalEl = document.getElementById(id)
                var modal = bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
                this.provided_example = null;
            },
            loadInputs(){
                this.local_asset_inputs = [];
                this.local_asset.asset_type.inputs.forEach(input => {
                    var val = null;
                    if(this.local_asset.inputs != null) {
                        let ph = this.local_asset.inputs.find(i => i.placeholder == input.placeholder);
                        if(ph != null)
                            val = ph.value;
                    }

                    var input_object = {
                        name: input.name,
                        description:input.description,
                        max_length:input.max_length,
                        placeholder:input.placeholder,
                        type:input.type,
                        optional:input.optional,
                        options:input.options,
                        value: val,
                        orig_value: val,
                    }
                    if(input.type=='image'){ // construct image array
                        if (!Array.isArray(val)){
                            input_object.value = [val];
                            input_object.orig_value = [val];
                        }
                    }
                    this.local_asset_inputs.push(input_object);

                    if(input.max_length != null && val != null && input.max_length < val.length){
                        this.errors[input.placeholder] = 'The maximum number of characters is ' + input.max_length + ". When generating text, the AI has a hard time following character limit rules&mdash;you'll need to adjust."
                    }
                    else
                        this.errors[input.placeholder]='';

                });
            },
            hasErrors(){
                if ((this.local_asset.name == null || this.local_asset.name == "")){
                    this.errors.name = 'Please provide a name for the asset that is being tested.'
                } else {
                    this.errors.name = '';
                }

                this.local_asset_inputs.forEach((input) =>{
                    if((input.value == null || input.value == "") && (input.optional == 0) && (input.type == 'text' || input.type == 'textarea' || input.type=='wysiwyg')){
                        this.errors[input.placeholder] = input.name + " is required.";
                    } else if (input.value != null && input.max_length != null && input.value.length > input.max_length){
                        this.errors[input.placeholder] = 'The maximum number of characters is ' + input.max_length + ". Please adjust the text above."
                    } else {
                        this.errors[input.placeholder] = "";
                    }
                })
                for (const error_name in this.errors) {
                    if (this.errors[error_name] != '') {
                        return true;
                    }
                }
                return false;
            },
            getErrorClasses(input) {
                var classes = ''
                
                if (input.max_length != null)
                    classes += 'max-warning'

                if(this.errors[input.placeholder] != null && this.errors[input.placeholder] != '' )
                    classes +=' is-invalid';

                return classes;
            },
            imageUploadStarted(input_field, image_index) {
                this.active_input_field = {
                    image_field: input_field,
                    image_index: image_index
                };
            },
            imageUpload(s3ObjectLocation){
                s3ObjectLocation = s3ObjectLocation.replace("%2F", "/");
                this.active_input_field.image_field.value[this.active_input_field.image_index] = s3ObjectLocation;
                this.$forceUpdate();
                this.active_input_field = null;
            },
            deleteImage(input_field_index, image_index){
                var self = this;
                Swal.fire({
                  title: "Are you sure you want to delete this image?",
                  text: "This image will be removed from this asset." ,
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.local_asset_inputs[input_field_index].value.splice(image_index,1)
                        if (this.local_asset_inputs[input_field_index].value.length == 0){
                            // when deleting the only image in the array, reset the array to have one empty image slot
                            this.local_asset_inputs[input_field_index].value = [null]
                        }
                        this.inputFieldValueChange();
                    }
                });
            },
            onProcessing(file) {
                //Set the upload URL for this specific file
                var file_urls = $("#dropzone").data('file_urls');
                document.getElementById("dropzone").dropzone.options.url = file_urls[file.upload.uuid];
            },
            onSuccess( file, res ) {
                var data = new FormData();
                data.append("file_name", file.name);
                data.append("gcs_path", file.xhr.responseURL);
                data.append("client_url", this.client.url);

                //Remove it from the dropzone UI
                var fadeTarget = document.getElementsByClassName("dz-success")[0];
                var fadeEffect = setInterval(function () {
                    if (!fadeTarget.style.opacity) {
                        fadeTarget.style.opacity = 1;
                    }
                    if (fadeTarget.style.opacity > 0) {
                        fadeTarget.style.opacity -= 0.1;
                    } else {
                        clearInterval(fadeEffect);
                        document.getElementById("dropzone").dropzone.removeFile(file);
                    }
                }, 50);

                this.local_files.unshift({
                    id: Math.floor(Math.random() * -100000),
                    source: 'Upload',
                    status: "Processing",
                    name: file.name,
                    created_at: new Date().getTime()
                });
                this.local_conn.num_files++;

                var self = this;
                var id_to_remove = this.local_files[0].id;
                window.axios.post('/api/file_upload/new_file', data).then(function(data) {
                    //Insert the file returned in data.file to the top of the local_files variable
                    for(var i = 0 ; i < self.local_files.length; i++)
                        if(self.local_files[i].id == id_to_remove) {
                            self.local_files[i] = data.data.file;
                            break;
                        }

                    self.$forceUpdate();
                });
            },
            onSending( file, xhr, formData ) {
                var _send = xhr.send;
                xhr.send = function() {
                  _send.call(xhr, file);
                };
            },        
        }
    }
</script>
