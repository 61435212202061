<template>
    <div class="files" >
        <div class="row">
            <div class= "col">
                <div class="card box-content  mb-4  mt-3 p-0 ">
                    <div class="card-body px-3 py-1 m-0">
                        <nav class="navbar navbar-expand-lg">
                          <div class="container-fluid">
                            <span class="navbar-brand mb-0 h1">Steps:</span>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#filter-navbar" aria-controls="filter-navbar" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="filter-navbar">
                              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item me-4">
                                  <a class="nav-link d-flex flex-nowrap" :class="{'text-dark': step_num==1}" href="#" role="button" aria-expanded="false" v-on:click="step_num=1">
                                    <div class="rounded-circle avatar flex-fill" :class="step_num==1?'primary':''">1</div> <span class="m-2">Information</span>
                                  </a>
                                </li>
                                <li class="nav-item me-4">
                                  <a class="nav-link d-flex flex-nowrap" :class="{'pe-none': (step_num == 1), 'text-dark': step_num==2}" href="#" role="button" aria-expanded="false" v-on:click="step_num=2">
                                    <div class="rounded-circle avatar flex-fill" :class="step_num==2?'primary':''">2</div> <span class="m-2">Filters</span>
                                  </a>
                                </li>
                                <li class="nav-item me-4">
                                  <a class="nav-link d-flex flex-nowrap" :class="{'pe-none': (step_num <= 2), 'text-dark': step_num==3}" href="#" role="button" aria-expanded="false">
                                    <div class="rounded-circle avatar flex-fill" :class="step_num==3?'primary':''">3</div> <span class="m-2">Column Mapping</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="step_num==1">

            <div class="col">

                <div class="card box-content mb-3">
                    <div class="card-body">
                        <h3>Data Set Information</h3>
                        <p class="mb-3">A "data set" is a view of the data extracted from your integrated systems. To be properly used within the system, we need to establish the information about the data set and the most commonly used data points need to be mapped to standard columns. If there are additional columns you'd like to use for filters, be sure to add those columns too.</p>
                        <div class="mb-3">
                            <label class="form-label">Data Set Name</label>
                            <input type="text" class="form-control" :class="((errors.name != '' )? 'is-invalid':'')"
                                name="name" v-model="local_dataset.name" v-on:change="form.error = false; errors.name=''">
                            <div class="text-danger invalid-feedback" v-if="errors.name != ''">
                                {{errors.name}}
                            </div>
                        </div>
                        <div class="mb-3" v-if="file_or_dataset == 'file' || errors.category != ''">
                            <label class="form-label">Data Category</label>
                            <select class="form-select"  v-model="local_dataset.source_type_id"
                                @change="setDataSourceType()"  :class="((errors.category != '' )? 'is-invalid':'')"
                                 v-on:change="form.error = false; errors.category=''">
                                <option v-for="(cols, key) in source_types" :value="key">{{key}}</option>
                            </select>
                            <div class="text-danger invalid-feedback" v-if="errors.category != ''">
                                {{errors.category}}
                            </div>
                        </div>
                        <div v-if="local_dataset.source != 'Airbyte' && local_dataset.source != 'Table'">
                            <div class="mb-3">
                                <label class="form-label">Refresh Type</label>
                                <select class="form-select"  v-model="local_dataset.settings.refresh_type" @change="columnsValid()" >
                                    <option value="incremental" >Append: New files are merged with prior uploads</option>
                                    <option value="full" >Replace: New files replace prior uploads</option>
                                </select>
                                <em class="small" v-if="local_dataset.settings.refresh_type == 'incremental'">When appending files, the "Updated At" field will ensure the most recent record is used (if it is set on the Column Mapping screen).</em>

                            </div>
                            <div class="mb-3" v-if="local_dataset.settings.refresh_type == 'incremental'">
                                <label class="form-label" data-bs-toggle="tooltip" title="When appending files, you can select one or more columns to be used as a unique ID. Records with the same ID as a record in a prior upload will be used as a replacement rather than a new record. If no columns are selected, records will always be appended.">Column(s) to Identify Unique Records <i class="fas fa-info-circle"></i></label>
                                <div class="dropdown">
                                  <button class="btn form-select btn-field" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside">
                                    <span v-if="(local_dataset.settings.id_columns != null && local_dataset.settings.id_columns.length > 0 )">{{ id_columns }}</span><span v-else><em>None Selected</em></span>
                                  </button>
                                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"  >
                                      <label class="dropdown-item" v-for="(item, key) in local_dataset.source_columns" :key="key">
                                        <input class="form-check-input" type="checkbox" :value="key"  v-model="local_dataset.settings.id_columns" :true-value="[]">
                                        <span class="ms-2" >
                                          {{ item }}
                                        </span>
                                      </label>
                                  </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" data-bs-toggle="tooltip" title="Identify what files should be included in this data set. You can use a wildcard character (%) if there are parts of the file name that will change with each upload or, for more advanced rules, use a regular expression by starting the pattern with r' and ending it with a '">File Name Pattern <i class="fas fa-info-circle"></i></label>
                                <input type="text" class="form-control" :class="((errors.pattern != '' )? 'is-invalid':'')"
                                    name="pattern" v-model="local_dataset.settings.pattern" @change="refreshFiles()">
                                <div class="text-danger invalid-feedback" v-if="errors.pattern != ''">
                                    {{errors.pattern}}
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" id="ignore_unused" v-model="local_dataset.settings.ignore_unused" @change="columnsValid()">
                                  <label class="form-check-label" for="ignore_unused">
                                    <strong>Ignore Unused Column Changes.</strong> If columns are added or removed in future uploads, the file will still process when the changes don't impact existing mappings.
                                  </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4"  v-if="local_dataset.source != 'Airbyte' && local_dataset.source != 'Table'">
                <div class="card box-content mb-3 overflow-hidden">
                    <div class="card-body px-0 pb-0">
                        <p class="px-3">Based upon the pattern specified, the following files will be included in this data set:</p>
                        <div class="table-responsive" style="max-height: 271px;" v-if="local_files.length > 0">
                            <table class="table gy-0 m-0 mb-2 w-100">
                                <thead class="table-dark">
                                    <tr>
                                        <th class='align-middle'>File Name</th>
                                        <th class="align-middle" colspan="2">Uploaded At</th>
                                    </tr>
                                </thead>
                                <transition-group tag="tbody" name="table-row" class="table-border-bottom-0" >
                                    <tr v-for="(f, index) in local_files" :key="f.id" :class="(f.status_msg == 'MISMATCH' ? 'error' : '')">
                                        <td class='ps-3 align-middle'>
                                            {{f.name}}
                                        </td>
                                        <td class="align-middle text-nowrap" :colspan="(f.status_msg == 'MISMATCH'? '1' : '2')" >{{ f.created_at | datetime }}</td>
                                        <td v-if="f.status_msg == 'MISMATCH'" class="text-end" ><button type="button" class="btn btn-sm btn-none red" ><i class="fa fa-trash"  @click="removeFile(f)"></i></button></td>
                                    </tr>
                                </transition-group>
                            </table>
                        </div>
                        <div v-else>
                            <p class="px-3"><strong><em>No files were found that match the given pattern.</em></strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"  v-if="step_num==1">
            <div class="col">
                <div class="alert alert-danger mb-3" v-if="form.error">
                    There is an issue with the information provided in the form above. Please check the fields and make the necessary corrections.
                </div>
                <!-- Save Button -->
                <div class="mb-3">
                    <button type="submit" class="btn btn-primary" @click="nextStep()" :disabled="this.form.busy">
                         <span v-if="file_or_dataset == 'data_set'">Save & </span>Next <div v-if="form.busy" class="spinner-border  spinner-border-sm float-left ms-1" role="status"></div><i v-else class="fa fa-btn fa-chevron-right ms-1"></i>
                    </button>
                </div>

            </div>
        </div>

        <div class="row"  v-if="step_num==2">
            <div class="col mb-3 ">
                <div :class="local_dataset.filter != null && local_dataset.filter.filter_ui=='advanced'?'card box-content' : ''">
                    <div :class="{'card-body': local_dataset.filter != null && local_dataset.filter.filter_ui=='advanced'}">
                        <h3>Inclusion Filters</h3>
                        <p>You are able to limit the records in your data set. This is helpful if removing records like soft credits from a list of transactions or non-donors from a list of constituents.
                        <query-inclusion-builder :client="client" v-model="local_dataset.filter"
                            :static_db_columns="local_dataset.source_columns"
                            :include_trans="false" :source="file_or_dataset" :in_card="false" :add_blank="false"
                            :data_source_id="object_id" ref="filter"></query-inclusion-builder>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row"  v-if="step_num==2">
            <div class="col">
                <div class="alert alert-danger mb-3" v-if="form.error">
                    There is an issue with the information provided in the fields above. Please check your entries and make the necessary corrections.
                </div>
                <!-- Save Button -->
                <div class="mb-3">
                    <button type="submit" class="btn btn-primary" @click="nextStep()" :disabled="this.form.busy">
                         <span v-if="file_or_dataset == 'data_set'">Save & </span>Next <div v-if="form.busy" class="spinner-border  spinner-border-sm float-left ms-1" role="status"></div><i v-else class="fa fa-btn fa-chevron-right ms-1"></i>
                    </button>
                </div>

            </div>
        </div>

        <div class="row" v-if="step_num==3">
            <div class="col mb-3">
                <h3>Column Mapping</h3>
                <p>Based upon the "Data Category" selected, there are several mandatory and optional fields to be mapped. <strong>If you have additional columns you'd like to use for filters, be sure to add those columns too.</strong></p>

                <column-mapping :client="client" :dataset="local_dataset" :dest_columns="dest_columns"
                    ref="column_mapping" v-model="local_dataset.columns" :data_source_id="object_id"
                    :source="file_or_dataset" ></column-mapping>
            </div>
        </div>
        <div class="row" v-if="step_num==3">
            <div class="col">
                <div class="alert alert-danger mb-3" v-if="form.error">
                    There was an issue saving the data set. Check the fields above to get more information.
                </div>
                <!-- Save Button -->
                <div class="mb-3">
                    <button type="submit" class="btn btn-primary"
                            @click="save()"
                            :disabled="form.busy">
                        <i class="fa fa-save" v-if="!form.busy"></i><i class="fas fa-sync fa-spin" v-else></i> Save
                    </button>
                </div>

            </div>
        </div>


    </div>
</template>

<script>

    import Swal from 'sweetalert2';
    export default {
        components: {
            Swal
        },

        props: ['dataset', 'files', 'client', 'source_types'],
        data() {
            return {
                local_dataset: null,
                local_files: null,
                dest_columns: [],
                form: {
                    error: false,
                    busy: false
                },
                errors: {
                    name: '',
                    category: '',
                    pattern: '',
                    filter: ''
                },
                filter_type: 1,
                step_num: 1
            };
        },
        beforeMount() {
            //Make a deep copy of the dataset

            this.local_dataset = JSON.parse(JSON.stringify(this.dataset));
            //Make a null settings into an object
            if(this.local_dataset.settings == null) {
                this.local_dataset.settings = {ignore_unused: true};
            }else if(this.local_dataset.settings.ignore_unused == null)
                this.local_dataset.settings.ignore_unused = true;

            if(this.files == null)
                this.local_files = [];
            else
                this.local_files = this.files;

            //Set the fake "source type id" column and the types of columns
            if(this.local_dataset.source_type != null){
                //Foreach to loop through the source_types object
                for (const key in this.source_types) {
                    //Need to match on source type and source subtype (which can be null)
                    if(this.source_types[key][0].source_type == this.local_dataset.source_type &&
                        (this.local_dataset.source_subtype == null &&
                            this.source_types[key][0].source_subtype == null ||
                            this.local_dataset.source_subtype == this.source_types[key][0].source_subtype)){
                        this.local_dataset.source_type_id = key;
                        this.dest_columns = this.source_types[key];
                        break;
                    }
                }
                if(Array.isArray(this.local_dataset.columns) && this.local_dataset.columns.length == 0)
                    this.local_dataset.columns = null;
            }

            //If the source_columns isn't set...
            if(this.local_dataset.source_columns == null && this.local_files.length >= 1) {
                this.local_dataset.source_columns = this.local_files[0].columns;
            }

            const params = new URLSearchParams(window.location.search);
            if(params.get('step') != null)
                this.step_num = parseInt(params.get('step'));
        },
        computed: {
            file_or_dataset() {
                var split = window.location.pathname.split("/");
                return split[split.length - 2];
            },
            object_id() {
                var split = window.location.pathname.split("/");
                return split[split.length - 1];
            },
            id_columns() {
                //Loop through the local_dataset.settings.id_columns array (if it isn't null)
                //and return the column names
                if(this.local_dataset.settings.id_columns == null || this.local_dataset.source_columns == null)
                    return '';
                else {
                    var col = this.local_dataset.source_columns[this.local_dataset.settings.id_columns[0]];;
                    for(var i = 1; i < this.local_dataset.settings.id_columns.length; i++) {
                        col += ", " + this.local_dataset.source_columns[this.local_dataset.settings.id_columns[i]];
                    }
                    return col;
                }
            }
        },
        updated() {
             const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
             const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
        },
        watch: {
            local_files(value) {
                this.columnsValid();
            },
        },
        methods: {

            save() {
                this.form.error = false;

                //Validate the query builder
                if(!this.$refs.column_mapping.isValid())
                    this.form.error = true;
                else if(!this.columnsValid()){
                    this.form.error = true;
                    Swal.fire({
                        title: "Error",
                        html: "The columns that you utilized in your column mapping aren't available with all of the matching files. Please delete the files noted in the table or remove the new mappings not present in those files.",
                        icon: "warning",
                        iconColor:  "#D11F1F",
                        confirmButtonColor: "#D11F1F"
                    }).then((result) => {
                        this.step_num=1;
                    });
                }
                else {
                    var _self = this;
                    this.form.busy = true;

                    if(this.local_dataset.id == null) {

                        window.axios.post('/api/data_set/'+this.client.url, this.local_dataset)
                          .then(response => {

                            _self.form.busy = false;
                            Swal.fire({
                                title: "Success!",
                                html: "The data set <strong>"+_self.local_dataset.name +"</strong> was successfully created. The data will be processed shortly and can then be used for analysis and keeping your various systems in sync.",
                                icon: "success",
                                iconColor:  "#46705F",
                                confirmButtonColor: "#46705F"
                            }).then((result) => {
                                // window.location = "/integrations/"+_self.client.url+"/data_sets"

                                var previous_url = document.referrer;
                                document.location.href = previous_url;
                            });
                          });
                      }
                      //Just update the old filter
                      else {

                        window.axios.put('/api/data_set/'+this.client.url + '/' + this.local_dataset.id, this.local_dataset)
                          .then(response => {

                            _self.form.busy = false;
                            Swal.fire({
                                title: "Success!",
                                html: "The data set <strong>"+_self.local_dataset.name +"</strong> was successfully created. The data will be processed shortly and can then be used for analysis and keeping your various systems in sync.",
                                icon: "success",
                                iconColor:  "#46705F",
                                confirmButtonColor: "#46705F"
                            }).then((result) => {
                                // window.location = "/integrations/"+_self.client.url+"/data_sets"

                                var previous_url = document.referrer;
                                document.location.href = previous_url;
                            });
                          });
                      }
                }
            },
            nextStep() {
                //Validate the form
                if(this.step_num == 1) {
                    if(this.local_dataset.source_type_id == null || this.local_dataset.source_type_id == "") {
                        this.errors.category = "Please select a category for this data set.";
                        this.form.error = true;
                    }
                    if(this.local_dataset.name == "") {
                        this.errors.name = "Please enter a name for this data set.";
                        this.form.error = true;
                    }
                    if((this.local_dataset.source != 'Airbyte' && this.local_dataset.source != 'Table') && this.local_dataset.settings.pattern == '') {
                        this.errors.pattern = "Please enter a file pattern to use to identify subsequently uploaded files.";
                        this.form.error = true;
                    }
                    else if(this.local_dataset.source != 'Airbyte' && this.local_dataset.source != 'Table' && this.local_files.length == 0 ) {
                        this.errors.pattern = "The current pattern doesn't have any matching files. Please update it to match files that have already been uploaded.";
                        this.form.error = true;
                    }

                    if(!this.form.error){
                        //If something has changed, update the dataset
                        if(this.file_or_dataset == 'data_set' && this.datasetHasChanged()){
                            this.form.busy = true;
                            window.axios.put('/api/data_set/'+this.client.url+ '/' + this.local_dataset.id, this.local_dataset).then(response => {
                                this.local_dataset = response.data.data;
                                this.form.busy = false;
                                this.step_num++;
                            });
                        }
                        else
                            this.step_num++;
                    }
                }
                else if(this.step_num == 2) {
                    //Validate the query builder
                    if(!this.$refs.filter.isFormValid())
                        this.form.error = true;

                    else {
                        this.form.error = false;
                        if(this.file_or_dataset == 'data_set' && this.filterHasChanged()){
                            this.form.busy = true;
                            window.axios.put('/api/data_set/'+this.client.url+ '/' + this.local_dataset.id, this.local_dataset).then(response => {
                                this.local_dataset = response.data.data;
                                this.form.busy = false;
                                this.step_num++;
                            });
                        }

                        else
                            this.step_num++;
                    }
                }
            },
            filterHasChanged() {
                return !this.deepEqual( this.local_dataset.filter , this.dataset.filter);
            },
            deepEqual(obj1, obj2){
                if (obj1 === obj2) return true;

                if (typeof obj1 !== 'object' || obj1 === null ||
                    typeof obj2 !== 'object' || obj2 === null) {
                    return false;
                }

                let keys1 = Object.keys(obj1);
                let keys2 = Object.keys(obj2);

                if (keys1.length !== keys2.length) return false;

                for (let key of keys1) {
                    if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
                        return false;
                    }
                }

                return true;
            },
            datasetHasChanged() {
                //If any of the following are different from the dataset prop
                if(this.local_dataset.settings.ignore_unused != this.dataset.settings.ignore_unused)
                    return true;
                if(this.local_dataset.settings.pattern != this.dataset.settings.pattern)
                    return true;
                if(this.local_dataset.settings.refresh_type != this.dataset.settings.refresh_type)
                    return true;
                //ID_Columns is an array, so we need to compare the contents
                if(!this.arraysEqual(this.local_dataset.settings.id_columns, this.dataset.settings.id_columns))
                    return true;
            },
            arraysEqual(arr1, arr2) {
                if (arr1.length !== arr2.length) return false;
                arr1 = arr1.slice().sort();
                arr2 = arr2.slice().sort();
                for (let i = 0; i < arr1.length; i++) {
                    if (arr1[i] !== arr2[i]) return false;
                }
                return true;
            },
            columnsValid() {
                this.errors.pattern = "";
                this.form.error = false;

                // If it is incremental, all of the local files columns need to match (or be in the most recent one)
                if(this.local_dataset.settings.refresh_type == 'incremental' && this.local_files.length > 1){
                    //Check if the URL has "mapping/file" in it
                    var recent_file = this.local_files[0];
                    var file_id = -1;
                    if(window.location.pathname.indexOf("mapping/file") != -1){
                        //Get the fileID from the URL and update the "recent_file" with that file
                        var split = window.location.pathname.split("/");
                        file_id = split[split.length - 1];
                        for(var i = 0; i < this.local_files.length; i++)
                            if(this.local_files[i].id == file_id){
                                recent_file = this.local_files[i];
                                break;
                            }
                    }

                    //Get an array of the keys of the recent_file.columns object
                    var compare_columns = Object.keys(recent_file.columns);
                    if(this.local_dataset.settings.ignore_unused){
                        compare_columns = this.getUsedColumns();
                    }

                    //Look through all of the files
                    for(var i = 0; i < this.local_files.length; i++){
                        //Don't worry about the files we're comparing it to
                        if(this.local_files[i].id == recent_file.id)
                            continue;

                        //Loop through each of the columns
                        for (let key in compare_columns) {
                            //If it isn't in the current file's list of columns, throw an error
                            if (!(compare_columns[key] in this.local_files[i].columns)){
                                var file_language = "the most recent";
                                if(file_id >= 0)
                                    file_language = "your selected";
                                this.errors.pattern = "The files identified by the given pattern have one or more columns missing when compared to "+file_language+" file. Please update the pattern or delete the files identified in the table.";
                                this.form.error = true;
                                this.local_files[i].status_msg = "MISMATCH";
                                break;
                            }
                            else this.local_files[i].status_msg = "";
                        }
                    }
                } else {
                    //Reset the status message of the files in case they had previously been flagged
                    for(var i = 0; i < this.local_files.length; i++)
                        this.local_files[i].status_msg = "";
                }

                return !this.form.error;

            },
            getUsedColumns(){

                // Get all of the mapped columns from the column definitions relationship
                let columns = this.local_dataset.columns;
                let arr = [];

                // If there are identified ID columns
                let settings = this.local_dataset.settings;
                if (settings.id_columns && settings.refresh_type && settings.refresh_type === 'incremental') {
                    // Merge the array into arr
                    arr = [...arr, ...settings.id_columns];
                }
                if (columns){
                    columns.forEach(c => {
                        // If it isn't a custom definition
                        if (c.source_column !== 'CUSTOM') {
                            // Insert it only if it isn't already in the array (and it has been mapped)
                            if (!arr.includes(c.source_column) && c.source_column) {
                                arr.push(c.source_column);
                            }
                        } else {
                            let logic = c.column_logic;
                            // Check each unit
                            logic.units.forEach(unit => {
                                // Check the value type
                                if (unit.value_type === 'column' && !arr.includes(unit.value)) {
                                    arr.push(unit.value);
                                }

                                // Now loop through the logic units
                                unit.logic.logic_units.forEach(u => {
                                    // If the right side of the equation is a column mapping
                                    if (!u.is_static && !u.show_options && !arr.includes(u.value.value)) {
                                        arr.push(u.value.value);
                                    }
                                    // Get the left side of the equation
                                    if (!arr.includes(u.db_column.value)) {
                                        arr.push(u.db_column.value);
                                    }
                                });
                            });

                            // Check the default type
                            if (logic.default_type === 'column' && !arr.includes(logic.default_value)) {
                                arr.push(logic.default_value);
                            }
                        }
                    });                    
                }


                return arr;

            },
            setDataSourceType() {
                for (const key in this.source_types) {
                    if(key == this.local_dataset.source_type_id){
                        this.dest_columns = this.source_types[key];
                        this.local_dataset.source_type = this.source_types[key][0].source_type;
                        this.local_dataset.source_subtype = this.source_types[key][0].source_subtype;
                        break;
                    }
                }
                this.local_dataset.columns = null;
            },
            refreshFiles() {
                var self = this;
                self.errors.pattern = "";
                if(this.local_dataset.settings.pattern != "")
                    window.axios.post('/api/files/' + this.client.url + '/list_by_pattern',
                        {'pattern': this.local_dataset.settings.pattern }).then(response => {
                        if(response.status == 200) {

                            self.local_files = response.data.files;
                            if(self.local_files.length > 0)
                                self.local_dataset.source_columns = this.local_files[0].columns;
                        }

                      });
                else {
                    self.errors.pattern = "You must specify a pattern to identify current and future files with.";
                    self.local_files = [];
                }

            },

            removeFile(file) {
                var self  = this;
                Swal.fire({
                  title: "Are you sure you want to delete <em>" + file.name + "</em>?",
                  text: "This will remove the file from the system and prevent it from being used with future data processes.",
                  icon: "warning",
                  iconColor: "#D11F1F",
                  showCancelButton: true,
                  confirmButtonColor: "#D11F1F",
                  confirmButtonText: "Yes, delete",
                }).then((result) => {
                      if (result.isConfirmed) {
                        for(var key in self.local_files)
                            if(self.local_files[key].id == file.id) {
                                self.local_files.splice(key, 1);
                                break;
                            }
                        self.columnsValid();
                        window.axios.delete('/api/files/' + self.client.url + '/destroy/' + file.id);
                  }
                });

            }

        }
    }
</script>
