<template>
    <div class="row onboarding">
        <div class="col-lg-6 ">
            <div class="d-lg-block d-none">
                <h2 class="text-primary mt-5 text-center">Turn your data into donations</h2>
                <div class="row d-none d-lg-block">
                    <div class="col-sm-10 offset-sm-1 mt-3">
                        <img src="/img/screenshot.png" class="w-100">
                    </div>
                </div>
                <div class="row d-none d-lg-block">
                    <div class="col-sm-10 offset-sm-1 mt-3">
                        <div class="card box-content">
                            <div class="card-body d-flex align-items-center">
                                <img src="https://s3.us-west-2.amazonaws.com/nextafter-pulse/users%2F1726175140680_image+%2816%29.png" class="avatar rounded-circle me-3">
                                <p class="mb-0">"Avid seems to excel at integrating data from our current complicated donor data collection methods, turning it into actionable insights that lead to measurable improvements." <small>- Rebecca, FamilyLife Ministries</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div  class="d-block d-lg-none mb-3">
                <h1 class="text-primary mt-5 text-center">Turn your data into donations</h1>
                <p>From custom benchmarks to unique insights, Avid AI is able to help you get more out of your existing systems. Get started today with your free 30-day trial.</p>
            </div>
        </div>
        <div class="col">
            <nav class="navbar navbar-expand-lg pb-0 mt-lg-5">
                <!-- <div class="container-fluid"> -->
                    <div class="collapse navbar-collapse" id="filter-navbar">
                        <ul class="flex-row d-flex navbar-nav w-100">
                            <li class="col-3 nav-item px-3">
                                <a class="nav-link border-bottom border-4" :class="{'border-primary': step_num>=1}" href="/register?step=1" role="button" aria-expanded="false"><span  v-if="step_num == 1">Sign Up</span></a>
                            </li>
                            <li class="col-3 nav-item pe-3">
                                <a class="nav-link border-bottom border-4" :class="{'pe-none': (step_num == 1), 'border-primary': step_num>=2}"
                                href="/register?step=2" role="button" aria-expanded="false"><span v-if="step_num == 2">Organization</span></a>
                            </li>
                            <li class="col-3 nav-item pe-3">
                                <a class="nav-link border-bottom border-4" :class="{'pe-none': (step_num == 2), 'border-primary': step_num>=3}"
                                href="/register?step=3" role="button" aria-expanded="false"><span  v-if="step_num == 3">Systems</span></a>
                            </li>
                            <li class="col-3 nav-item pe-3">
                                <a class="nav-link border-bottom border-4" :class="{'pe-none': (step_num <= 3), 'border-primary': step_num>=4}"
                                href="/register?step=4" role="button" aria-expanded="false"><span  v-if="step_num == 4">Connect</span></a>
                            </li>
                        </ul>
                    </div>
                <!-- </div> -->
            </nav>

            <div class="card box-content" :class="step_num == 4 ? 'mb-3' : 'mb-5'">
                <div class="card-body">
                    <onboarding-personal-info :error="error" v-if="step_num == 1" @nextStep="nextStep"></onboarding-personal-info>

                    <onboarding-org-info v-if="step_num == 2" @nextStep="nextStep" :user="local_user" @reset="reset"></onboarding-org-info>

                    <onboarding-current-systems v-if="step_num == 3" @nextStep="nextStep" @reset="reset" :user="local_user" :client="local_client" :integrations="integrations"></onboarding-current-systems>

                    <onboarding-connect-crm v-if="step_num == 4" @reset="reset":user="local_user" :client="local_client" :integrations="integrations"></onboarding-connect-crm>
                </div>
            </div>
            <div class="mb-5 row">
                <div class="col">
                    <div class="d-flex align-items-center mb-3">
                        <img src="/img/lock.png" alt="lock" class="me-3" style="width:64px;">
                        <div>
                            <h5 class="mb-2">Your data is secure</h5>
                            <p class="mb-0">Avid AI takes the security of your data seriously.
                            <a href="https://trust.avidai.com" target="_blank">Visit our trust center</a>
                            to see our SOC2 compliance status and the steps taken to ensure your organization's privacy.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: ['error','user', 'client', 'integrations'],
        data() {
            return {
                step_num: 1,
                local_user: null,
                local_client: null
            };
        },
        beforeMount() {
            this.local_user = this.user;
            this.local_client = this.client;

            const params = new URLSearchParams(window.location.search);
            if(params.get('reg_step') != null)
                this.step_num = parseInt(params.get('reg_step'));

            if(this.step_num == 4 && (this.local_client == null || this.local_client.systems == null))
                this.step_num = 3;

            if(this.step_num == 3 && (this.local_client == null))
                this.step_num = 2;

            else if(this.step_num == 2 && this.local_user == null)
                this.step_num = 1;
        },
        methods: {
            reset(){
                this.step_num = 1;
            },
            nextStep(data){

                if(this.step_num == 1)
                    this.local_user = data;
                else if(this.step_num == 2 || this.step_num == 3)
                    this.local_client = data;

                this.step_num++;
                var new_url = '/?reg_step='+this.step_num
                window.history.pushState({}, {}, new_url);
                this.$forceUpdate();
            },
        }
    }
</script>
