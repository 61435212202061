<template>
    <div ref="reportSection" id="section-start" class="report-section">
        <!-- <h2>{{ page_section.title }}</h2>
        <p>Optional description{{ page_section.description }}</p> -->
        <!-- v-if="applied_chart_filter.if_chart_filter_applied" -->

        <div  class="applied-chart-filter mb-4" :class="applied_chart_filter.if_chart_filter_applied ? '' : 'hidden'">
            <div class="applied-chart-filter-direct-descendant-div">
                <div class="applied-chart-filter__icon">
                    <img class="icon" src="/img/icons/dialexa-icons/filter-sharp.svg">
                </div>
                <div v-if="applied_chart_filter.if_chart_filter_applied" class="applied-chart-filter__content">
                    <label>Chart Filter (Click on the X button to remove the chart filter selected)</label>
                    <h4>{{applied_chart_filter.chart_selected.title}}</h4>
                    <p>Segment selected: {{getSegmentName()}}</p>
                </div>
            </div>

            <div class="applied-chart-filter-direct-descendant-div">
                <div class="applied-chart-filter__actions">
                    <button type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#save-segment-modal-'+ page_section.id" @click="setSegmentDetails()">
                        <img class="icon svg-filter-primary" src="/img/icons/dialexa-icons/save-floppy-disk.svg">Save Segment
                    </button>
                    <button class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="'#push-segment-modal-' + page_section.id" @click="setSegmentDetails()">
                        <img class="icon" src="/img/icons/dialexa-icons/direction-sign.svg">Push Segment
                    </button>
                </div>
            </div>

            <button type="button" class="close btn" @click="removedAppliedChartFilter()">
                <i class="fa fa-close"></i>
            </button>
        </div>
        <div class="border rounded mb-3 pt-3 px-3 position-relative" v-if="any_active_charts == false">
            <transition name="fade" duration="300">
                <div v-if="if_refreshing" class="loading-overlay rounded">
                    <div role="status" class="d-inline-block spinner-border spinner-border-lg"><span class="visually-hidden">Loading</span></div>
                    <p>Loading...</p>
                </div>
            </transition>
            <div class="textbox-template">
                <div class="d-flex flex-row">
                    <div class="flex-shrink-1 insights-dashboard-group-icon me-2">
                        <div class="icon-red me-2"><img src="/img/icons/dialexa-icons/warning-circle.svg"></div></div>
                    <div class="flex-fill">
                        <h1>Invalid Filter Selection</h1>
                        <p>Based upon the filters selected, there are no records that match your criteria. Please update your filters and try again.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="chart-grid" v-else >
            <div class="row">

                <div v-for="(chart, i) in local_charts" v-if="checkIfShowChart(chart.chart)" :key="chart.id" :class="chart.size_class" :id="chart.id" class="size-container" :data-size="chart.size">
                    <div>
                        <div class="chart-container border rounded mb-3 pt-3 px-3 position-relative" :class="if_benchmark_report? 'benchmark-'+chart.subtitle : ''">
                            <transition name="fade" duration="300">
                                <div v-if="if_refreshing && chart.id != applied_chart_filter.section_chart_id  && chart.chart.type!='textbox'" class="loading-overlay rounded">
                                    <div role="status" class="d-inline-block spinner-border spinner-border-lg"><span class="visually-hidden">Loading</span></div>
                                    <p>Loading...</p>
                                </div>
                            </transition>
                            <button v-if="chart.chart.type!='scorecard' && chart.chart.type!='textbox' && chart.chart.type!='table'" class="btn btn-none zoom-btn" @click="zoomOnChart(chart, i)">
                                <img v-if="!chart.if_zoomed_in" src="/img/icons/dialexa-icons/maximize-4.svg" class="icon pe-0">
                                <img v-if="chart.if_zoomed_in" src="/img/icons/dialexa-icons/minimize-4.svg" class="icon pe-0">
                            </button>
                            <div v-if="chart.chart.type!='scorecard' && chart.chart.type!='textbox'">
                                <h4 :class="(chart.chart.subtitle != null && chart.chart.subtitle != '') ? 'mb-1' : 'mb-3'" v-if="chart.chart.title != null && chart.chart.title != ''" class="chart-title">{{chart.chart.title}} <sup><i class="fas fa-question-circle text-primary" data-bs-toggle="tooltip" :title="chart.chart.description" v-if="chart.chart.description != null"></i></sup></h4>
                                <p class="chart-subtitle" v-if="chart.chart.subtitle != null && chart.chart.subtitle != ''">{{chart.chart.subtitle}}</p>
                            </div>
                            <div v-else-if="chart.chart.type=='scorecard'">
                                <p class="mb-2">{{chart.chart.title}} </p>
                            </div>
                            <div class="mb-3 grow" :class="chart.if_zoomed_in? 'zoomed-in-chart': ''">
                                <chart-template :chart="chart.chart" :id="chart.id" :if_refreshing="if_refreshing" :if_on_view="true" :applied_chart_filter="applied_chart_filter" :if_benchmark_report="if_benchmark_report" @exploreChart="exploreChart"></chart-template>
                            </div>
                        </div>     

                        <transition name="slide" v-if="!if_benchmark_report">
                            <div v-if="chart.is_primary && local_section.summary != null && local_section.summary != ''" class="card box-content mb-3 primary-chart-insight">
                                <div class="card-body d-flex pink-gradient-left">
                                    <div class="primary-chart-insight-icon mt-2 me-2">
                                        <img class="icon" src="/img/icons/dialexa-icons/flag.svg">
                                    </div>
                                    <div>
                                        <h5>What does this mean?</h5>
                                        <div v-html="local_section.summary"></div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>

                <div v-if="if_benchmark_report && section_index == 1" class="col-12">
                    <div class="float-end">
                        <h5><a href="#" @click.prevent="goToNextSection()">Next Page: Benchmarks by Giving Level <i class="fa fa-solid fa-arrow-right"></i></a></h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="card box-content key-takeaways-section col-12" v-if="(local_section.takeaways==null || local_section.takeaways.length > 0) && any_active_charts && !if_benchmark_report">
            <div class="card-body">
                <h4 class="mb-3">Key Takeaways</h4>

                <div class="key-takeaways-none" v-if="local_section.takeaways==null">
                    <p class="mb-0"><div class="d-inline-block spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div>Your data is being analyzed. The results will be displayed here once the analysis is complete.</p>
                </div>

                <ul class="key-takeaways-list" v-else>
                    <li class="key-takeaways-item" v-for="takeaway in local_section.takeaways">
                        <div class="card box-content">
                            <div class="card-body">
                                <div class="key-takeaways-item--icon mt-2 me-3">
                                    <img v-if="takeaway.icon == 'money'" class="icon" src="/img/icons/dialexa-icons/dollar-square.svg">
                                    <img v-if="takeaway.icon == 'number'" class="icon" src="/img/icons/dialexa-icons/people.svg">
                                    <img v-if="takeaway.icon == 'percent'" class="icon" src="/img/icons/dialexa-icons/percentage-square.svg">
                                    <img v-if="takeaway.icon == 'other'" class="icon" src="/img/icons/dialexa-icons/note-2.svg">
                                    <!-- <img class="icon" src="/img/icons/dialexa-icons/flag.svg"> -->
                                </div>
                                <div class="key-takeaways-item--text">
                                    <div class="key-takeaways-item--text--heading">
                                        <h5 v-html='takeaway.title'></h5>
                                        <span v-if="takeaway.url != null" class="explore" >
                                            <i class="fas fa-question-circle text-primary"></i>
                                            <!-- <i class="fas fa-question-circle text-primary" data-bs-toggle="tooltip" :title="takeaway.tooltip" ></i> -->
                                            <a :href="takeaway.url">Explore <i class="fa fa-solid fa-arrow-right"></i></a>                                            
                                        </span>
                                    </div>
                                    <div class="key-takeaways-item--text--body">
                                        <span v-html="takeaway.html"></span><br>
                                        <!-- <a :href="takeaway.url" >Explore &gt;&gt;</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="modal fade push-segment-modal new-modal" :id="'push-segment-modal-' + page_section.id" data-bs-backdrop="static" aria-labelledby="push-segment-modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content mx-2">
                    <div class="modal-header">
                        <div class="w-100 d-flex flex-column gap-1">
                            <img class="modal-icon" src="/img/icons/dialexa-icons/directions.svg">
                            <p>Pathways</p>
                        </div>
                        <button type="button" class="btn btn-none close-modal"
                            :data-bs-dismiss="'push-segment-modal-' + page_section.id" @click="closeModal('push-segment-modal-' + page_section.id)">
                            <i class="fa fa-close"></i>
                        </button>
                    </div>
                    <div class="modal-body pt-0">
                        <h3>Push Segment</h3>
                        <p>Fill out the details for your selected segment. Once the segment is created you can continue to push it to a pathway.</p>
                    
                        <div>
                            <div v-if="segment_selected" class="segment-summary-item">
                                <div class="col-8">
                                    <h5>{{ segment_selected.name}} <small v-if="segment_selected.sizes">| {{ getDefaultSize(segment_selected.sizes) |number_with_zero }} Contacts</small></h5>
                                    <p>Source: {{ page.data_blend.name}}</p>
                                </div>
                                <div class="col-4" v-if="if_proceed_select_pathway" >
                                    <div v-if="!segment_selected.id">
                                        <div role="status" class="d-inline-block spinner-border spinner-border-sm"><span class="visually-hidden">Segment Creating...</span></div>Segment Creating...
                                    </div>
                                    <div v-else><i class="fa fa-check"></i>Segment Created!</div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!if_proceed_select_pathway" class="d-flex flex-column my-3">
                            <div>
                                <label required>Segment Name</label>
                                <input type="text" class="form-control"
                                    :class="((errors.segment_name_push != '' )? 'is-invalid':'')"
                                    name="segment_name" v-model="segment_selected.name">
                                <div class="text-danger invalid-feedback" v-if="errors.segment_name_push != ''">
                                    {{errors.segment_name_push}}
                                </div>
                            </div>
                        </div>
                        
                        <div v-else class="d-flex flex-column my-3">
                            <div class="w-100">
                                <div>
                                    <h5>Choose a Pathway</h5>
                                    <p>Select a pathway</p>
                                </div>

                                <div class="scroll-view" style="max-height: 200px;" :class="connections.length >= 3 ? 'pe-2' : ''">
                                    <div v-for="(conn, conn_index) in connections" class="card box-content long-card" :class="conn.id===pathway_selected.id? 'long-card-selected':''" >
                                        <div class="card-body long-card__body" @click="changePathwaySelection(conn)">
                                            <div class="long-card__radio d-flex">
                                                <input class="form-radio-input" type="radio" :id="conn.id" :value="conn.id" :checked="conn.id==pathway_selected.id"  >
                                            </div>
                                    
                                            <div class="long-card__content d-flex flex-row justfy-content-start gap-2">
                                                <div class="d-flex flex-column gap-1">
                                                    <img :src="conn.integration.logo_url" :alt="conn.integration.name" class="avatar rounded-circle avatar-sm bg-white">
                                                </div>
                                                <div class="d-flex flex-column gap-1">
                                                    <h5>{{conn.integration_id == '71b0c3b0-8390-49ec-9984-1c04b7384d38' ? 'File Export' : conn.name}}</h5>
                                                    <!-- <p></p> -->
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="w-100 d-flex flex-row justify-content-center gap-3 mt-3">        
                            <button class="btn btn-secondary w-50" @click.prevent="closeModal('push-segment-modal-'+ page_section.id)">
                               Cancel
                            </button>
                            <button v-if="!if_proceed_select_pathway" class="btn btn-primary w-50" @click.prevent="proceedSelectPathway()">
                                Confirm and Select Pathway
                            </button>                                
                            <button v-else class="btn btn-primary col-6" :disabled="!pathway_selected.id || !segment_selected.id"
                            @click.prevent="directToPushSegmentPage()">
                                Review and Confirm
                            </button>   
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade save-segment-modal new-modal" :id="'save-segment-modal-' + page_section.id" data-bs-backdrop="static" aria-labelledby="save-segment-modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content mx-2">
                    <div class="modal-header">
                        <div class="w-100 d-flex flex-column gap-1">
                            <img class="modal-icon" src="/img/icons/dialexa-icons/directions.svg">
                            <p>Pathways</p>
                        </div>
                        <button type="button" class="btn btn-none close-modal"
                            :data-bs-dismiss="'save-segment-modal-' + page_section.id" @click="closeModal('save-segment-modal-' + page_section.id)">
                            <i class="fa fa-close"></i>
                        </button>
                    </div>
                    <div class="modal-body pt-0">
                        <h3>Save Segment</h3>
                        <p>Save the segment that you want to save. Once the segment is created you can view it in Pathways - Manage Tab.</p>
                    
                        <div>
                            <div v-if="applied_chart_filter && applied_chart_filter.chart_selected && applied_chart_filter.chart_filters.length" class="segment-summary-item">
                                <div class="col-12">
                                    <h5>{{ segment_selected.name}} <small v-if="segment_selected.size">| {{ segment_selected.size |number_with_zero }} Contacts</small></h5>
                                    <p>Source: {{ page.data_blend.name}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-column my-3">
                            <div>
                                <label required>Segment Name</label>
                                <input type="text" class="form-control"
                                    :class="((errors.segment_name_save != '' )? 'is-invalid':'')"
                                    name="segment_name" v-model="segment_selected.name">
                                <div class="text-danger invalid-feedback" v-if="errors.segment_name_save != ''">
                                    {{errors.segment_name_save}}
                                </div>
                            </div>
                        </div>
                        
                        <div class="w-100 d-flex flex-row justify-content-center gap-3 mt-3">        
                            <button class="btn btn-secondary w-50" @click.prevent="closeModal('save-segment-modal-'+page_section.id)">
                               Cancel
                            </button>
                            <button class="btn btn-primary w-50" @click.prevent="saveSegment()">
                                <div v-if="if_saving_segment" class="d-inline-block spinner-border spinner-border-sm" role="status"><span class="visually-hidden"></span></div> Save Segment
                            </button>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Swal from 'sweetalert2';

    export default {
        components: {
            Swal
        },
        props: ['page',  'client', 'connections', 'page_section', 'section_index', 'is_filter_panel_expanded', 'if_refreshing', 'if_benchmark_report', 'applied_chart_filter'],
        data() {
            return {
                local_section: null,
                local_charts: [],
                formatted_rows: [],
                errors: {
                    segment_name_push: '', // push-segment-modal 
                    segment_name_save: '', // save-segment-modal 
                },
                segment_selected: { // push-segment-modal
                    name: '',  
                },
                if_saving_segment: false,
                pathway_selected: {}, // push-segment-modal
                if_proceed_select_pathway: false,  // push-segment-modal
                any_active_charts: true
            };
        },
        beforeMount() {
            this.local_charts = JSON.parse(JSON.stringify(this.page_section.charts));
            this.local_section = {...this.page_section};
            this.local_charts.forEach((chart)=>{
                chart.if_zoomed_in = false;
            })
            this.formatChartObjectInArray();

            this.any_active_charts = this.areChartsActive();

            //It takes a second to generate the takeaways when they refresh
            if(!this.if_benchmark_report){
                if(this.local_section.takeaways == null && this.any_active_charts)
                    setTimeout(() => { this.fetchKeyTakeaways(); }, 1000);
                else if(this.any_active_charts) {
                    // add tooltip title to takeaway objects
                    for (var i = 0; i <this.local_section.takeaways.length; i++){
                        this.local_section.takeaways[i].tooltip = 'By clicking on Explore button, you will be navigate to Report "" on Section "" with Filters as " " applied.'
                    }
                }                
            }
        },
        watch:{
            page_section(new_value, old_value){
                this.local_charts = JSON.parse(JSON.stringify(this.page_section.charts));
                this.local_section = {...this.page_section};
                this.formatChartObjectInArray();

                this.any_active_charts = this.areChartsActive();
                if(!this.if_benchmark_report){
                    if(this.local_section.takeaways == null && this.any_active_charts)
                    //It takes a second to generate the takeaways when they refresh
                        setTimeout(() => { this.fetchKeyTakeaways(); }, 1000);
                }
            },
            is_filter_panel_expanded(new_value, old_value){
                this.formatChartObjectInArray();
                this.$forceUpdate();
            },
            applied_chart_filter(new_value, old_value){
                if(this.applied_chart_filter.if_chart_filter_applied){
                    $('html,body').animate({ scrollTop: $("#section-start").offset().top-100}, 200);           
                }
            }
        },
        methods: {

            getDefaultSize(sizes) {
                if(!sizes)
                    return null;
                for (var i = 0; i < sizes.length; i++){
                    if (sizes[i].id == 0)
                        return sizes[i].size;
                }
                return null
            },
            zoomOnChart(chart, i){
                if(chart.if_zoomed_in){
                    this.local_charts[i].size = this.local_section.charts[i].size;
                    if(this.local_charts[i].size=='small'){
                        this.local_charts[i].size_class='col-md-4 col-12';
                    }else if(this.local_charts[i].size=='medium'){
                        this.local_charts[i].size_class='col-md-6 col-12';
                    }else if(this.local_charts[i].size=='large'){
                        this.local_charts[i].size_class='col-md-8 col-12';
                    }else if(this.local_charts[i].size=='x-large'){
                        this.local_charts[i].size_class='col-12';
                    }
                }else{
                    this.local_charts[i].size = 'x-large';
                    this.local_charts[i].size_class = "col-12";
                }
                this.local_charts[i].if_zoomed_in = !this.local_charts[i].if_zoomed_in;
                this.$forceUpdate();
  
            },
            areChartsActive() {
                //If a chart is a text box or the data is empty, it doesn't count
                for(var i = 0; i < this.local_charts.length; i++){
                    let chart = this.local_charts[i].chart;
                    if(chart.type == 'textbox' && chart.html != '') continue;
                    if(!chart.data || Object.keys(chart.data).length == 0){
                        continue;
                    } else if(!chart.labels || chart.labels.length ==0
                    || (chart.labels.length == 1 && chart.labels[0] && chart.labels[0] == '(No Data)') )
                        continue;
                    return true;
                }
                return false;
            },
            getSegmentName(){
                var segment_name ='';
                if (this.applied_chart_filter && this.applied_chart_filter.if_chart_filter_applied){
                    this.applied_chart_filter.chart_filters.forEach((chart_filter) => {
                        segment_name = segment_name + chart_filter.value + ' - ';
                    });
                    segment_name = segment_name.slice(0, -3); // remove last ' - '
                }
                return segment_name;
            },
            checkIfShowChart(chart){
                if(chart.type == 'textbox' && chart.html != '')
                    return true;

                // console.log(chart);
                if(!chart.data || Object.keys(chart.data).length == 0){
                    return false;
                } else if(!chart.labels || chart.labels.length ==0 
                || (chart.labels.length == 1 && chart.labels[0] && chart.labels[0] == '(No Data)') ){
                    return false;
                }else{
                    return true;
                }
            },
            formatChartObjectInArray(){
                if(this.is_filter_panel_expanded){
                    for (var i = 0; i< this.local_charts.length; i++){
                        this.local_charts[i].size_class='col-12';
                    }
                } else{
                    for (var i = 0; i< this.local_charts.length; i++){
                        if(this.local_charts[i].size=='small'){
                            this.local_charts[i].size_class='col-md-4 col-12';
                        }else if(this.local_charts[i].size=='medium'){
                            this.local_charts[i].size_class='col-md-6 col-12';
                        }else if(this.local_charts[i].size=='large'){
                            this.local_charts[i].size_class='col-md-8 col-12';
                        }else if(this.local_charts[i].size=='x-large'){
                            this.local_charts[i].size_class='col-12';
                        }
                    }
                }
            },
            removedAppliedChartFilter(){
                var details = {
                    if_chart_filter_applied: false,
                    section_index: null,
                    section_chart_id:null,
                    chart_selected: {},
                    chart_filters:[]
                };
                this.$emit("exploreChartInSection", details);
            },
            exploreChart(details){
                details.section_index = this.section_index;
                this.$emit("exploreChartInSection", details);
            },
            fetchKeyTakeaways(){
                var form_data = {
                    'section':this.local_section,
                    'page':this.page,
                }
                window.axios.post('/api/insights/section/'+this.local_section.id+'/takeaways', form_data)
                .then(response => {
                    //If it made a new summary or new takeaways
                    if(this.local_section.summary == null && response.data.section.summary != null || this.local_section.takeaways == null && response.data.section.takeaways != null) {
                        // add tooltip title to takeaway objects
                        for (var i = 0; i <response.data.section.takeaways.length; i++){
                             response.data.section.takeaways[i].tooltip = 'By clicking on Explore button, you will be navigate to Report "" on Section "" with Filters as " " applied.'
                        }
                        this.local_section = response.data.section;
                        this.$forceUpdate();
                    }
                    //It did neither, try again in a few seconds
                    if(response.data.section.summary == null || response.data.section.takeaways == null) setTimeout(() => { this.fetchKeyTakeaways(); }, 1000);

                });
            },
            setSegmentDetails(){
                this.segment_selected.name = this.applied_chart_filter.chart_filters[0].value;
            },
            changePathwaySelection(pathway){
                this.pathway_selected = pathway;
            },
            closeModal(id) {
                if(id == 'push-segment-modal-'+ this.page_section.id){
                    this.if_proceed_select_pathway = false;
                }

                var myModalEl = document.getElementById(id)
                var modal = bootstrap.Modal.getInstance(myModalEl);
                modal.hide();
            },
            proceedSelectPathway(){
                this.clearErrors();
                if(this.hasErrors()) {
                    return;
                }
                this.if_proceed_select_pathway = true;
                var form_data = {
                    page: this.page,
                    segment_name: this.segment_selected.name
                }
                window.axios.post('/api/segment/'+this.client.url+'/push', form_data)
                    .then(response => {
                        this.segment_selected = response.data.segment;
                    });
            },
            directToPushSegmentPage(){
                if(this.pathway_selected.census_destination_id == null && this.pathway_selected.integration.census_requires_oauth){
                    // census oauth
                    var self = this;
                    Swal.fire({
                        title: "Additional Access Needed",
                        text: "Avid AI has partnered with a software service named Census to activate the ability to push data to "+this.pathway_selected.integration.name+". This software requires an additional authentication before it can be used.",
                        iconHtml: '<i class="fa-solid fa-exclamation"></i>',
                        iconColor: "#25473E",
                        showCancelButton: true,
                        confirmButtonColor: "#25473E",
                        confirmButtonText: "Proceed",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location = '/integrations/'+this.client.url+'/census/oauth/' + self.pathway_selected.id + '/'+ self.segment_selected.id;
                            }
                        });                    
                } else {
                    window.location = '/pathways/' + this.client.url + '/' +this.segment_selected.id + '/' + this.pathway_selected.id;
                }      
            },
            clearErrors() { //reset errors to empty strings
                this.errors = {
                    segment_name_push: '',
                    segment_name_save: ''
                }
            },
            hasErrors() {
                if(this.segment_selected.name == null || this.segment_selected.name.trim()== ""){
                    this.errors.segment_name_push = "Segment name is required.";
                    this.errors.segment_name_save = "Segment name is required.";
                } else
                    this.errors.segment_name_push = "";
                    this.errors.segment_name_save = "";
                if (this.errors.segment_name_push!="" || this.errors.segment_name_save!=""){
                    return true
                } else {
                    return false
                }
            },
            saveSegment(){
                this.clearErrors();
                if(this.hasErrors()) {
                    return;
                }
                var form_data = {
                    page: this.page,
                    segment_name: this.segment_selected.name
                }
                this.if_saving_segment = true;
 
                window.axios.post('/api/segment/'+this.client.url+'/push', form_data)
                    .then(response => {
                        this.segment_selected = response.data.segment;
                        this.if_saving_segment = false;
                        this.closeModal('save-segment-modal-' + this.page_section.id);
                        Swal.fire({
                            title: "Segment Saved!",
                            text: "To further manage the saved segment, you can click on the 'Proceed to Manage Segments' button to be redirected to Pathway - Manage Segments screen",
                            iconHtml: '<i class="fa fa-check"></i>',
                            iconColor: "#25473E",
                            showCancelButton: true,
                            confirmButtonColor: "#25473E",
                            confirmButtonText: "Proceed to Manage Segments",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location = '/pathways/'+this.client.url +'/manage_segments';
                            }
                        });   
                    });
            },
            goToNextSection(){
                this.$emit('goToNextSection', null);
            }
        }
    }
</script>
